/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { GlobalVar } from '@vmf/shared';
import { Pm } from './Pm';

class Activity extends Pm {
    constructor() {
        super();
        this.baseUrl += '/activity';
    }

    data({ activityName, activityStatus, activityType, currentPage, pageSize } = {}) {
        return super.get('/data', {
            activityName,
            activityStatus,
            activityType,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { activityAdImage } = json.data.data.activity;
            if (!activityAdImage) {
                json.data.data.activity.activityAdImage = [];
            } else {
                json.data.data.activity.activityAdImage = activityAdImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        activityType,
        activityName,
        activityDesc,
        activityGoodsJson,
        activityAdImage,
        activityPreviewType,
        activityPreviewHours,
        isActivityDiscount,
        activityDiscountType,
        activityGoodsOrderLimit,
        activityScopeType,
    } = {}) {
        const activityAdImageStr = activityAdImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            activityType,
            activityName,
            activityDesc,
            activityGoodsJson,
            activityAdImage: activityAdImageStr,
            activityPreviewType,
            activityPreviewHours,
            isActivityDiscount,
            activityDiscountType,
            activityGoodsOrderLimit,
            activityScopeType,
        });
    }

    onReleaseActivity({ activityId }) {
        return super.post('/onReleaseActivity', { activityId });
    }

    onCloseActivity({ activityId }) {
        return super.post('/onCloseActivity', { activityId });
    }

    onDelActivity({ activityId }) {
        return super.post('/onDelActivity', { activityId });
    }

    activityGoodsStockList({ activityName, goodsName, goodsSn, orderByKey, orderByType, currentPage, pageSize } = {}) {
        return super.get('/activityGoodsStockList', {
            activityName,
            goodsName,
            goodsSn,
            orderByKey,
            orderByType,
            currentPage,
            pageSize,
        });
    }
}

export default new Activity();
export { Activity };
