/**
 * Created by henian.xu on 2021/8/15.
 *
 */

import destMarkersIcon from '../dest-markers.png';
import { DeleteMarker } from './DeleteMarker';

export const DEST_TYPE_ENUM = {
    START: 'start',
    VIA: 'via',
    END: 'end',
};

export class DrivingRoute {
    constructor(waypoints, opt) {
        if (!opt || !opt.BMap || !opt.map) {
            console.error('BMap|map 参数未传入');
            return;
        }
        this.type = 'driving';
        this._waypoints = waypoints || [];
        this.BMap = opt.BMap;
        this.map = opt.map;
        this.polylines = [];
        this.destMarkers = [];
        this.deleteMarkers = [];
        this.deleteMarker = null;
        this.isEditing = false;
        const options = {
            enableEditing: false,
            enableMassClear: true,
            enableClicking: true,
            ...opt,
        };
        delete options.BMap;
        delete options.map;
        this.options = options;
        this.load();
    }

    load() {
        const { BMap, map, options } = this;
        this.originInstance = new BMap.DrivingRoute(map, {
            renderOptions: {
                // map,
                // panel:'',
                selectFirstResult: false, // 是否选择第一个检索结果。此属性仅对LocalSearch有效
                autoViewport: false, // 检索结束后是否自动调整地图视野。此属性对LocalCity无效
            },
            // BMAP_DRIVING_POLICY_DEFAULT //默认
            // BMAP_DRIVING_POLICY_FIRST_HIGHWAYS //优先高速
            // BMAP_DRIVING_POLICY_AVOID_HIGHWAYS //避开高速
            // BMAP_DRIVING_POLICY_AVOID_CONGESTION //避开拥堵
            policy: 'BMAP_DRIVING_POLICY_DEFAULT',
            onSearchComplete: event => this.onSearchComplete(event),
            // onMarkersSet: (event) => this.onMarkersSet(event),
            // onInfoHtmlSet: (event) => this.onInfoHtmlSet(event),
            // onPolylinesSet: (event) => this.onPolylinesSet(event),
        });

        if (options.paths && options.paths.length) {
            const paths = options.paths.reduce((pre, cur) => {
                if (!Array.isArray(cur)) return pre;
                const path = cur.reduce((p, c) => {
                    const { lng, lat } = c;
                    if (!lng || !lat) return p;
                    p.push(new BMap.Point(lng, lat));
                    return p;
                }, []);
                if (path.length) pre.push(path);
                return pre;
            }, []);
            if (paths.length) this.updateMap(this.pathsToPolylines(paths));
            delete options.paths;
        } else {
            this.search();
        }
        if (options.enableEditing) {
            setTimeout(() => {
                this.enableEditing();
            }, 50);
        }
    }

    pathsToPolylines(paths) {
        const { BMap } = this;
        return paths.map((path, index) => {
            const polyline = new BMap.Polyline(path, {
                enableEditing: false,
                enableMassClear: true,
                enableClicking: true,
            });
            polyline.addEventListener('click', e => this.onPolylineClick(e, index));
            return polyline;
        });
    }

    // 检索完成后的回调函数。参数： results: DrivingRouteResult
    onSearchComplete(event) {
        if (!event) {
            this.updateMap();
            return;
        }
        // const { BMap } = this;
        const plansNum = event.getNumPlans();
        if (plansNum < 1) return;
        const routePlan = event.getPlan(0);
        const routesNum = routePlan.getNumRoutes();
        if (routesNum < 1) return;

        /* const polylines = [];
    Array(routesNum)
      .fill(null)
      .forEach((item, index) => {
        const route = routePlan.getRoute(index);
        const path = route.getPath();
        const polyline = new BMap.Polyline(path, {
          enableEditing: false,
          enableMassClear: true,
          enableClicking: true,
        });
        polyline.addEventListener('click', (e) =>
          this.onPolylineClick(e, index),
        );
        polylines.push(polyline);
        // map.addOverlay(polyline);
      }); */

        const paths = Array(routesNum)
            .fill(null)
            .reduce((pre, cur, index) => {
                const route = routePlan.getRoute(index);
                const path = route.getPath();
                if (path.length) {
                    pre.push(path);
                }
                return pre;
            }, []);
        this.updateMap(this.pathsToPolylines(paths));
    }

    createMarker(point, destType) {
        const { BMap, map, destMarkers, deleteMarkers, options } = this;
        const offsetMap = {
            [DEST_TYPE_ENUM.START]: 0,
            [DEST_TYPE_ENUM.VIA]: -2,
            [DEST_TYPE_ENUM.END]: -1,
        };
        const marker = new BMap.Marker(point, {
            offset: new BMap.Size(0, -12),
            icon: new BMap.Icon(destMarkersIcon, new BMap.Size(29, 32), {
                imageOffset: new BMap.Size(0, 32 * offsetMap[destType] || 0),
            }),
            enableDragging: options.enableEditing,
        });
        if (options.enableEditing) {
            const deleteMarker = new DeleteMarker(point, {
                offset: {
                    width: 20,
                    height: -30,
                },
                BMap,
                map,
            });
            const index = destMarkers.length;
            marker.addEventListener('dragend', event => this.onDestMarkerDragend(event, index));
            deleteMarker.addEventListener('click', event => this.onDestMarkerDelete(event, index));
            deleteMarkers.push(deleteMarker);
        }

        map.addOverlay(marker);
        destMarkers.push(marker);
    }

    clearMap() {
        const { map } = this;
        this.polylines.forEach(polyline => {
            map.removeOverlay(polyline);
        });
        this.polylines = [];
        this.destMarkers.forEach(marker => {
            map.removeOverlay(marker);
        });
        this.destMarkers = [];
        this.deleteMarkers.forEach(deleteMarker => {
            deleteMarker.destroy();
        });
        this.deleteMarkers = [];
    }

    updateMap(polylines = this.polylines) {
        const { map, _waypoints } = this;
        this.clearMap();
        if (!polylines || (!polylines.length && _waypoints && _waypoints.length)) {
            const point = _waypoints[0];
            if (point) this.createMarker(point, DEST_TYPE_ENUM.START);
            return;
        }
        const { length } = polylines;
        const waypoints = [];
        polylines.forEach((polyline, index) => {
            const paths = [...polyline.getPath()];
            let p = null;
            if (index === 0) {
                p = paths.shift();
                this.createMarker(p, DEST_TYPE_ENUM.START);
                waypoints.push(p);
            }
            p = paths.pop();
            this.createMarker(paths.pop(), index + 1 === length ? DEST_TYPE_ENUM.END : DEST_TYPE_ENUM.VIA);
            waypoints.push(p);
            map.addOverlay(polyline);
        });
        this._waypoints = waypoints;
        this.polylines = polylines;
    }

    onPolylineClick(event, index) {
        if (!this.options.enableEditing) return;
        this._waypoints.splice(index + 1, 0, event.point);
        this.search();
    }

    onDestMarkerDragend(event, index) {
        this._waypoints.splice(index, 1, event.point);
        this.search();
    }

    onDestMarkerDelete(event, index) {
        if (this._waypoints.length <= 2) {
            this._waypoints = [];
            this.destroy();
            return;
        }
        this._waypoints.splice(index, 1);
        this.search();
    }

    // 标注添加完成后的回调函数。 参数： pois: Array ，起点和目的地点数组，通过marker属性可得到其对应的标注
    /* onMarkersSet(event) {
    console.log('onMarkersSet', event);
  } */

    // 标注气泡内容创建后的回调函数。 参数： poi: LocalResultPoi，通过marker属性可得到当前的标注。html: HTMLElement，气泡内的DOM元素
    /* onInfoHtmlSet(event) {
    console.log('onInfoHtmlSet', event);
  } */

    // 折线添加完成后的回调函数。 参数： routes: Array ，驾车线路数组，通过Route.getPolyline()方法可得到对应的折线覆盖物
    /* onPolylinesSet(event) {
    console.log('onPolylinesSet', event);
  } */

    get waypoints() {
        return this._waypoints;
    }

    set waypoints(points) {
        this._waypoints = points;
        this.search();
    }

    addWaypoint(point) {
        this._waypoints.push(point);
        this.search();
    }

    search() {
        const { originInstance, _waypoints } = this;
        if (!originInstance) return;
        const waypoints = [..._waypoints];
        if (!waypoints.length) return;
        originInstance.search(waypoints.shift(), waypoints.pop(), {
            waypoints,
        });
    }

    enableEditing() {
        const { waypoints } = this;
        if (waypoints.length < 2) this.destroy();
        this.isEditing = true;
        this.options.enableEditing = true;
        this.updateMap();
    }

    disableEditing() {
        this.isEditing = false;
        this.options.enableEditing = false;
        this.updateMap();
    }

    destroy() {
        // const { map, originInstance } = this;
        // map.removeOverlay(originInstance);
        this.clearMap();
        this.originInstance = null;
    }
}

export default DrivingRoute;
