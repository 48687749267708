/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import BuyerLevel from './BuyerLevel';
import Buyer from './Buyer';
import DistributorLevel from './DistributorLevel';
import PointRule from './PointRule';
import BuyerAuthInfo from './BuyerAuthInfo';
import Distributor from './Distributor';
import DistributorBill from './DistributorBill';
import PrepaidCard from './PrepaidCard';
import GrowthValueRule from './GrowthValueRule';
import DistributorWithdraw from './DistributorWithdraw';
import BuyerBill from './BuyerBill';
import GiftCard from './GiftCard';
import BuyerWithdraw from './BuyerWithdraw';
import Feedback from './Feedback';
import VisitReserve from './VisitReserve';
import BenefitCard from './BenefitCard';
import BuyerBenefitCard from './BuyerBenefitCard';
import PromotionLevel from './PromotionLevel';
import BuyerReference from './BuyerReference';

export default {
    BuyerLevel,
    Buyer,
    DistributorLevel,
    PointRule,
    BuyerAuthInfo,
    Distributor,
    DistributorBill,
    PrepaidCard,
    GrowthValueRule,
    DistributorWithdraw,
    BuyerBill,
    GiftCard,
    BuyerWithdraw,
    Feedback,
    VisitReserve,
    BenefitCard,
    BuyerBenefitCard,
    PromotionLevel,
    BuyerReference,
};
