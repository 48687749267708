/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopPaymentConfig extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopPaymentConfig';
    }

    data({ shopId, storeId } = {}) {
        if (shopId) {
            return super.get('/shopPaymentConfigList', {
                shopId,
                storeId,
            });
        }
        return super.get('/currShopPaymentConfigList', {});
    }

    save({ shopId, storeId, configJson } = {}) {
        if (shopId) {
            return super.post('/save', {
                shopId,
                storeId,
                configJson,
            });
        }
        return super.post('/saveCurr', {
            configJson,
        });
    }
}

export default new ShopPaymentConfig();
export { ShopPaymentConfig };
