/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopDriverSet extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopDriverSet';
    }

    getShopDriverSet() {
        return super.get('/getShopDriverSet', {});
    }

    saveShopDriverSet({
        billingRuleType,
        fixedFreightAmount,
        balanceExpansionRatio,
        acceptOrderDistance,
        acceptOrderNum,
        withdrawDayNum,
        isAutoAudit,
        customerTel,
        acceptOrderNeedPicking,
    } = {}) {
        const billingRuleConfig = { fixedFreightAmount };
        const withdrawRuleConfig = { balanceExpansionRatio, withdrawDayNum, customerTel };
        const acceptOrderConfig = { acceptOrderDistance, acceptOrderNeedPicking, acceptOrderNum };
        return super.post('/saveShopDriverSet', {
            billingRuleType,
            isAutoAudit,
            billingRuleConfig: JSON.stringify(billingRuleConfig),
            withdrawRuleConfig: JSON.stringify(withdrawRuleConfig),
            acceptOrderConfig: JSON.stringify(acceptOrderConfig),
        });
    }
}

export default new ShopDriverSet();
export { ShopDriverSet };
