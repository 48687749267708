/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import ShopLevel from './ShopLevel';
import Shop from './Shop';
import ShopBuyerAuthSet from './ShopBuyerAuthSet';
import ShopPaymentConfig from './ShopPaymentConfig';
import ShopFreightTemplate from './ShopFreightTemplate';
import ShopFixedFreight from './ShopFixedFreight';
import ShopExpress from './ShopExpress';
import ShopStore from './ShopStore';
import ShopStoreServiceTime from './ShopStoreServiceTime';
import ShopStoreFreight from './ShopStoreFreight';
import ShopStoreGoodsCat from './ShopStoreGoodsCat';
import ShopStoreGoods from './ShopStoreGoods';
import ShopGoodsCat from './ShopGoodsCat';
import ShopDecoration from './ShopDecoration';
import ShopAftersaleTemplate from './ShopAftersaleTemplate';
import ShopGoodsStyle from './ShopGoodsStyle';
import ShopPremiumTemplate from './ShopPremiumTemplate';
import StoreInitGoodsCat from './StoreInitGoodsCat';
import ShopOtoldTemplate from './ShopOtoldTemplate';
import ShopDriver from './ShopDriver';
import ShopStoreBuyer from './ShopStoreBuyer';
import DeviceCat from './DeviceCat';
import Device from './Device';
import PointGoodsCat from './PointGoodsCat';
import ShopTag from './ShopTag';
import ShopStoreLevel from './ShopStoreLevel';
import ShopStoreServiceStation from './ShopStoreServiceStation';
import ShopPaymentTemplate from './ShopPaymentTemplate';
import ShopDriverSet from './ShopDriverSet';
import ShopDriverWithdraw from './ShopDriverWithdraw';
import BusinessDistrict from './BusinessDistrict';
import ShopStoreTable from './ShopStoreTable';
import ShopNotice from './ShopNotice';
import BusinessDistrictServiceTime from './BusinessDistrictServiceTime';
import ShopDriverLevel from './ShopDriverLevel';
import BusinessOperateLog from './BusinessOperateLog';

export default {
    ShopLevel,
    Shop,
    ShopBuyerAuthSet,
    ShopPaymentConfig,
    ShopFreightTemplate,
    ShopFixedFreight,
    ShopExpress,
    ShopStore,
    ShopStoreServiceTime,
    ShopStoreFreight,
    ShopStoreGoodsCat,
    ShopStoreGoods,
    ShopGoodsCat,
    ShopDecoration,
    ShopAftersaleTemplate,
    ShopGoodsStyle,
    ShopPremiumTemplate,
    StoreInitGoodsCat,
    ShopOtoldTemplate,
    ShopDriver,
    ShopStoreBuyer,
    DeviceCat,
    Device,
    PointGoodsCat,
    ShopTag,
    ShopStoreLevel,
    ShopStoreServiceStation,
    ShopPaymentTemplate,
    ShopDriverSet,
    ShopDriverWithdraw,
    BusinessDistrict,
    ShopStoreTable,
    ShopNotice,
    BusinessDistrictServiceTime,
    ShopDriverLevel,
    BusinessOperateLog,
};
