/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class GoodsCatUser extends Gd {
    constructor() {
        super();
        this.baseUrl += '/goodsCatUser';
    }

    data({ supplierName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            supplierName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ goodsCatId, userIds } = {}) {
        return super.post('/save', {
            goodsCatId,
            userIds,
        });
    }

    delete({ goodsCatId, userIds } = {}) {
        return super.post('/delete', {
            goodsCatId,
            userIds,
        });
    }
}

export default new GoodsCatUser();
export { GoodsCatUser };
