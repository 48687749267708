/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import PupuGoods from './PupuGoods';

export default {
    PupuGoods,
};
