/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Stat } from './Stat';

class ShopStoreStat extends Stat {
    constructor() {
        super();
        this.baseUrl += '/shopStoreStat';
    }

    distributionStatList({ currentPage, pageSize } = {}) {
        return super.get('/distributionStatList', {
            currentPage,
            pageSize,
        });
    }
}

export default new ShopStoreStat();
export { ShopStoreStat };
