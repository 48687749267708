/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopStoreBuyer extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopStoreBuyer';
    }

    data({ fullName, mobile, storeId, currentPage, pageSize } = {}) {
        return super.get('/data', {
            fullName,
            mobile,
            storeId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, storeId, fullName, mobile } = {}) {
        return super.post('/save', {
            id,
            storeId,
            fullName,
            mobile,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new ShopStoreBuyer();
export { ShopStoreBuyer };
