/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ms } from './Ms';

class UserMessage extends Ms {
    constructor() {
        super();
        this.baseUrl += '/userMessage';
    }

    data({ keywords, isRead, currentPage, pageSize } = {}, cfg) {
        return super.get(
            '/data',
            {
                keywords,
                isRead,
                currentPage,
                pageSize,
            },
            cfg,
        );
    }

    updateMessageRead({ ids } = {}, cfg) {
        return super.post(
            '/updateMessageRead',
            {
                ids,
            },
            cfg,
        );
    }

    sendUserMsg({ userId, title, content } = {}) {
        return super.post('/sendUserMsg', {
            userId,
            title,
            content,
        });
    }

    getUnReadCount() {
        return super.get('/getUnReadCount', {});
    }
}

export default new UserMessage();
export { UserMessage };
