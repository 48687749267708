/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Stat } from './Stat';

class BuyerStat extends Stat {
    constructor() {
        super();
        this.baseUrl += '/buyerStat';
    }

    buyerOrderStatList({
        statDateDay,
        orderByType,
        minCustomerPrice,
        minOrderAmount,
        minOrderCount,
        buyerLevelId,
        distributorLevelId,
        mobile,
        currentPage,
        pageSize,
    } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/buyerOrderStatList', {
            statBeginDate,
            statEndDate,
            orderByType,
            minCustomerPrice,
            minOrderAmount,
            minOrderCount,
            buyerLevelId,
            distributorLevelId,
            mobile,
            currentPage,
            pageSize,
        });
    }
}

export default new BuyerStat();
export { BuyerStat };
