import { Rs } from './Rs';

class LanguageEntry extends Rs {
    constructor() {
        super();
        this.baseUrl += '/languageEntry';
    }

    data({ moduleId, entryName, entryKey, entryTrans, currentPage, pageSize } = {}) {
        return super.get('/data', {
            moduleId,
            entryName,
            entryKey,
            entryTrans,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, moduleId, entryKey, entryName, entryDesc, entryTransListJson } = {}) {
        return super.post('/save', {
            id,
            moduleId,
            entryKey,
            entryName,
            entryDesc,
            entryTransListJson,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    getLanguageEntryForPlatform({ language } = {}) {
        return super.get(
            '/getLanguageEntryForPlatform',
            {
                language,
            },
            {
                isAxiosResponseData: true,
            },
        );
    }
}

export default new LanguageEntry();
export { LanguageEntry };
