/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import { HttpBase } from '@/api/base/HttpBase';

class St extends HttpBase {
    constructor() {
        super();
        this.baseUrl += '/st';
    }
}

export default new St();
export { St };
