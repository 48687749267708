/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Pm } from './Pm';

class CouponTemplet extends Pm {
    constructor() {
        super();
        this.baseUrl += '/couponTemplet';
    }

    data({ templetName, isAvailable, couponGiveMode, currentPage, pageSize } = {}) {
        return super.get('/data', {
            templetName,
            isAvailable,
            couponGiveMode,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { couponImage } = json.data.data.couponTemplet;
            const { fileBasePath } = json.data.data;
            if (!couponImage) {
                json.data.data.couponTemplet.couponImage = [];
            } else {
                json.data.data.couponTemplet.couponImage = couponImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        templetName,
        templetType,
        couponDesc,
        couponShortDesc,
        couponAmount,
        couponUseAmount,
        couponExpireMode,
        couponPeriod,
        couponExpireTime,
        giveScope,
        remarks,
        couponAmountType,
        discountMaxAmount,
        couponUseScopeType,
        couponUseScope,
        couponImage,
        couponUseRemarks,
        isAvailable,
        couponShipMethod,
    } = {}) {
        const couponImageStr = couponImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            templetName,
            templetType,
            couponDesc,
            couponShortDesc,
            couponAmount,
            couponUseAmount,
            couponExpireMode,
            couponPeriod,
            couponExpireTime,
            giveScope,
            remarks,
            couponAmountType,
            discountMaxAmount,
            couponUseScopeType,
            couponUseScope,
            couponImage: couponImageStr,
            couponUseRemarks,
            isAvailable,
            couponShipMethod,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}

export default new CouponTemplet();
export { CouponTemplet };
