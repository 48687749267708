/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Utils } from 'vmf';
import { Pm } from './Pm';

const { GlobalVar } = Utils;

class CouponShare extends Pm {
    constructor() {
        super();
        this.baseUrl += '/couponShare';
    }

    data({ couponShareName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            couponShareName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { shareImage } = json.data.data.couponShare;
            if (!shareImage) {
                json.data.data.couponShare.shareImage = [];
            } else {
                json.data.data.couponShare.shareImage = shareImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const { adImage } = json.data.data.couponShare;
            if (!adImage) {
                json.data.data.couponShare.adImage = [];
            } else {
                json.data.data.couponShare.adImage = adImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        couponShareType,
        couponShareName,
        beginTime,
        endTime,
        couponShareDesc,
        totalAllowNum,
        buyerLimitTotalNum,
        buyerLimitDayNum,
        isCancelClose,
        remarks,
        isAvailable,
        detailJson,
        shareImage,
        adImage,
    } = {}) {
        const shareImageStr = shareImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const adImageStr = adImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            couponShareType,
            couponShareName,
            beginTime,
            endTime,
            couponShareDesc,
            totalAllowNum,
            buyerLimitTotalNum,
            buyerLimitDayNum,
            isCancelClose,
            remarks,
            isAvailable,
            detailJson,
            shareImage: shareImageStr,
            adImage: adImageStr,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}

export default new CouponShare();
export { CouponShare };
