/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import { HttpBase } from '@/api/base/HttpBase';

class Spider extends HttpBase {
    constructor() {
        super();
        this.baseUrl += '/spider';
    }
}

export default new Spider();
export { Spider };
