/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class BusinessOperateLog extends Sp {
    constructor() {
        super();
        this.baseUrl += '/businessOperateLog';
    }

    data({ operateTime, businessTable, businessTableId, currentPage, pageSize } = {}) {
        let operateTimeBegin = null;
        let operateTimeEnd = null;
        if (operateTime != null && operateTime.length === 2) {
            [operateTimeBegin, operateTimeEnd] = operateTime;
        }
        return super.get('/data', {
            operateTimeBegin,
            operateTimeEnd,
            businessTable,
            businessTableId,
            currentPage,
            pageSize,
        });
    }
}

export default new BusinessOperateLog();
export { BusinessOperateLog };
