/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class Warehouse extends Gd {
    constructor() {
        super();
        this.baseUrl += '/warehouse';
    }

    data({ warehouseName, supplierName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            warehouseName,
            supplierName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            if (json.data.data.interfaceConfig) {
                const interfaceConfig = JSON.parse(json.data.data.interfaceConfig);
                json.data.data.gatewayAppKey = interfaceConfig.gatewayAppKey;
                json.data.data.gatewayAppSecret = interfaceConfig.gatewayAppSecret;
                json.data.data.gatewayPlatformName = interfaceConfig.gatewayPlatformName;
                json.data.data.gatewayPlatformCode = interfaceConfig.gatewayPlatformCode;
                json.data.data.gatewayDistributorName = interfaceConfig.gatewayDistributorName;
                json.data.data.gatewayEbpCode = interfaceConfig.gatewayEbpCode;
                json.data.data.gatewayRecpAccount = interfaceConfig.gatewayRecpAccount;
                json.data.data.gatewayRecpCode = interfaceConfig.gatewayRecpCode;
                json.data.data.gatewayRecpName = interfaceConfig.gatewayRecpName;
            }
            return json;
        });
    }

    save({
        id,
        supplierId,
        warehouseName,
        warehouseSn,
        warehouseShipMethod,
        gatewayAppKey,
        gatewayAppSecret,
        gatewayPlatformName,
        gatewayPlatformCode,
        gatewayDistributorName,
        gatewayEbpCode,
        gatewayRecpAccount,
        gatewayRecpCode,
        gatewayRecpName,
        customsPlace,
        warehouseDesc,
        isAvailable,
        isSplitOrderGoods,
        isGetOrderPushtowarehouseInfo,
    } = {}) {
        const interfaceConfig = {
            gatewayAppKey,
            gatewayAppSecret,
            gatewayPlatformName,
            gatewayPlatformCode,
            gatewayDistributorName,
            gatewayEbpCode,
            gatewayRecpAccount,
            gatewayRecpCode,
            gatewayRecpName,
        };
        return super.post('/save', {
            id,
            supplierId,
            warehouseName,
            warehouseSn,
            warehouseShipMethod,
            interfaceConfig: JSON.stringify(interfaceConfig),
            customsPlace,
            warehouseDesc,
            isAvailable,
            isSplitOrderGoods,
            isGetOrderPushtowarehouseInfo,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new Warehouse();
export { Warehouse };
