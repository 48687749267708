/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class CustomsClearance extends Ex {
    constructor() {
        super();
        this.baseUrl += '/customsClearance';
    }

    data({
        customsClearanceStatus,
        orderSn,
        paymentSn,
        externalPaymentSn,
        paymentType,
        certCheckResult,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            customsClearanceStatus,
            orderSn,
            paymentSn,
            externalPaymentSn,
            paymentType,
            certCheckResult,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({
        id,
        orderSn,
        paymentSn,
        paymentTypeName,
        externalPaymentSn,
        clearanceAmount,
        customsPlace,
        merchantCustomsName,
        merchantCustomsCode,
        buyerName,
        buyerIdNo,
    } = {}) {
        return super.post('/save', {
            id,
            orderSn,
            paymentSn,
            paymentTypeName,
            externalPaymentSn,
            clearanceAmount,
            customsPlace,
            merchantCustomsName,
            merchantCustomsCode,
            buyerName,
            buyerIdNo,
        });
    }

    rePush({ ids } = {}) {
        return super.post('/rePush', {
            ids,
        });
    }

    syncCustomsClearance({ ids } = {}) {
        return super.post('/syncCustomsClearance', {
            ids,
        });
    }
}

export default new CustomsClearance();
export { CustomsClearance };
