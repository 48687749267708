/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class Buyer extends Mb {
    constructor() {
        super();
        this.baseUrl += '/buyer';
    }

    data({
        nickName,
        mobile,
        buyerStatus,
        buyerLevelId,
        insertTime,
        referenceBuyer,
        comments,
        currentPage,
        pageSize,
    } = {}) {
        let insertBeginTime = null;
        let insertEndTime = null;
        if (insertTime != null && insertTime.length === 2) {
            [insertBeginTime, insertEndTime] = insertTime;
        }
        return super.get('/data', {
            nickName,
            mobile,
            buyerStatus,
            buyerLevelId,
            insertBeginTime,
            insertEndTime,
            referenceBuyer,
            comments,
            currentPage,
            pageSize,
        });
    }

    selectByPage({
        userName,
        nickName,
        mobile,
        buyerStatus,
        buyerLevelId,
        distributorLevelId,
        isDistributor,
        distributorName,
        distributorTel,
        goodsForbidSellGoodsIdNE,
        idNE,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/selectByPage', {
            userName,
            nickName,
            mobile,
            buyerStatus,
            buyerLevelId,
            distributorLevelId,
            isDistributor,
            distributorName,
            distributorTel,
            goodsForbidSellGoodsIdNE,
            idNE,
            currentPage,
            pageSize,
        });
    }

    changeBuyerLevel({ ids, buyerLevelId }) {
        return super.post('/changeBuyerLevel', {
            ids,
            buyerLevelId,
        });
    }

    changeFrontDecoration({ ids, firstPageDecorationId }) {
        return super.post('/changeFrontDecoration', {
            ids,
            firstPageDecorationId,
        });
    }

    buyerPrepaidPayment({ buyerId, prepaidAmount, cardPrice, payVoucher, paymentComments }) {
        const payVoucherStr = payVoucher
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/buyerPrepaidPayment', {
            buyerId,
            prepaidAmount,
            cardPrice,
            payVoucher: payVoucherStr,
            paymentComments,
        });
    }

    editBuyerInfo({ id, buyerLevelId, referenceId, comments }) {
        return super.post('/editBuyerInfo', { id, buyerLevelId, referenceId, comments });
    }

    platEditPoint({ buyerId, pointNumber, pointDesc }) {
        return super.post('/platEditPoint', { buyerId, pointNumber, pointDesc });
    }

    wxuserList({ nickName, mobile, currentPage, pageSize } = {}) {
        return super.get('/wxuserList', {
            nickName,
            mobile,
            currentPage,
            pageSize,
        });
    }

    mpuserList({ nickName, mobile, currentPage, pageSize } = {}) {
        return super.get('/mpuserList', {
            nickName,
            mobile,
            currentPage,
            pageSize,
        });
    }
}

export default new Buyer();
export { Buyer };
