/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { St } from './St';

class BrowserTypeSaleStat extends St {
    constructor() {
        super();
        this.baseUrl += '/browserTypeSaleStat';
    }

    findStatDetailList({ type, statDateTime, statDateDay, statDateWeek, statDateMonth } = {}) {
        let statDateTimeBegin = null;
        let statDateTimeEnd = null;
        if (statDateTime != null && statDateTime.length === 2) {
            [statDateTimeBegin, statDateTimeEnd] = statDateTime;
        }
        let statDateDayBegin = null;
        let statDateDayEnd = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statDateDayBegin, statDateDayEnd] = statDateDay;
        }
        let statDateMonthBegin = null;
        let statDateMonthEnd = null;
        if (statDateMonth != null && statDateMonth.length === 2) {
            [statDateMonthBegin, statDateMonthEnd] = statDateMonth;
        }
        return super.get('/findStatDetailList', {
            type,
            statDateTimeBegin,
            statDateTimeEnd,
            statDateDayBegin,
            statDateDayEnd,
            statDateMonthBegin,
            statDateMonthEnd,
            statDateWeek,
        });
    }
}

export default new BrowserTypeSaleStat();
export { BrowserTypeSaleStat };
