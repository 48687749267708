<!-- Created by henian.xu on 2021/8/13. -->

<template>
    <el-button-group class="b-map-drawing-manager">
        <!--    <el-button
      size="mini"
      :type="drawType === drawTypeEnum.RECTANGLE ? 'primary' : ''"
      @click="onDraw(drawTypeEnum.RECTANGLE)"
      ><XIcon class="f-c-icon" content="f081"></XIcon
    ></el-button>-->
        <el-button
            v-if="showTypeMap[drawTypeEnum.CIRCLE]"
            size="mini"
            :type="drawType === drawTypeEnum.CIRCLE ? 'primary' : ''"
            @click="onDraw(drawTypeEnum.CIRCLE)"
        >
            <XIcon
                class="f-c-icon"
                content="f080"></XIcon
                >
        </el-button>
        <el-button
            v-if="showTypeMap[drawTypeEnum.POLYGON]"
            size="mini"
            :type="drawType === drawTypeEnum.POLYGON ? 'primary' : ''"
            @click="onDraw(drawTypeEnum.POLYGON)"
        >
            <XIcon
                class="f-c-icon"
                content="f082"></XIcon
                >
        </el-button>
        <!--    <el-button
      size="mini"
      :type="drawType === drawTypeEnum.POLYLINE ? 'primary' : ''"
      @click="onDraw(drawTypeEnum.POLYLINE)"
      ><XIcon class="f-c-icon" content="f083"></XIcon
    ></el-button>-->
        <el-button
            v-if="showTypeMap[drawTypeEnum.DRIVING]"
            size="mini"
            :type="drawType === drawTypeEnum.DRIVING ? 'primary' : ''"
            @click="onDraw(drawTypeEnum.DRIVING)"
        >
            <XIcon
                class="f-c-icon"
                content="f083"></XIcon
                >
        </el-button>
    </el-button-group>
</template>

<script>
import { Utils } from 'vmf';
// eslint-disable-next-line max-classes-per-file
import coordtransform from 'coordtransform';
import { BMapWidgetMixin, getBMapParent } from './BMapWidgetMixin';
import { createMaskOverlayClass, DrivingRoute, OverlayCircle, OverlayPolygon } from './BMap';

export default {
    name: 'BMapDrawingManager',
    mixins: [BMapWidgetMixin],
    data() {
        return {
            drawTypeEnum: {
                // MARKER: 'marker',
                // POLYLINE: 'polyline',
                CIRCLE: 'circle',
                // RECTANGLE: 'rectangle',
                POLYGON: 'polygon',
                DRIVING: 'driving',
                NONE: '',
            },
            drawType: '',
            drawing: false,
            currentOverlay: null,
            overlayList: [],
            MaskOverlayClass: null,
            maskOverlay: null,
        };
    },
    props: {
        showType: {
            type: [Boolean, Array],
            default: false,
        },
        enableEditing: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isStopPropagation() {
            const { drawType, drawTypeEnum } = this;
            return [drawTypeEnum.CIRCLE].includes(drawType);
        },
        showTypeMap() {
            const { showType, drawTypeEnum } = this;
            if (Utils.isBoolean(showType)) {
                return Object.values(drawTypeEnum).reduce((pre, cur) => {
                    pre[cur] = true;
                    return pre;
                }, {});
            }
            if (Utils.isString(showType)) {
                return {
                    [showType]: true,
                };
            }
            if (Utils.isArray(showType)) {
                return showType.reduce((pre, cur) => {
                    pre[cur] = true;
                    return pre;
                }, {});
            }
            return {};
        },
    },
    watch: {
        enableEditing: {
            handler(val) {
                const { overlayList } = this;
                overlayList.forEach(overlay => {
                    if (!overlay.originInstance) return;
                    if (val) {
                        overlay.enableEditing();
                    } else {
                        overlay.disableEditing();
                    }
                });
            },
        },
    },
    methods: {
        async ready() {
            const $parent = getBMapParent(this.$parent);
            this.BMap = $parent.BMap;
            this.map = $parent.map;
            const { BMap } = $parent;

            const MaskOverlayClass = createMaskOverlayClass(BMap);

            this.maskOverlay = new MaskOverlayClass();
            // this.map.addOverlay(this.maskOverlay);
            // this.map.removeOverlay(this.maskOverlay);

            this.maskOverlay.addEventListener('mousedown', this.onMapMousedown);
            this.maskOverlay.addEventListener('mousemove', this.onMapMousemove);
            this.maskOverlay.addEventListener('mouseup', this.onMapMouseup);
            this.maskOverlay.addEventListener('click', this.onMapClick);
            this.maskOverlay.addEventListener('dblclick', this.onMapDblclick);
            this.maskOverlay.addEventListener('rightclick', this.onMapDblclick);

            // this.map.addEventListener('click', this.onMapClick);
            // this.map.addEventListener('mousemove', this.onMapMousemove);
            // this.map.addEventListener('mouseup', this.onMapMouseup);
            // this.map.addEventListener('rightclick', this.onMapRightClick);
        },
        onMapMousedown(event) {
            if (this.isStopPropagation) {
                event.stopPropagation();
            }
            const { drawing, drawType, drawTypeEnum, BMap, map } = this;
            if (drawing) return;
            if (drawType === drawTypeEnum.CIRCLE) {
                this.drawing = true;
                this.currentOverlay = new OverlayCircle(event.point, 0, {
                    BMap,
                    map,
                });
            }
        },
        onMapMousemove(event) {
            if (this.isStopPropagation) {
                event.stopPropagation();
            }
            const {
                drawing,
                drawType,
                drawTypeEnum,
                // BMap,
                map,
                currentOverlay,
            } = this;
            if (!drawing) return;
            if (drawType === drawTypeEnum.POLYGON) {
                currentOverlay.lastPosition(event.point);
            } else if (drawType === drawTypeEnum.CIRCLE) {
                const distance = map.getDistance(currentOverlay.center, event.point);
                currentOverlay.radius = distance;
            }
        },
        onMapMouseup(event) {
            if (this.isStopPropagation) {
                event.stopPropagation();
            }
            const { drawing, drawType, drawTypeEnum } = this;
            if (!drawing) return;
            if (drawType === drawTypeEnum.CIRCLE) {
                const { currentOverlay } = this;
                if (currentOverlay && currentOverlay.radius < 3) {
                    currentOverlay.destroy();
                } else if (currentOverlay) {
                    currentOverlay.enableEditing();
                }
                if (currentOverlay.originInstance) {
                    this.overlayList.push(currentOverlay);
                }
                this.currentOverlay = null;
                this.drawing = false;
                // 退出编辑
                this.onDraw(this.drawType);
            }
        },
        onMapClick(event) {
            if (this.isStopPropagation) {
                event.stopPropagation();
            }
            const { drawing, drawType, drawTypeEnum, currentOverlay, BMap, map } = this;
            // if (!drawing) return;
            if (drawType === drawTypeEnum.POLYGON) {
                if (!drawing) {
                    this.drawing = true;
                    this.currentOverlay = new OverlayPolygon([event.point, event.point], {
                        BMap,
                        map,
                    });
                } else {
                    currentOverlay.addPosition(event.point);
                }
            } else if (drawType === drawTypeEnum.DRIVING) {
                if (!drawing) {
                    this.drawing = true;
                    this.currentOverlay = new DrivingRoute([event.point], {
                        BMap,
                        map,
                    });
                } else {
                    currentOverlay.addWaypoint(event.point);
                }
            }
        },
        onMapDblclick(event) {
            // if (this.isStopPropagation) {
            event.stopPropagation();
            // }
            const { currentOverlay, drawType, drawTypeEnum } = this;
            if (drawType === drawTypeEnum.POLYGON) {
                const { pointPath } = currentOverlay;
                pointPath.pop();
                // pointPath.pop();
                currentOverlay.setPath(pointPath);
            } else if (drawType === drawTypeEnum.DRIVING) {
                // const { waypoints } = currentOverlay;
                // waypoints.pop();
                // waypoints.pop();
                // console.log(waypoints);
                // currentOverlay.waypoints = waypoints;
            }
            if (currentOverlay) currentOverlay.enableEditing();
            if (currentOverlay.originInstance) this.overlayList.push(currentOverlay);
            this.currentOverlay = null;
            this.drawing = false;
            // 退出编辑
            this.onDraw(this.drawType);
        },
        onDraw(type) {
            if (this.drawing) {
                this.$message.error('请先完成当前绘制！');
                return;
            }
            if (this.drawType === type) {
                this.drawType = this.drawTypeEnum.NONE;
                if (this.maskOverlay.isVisible()) {
                    this.map.removeOverlay(this.maskOverlay);
                }
                return;
            }
            this.drawType = type;
            if (!this.maskOverlay.isVisible()) {
                this.map.addOverlay(this.maskOverlay);
            }
        },
        getDrawData() {
            const { overlayList } = this;
            if (!overlayList || !overlayList.length) return [];
            const { drawTypeEnum } = this;
            const overlayList_ = [];
            const res = overlayList.reduce((pre, cur) => {
                const { type, originInstance } = cur;
                if (!originInstance) return pre;
                overlayList_.push(cur);
                const item = {};
                if (type === drawTypeEnum.CIRCLE) {
                    const { center, radius } = cur;
                    item.type = type;
                    let [lng, lat] = coordtransform.bd09togcj02(center.lng, center.lat);
                    item.center = { lng, lat };
                    item.radius = radius;
                    const bounds = cur.getBounds();
                    let point_ = bounds.getSouthWest();
                    [lng, lat] = coordtransform.bd09togcj02(point_.lng, point_.lat);
                    item.southWestPoint = { lng, lat };
                    point_ = bounds.getNorthEast();
                    [lng, lat] = coordtransform.bd09togcj02(point_.lng, point_.lat);
                    item.northEastPoint = { lng, lat };
                } else if (type === drawTypeEnum.POLYGON) {
                    const { pointPath } = cur;
                    item.type = type;
                    item.points = pointPath.map(point => {
                        const [lng, lat] = coordtransform.bd09togcj02(point.lng, point.lat);
                        return { lng, lat };
                    });
                } else if (type === drawTypeEnum.DRIVING) {
                    const { polylines } = cur;
                    item.type = type;
                    item.paths = polylines.reduce((pre_, polyline) => {
                        const path = polyline.getPath();
                        pre_.push(
                            path.map(point => {
                                const [lng, lat] = coordtransform.bd09togcj02(point.lng, point.lat);
                                return { lng, lat };
                            }),
                        );
                        return pre_;
                    }, []);
                }

                if (item.type) {
                    pre.push(item);
                }
                return pre;
            }, []);
            this.overlayList = overlayList_;
            return res;
        },
        setDrawData(list) {
            const { drawTypeEnum, BMap, map, enableEditing } = this;
            this.overlayList.forEach(overlay => {
                if (overlay.destroy) overlay.destroy();
            });
            const overlayList = [];
            const viewportPoints = [];
            list.forEach(item => {
                const { type } = item;
                let overlay = null;
                if (type === drawTypeEnum.CIRCLE) {
                    let { center } = item;
                    const { radius } = item;
                    if (!center.lng || !center.lat || !radius) return;
                    const [lng, lat] = coordtransform.gcj02tobd09(center.lng, center.lat);
                    center = new BMap.Point(lng, lat);
                    overlay = new OverlayCircle(center, radius, {
                        enableEditing,
                        BMap,
                        map,
                    });
                    const bounds = overlay.getBounds();
                    viewportPoints.push(bounds.getSouthWest(), bounds.getNorthEast());
                } else if (type === drawTypeEnum.POLYGON) {
                    let { points } = item;
                    if (points.length < 2) return;
                    points = points.map(point => {
                        const [lng, lat] = coordtransform.gcj02tobd09(point.lng, point.lat);
                        return { lng, lat };
                    });
                    viewportPoints.push(...points);
                    overlay = new OverlayPolygon(points, {
                        enableEditing,
                        BMap,
                        map,
                    });
                } else if (type === drawTypeEnum.DRIVING) {
                    let { paths } = item;
                    if (paths.length < 1) return;
                    paths = paths.reduce((p, c) => {
                        if (!Array.isArray(c) || !c.length) return p;
                        const l = c.map(point => {
                            const [lng, lat] = coordtransform.gcj02tobd09(point.lng, point.lat);
                            return { lng, lat };
                        });
                        p.push(l);
                        viewportPoints.push(...l);
                        return p;
                    }, []);
                    overlay = new DrivingRoute(null, {
                        enableEditing,
                        paths,
                        BMap,
                        map,
                    });
                }

                if (overlay) {
                    overlayList.push(overlay);
                }
            });
            if (viewportPoints && viewportPoints.length) map.setViewport(viewportPoints);
            this.overlayList = overlayList;
        },
    },
};
</script>

<style lang="scss">
.b-map-drawing-manager {
}
</style>
