<!-- Created by henian.xu on 2021/8/12. -->

<template>
    <div class="marker-info-window">
        <div class="header">
            <div class="label">{{ dataset.carLicenseNumber }}</div>
            <div class="info">
                <span>车辆{{ dataset.online ? '在线' : '离线' }}</span>
                <span>时速：{{ dataset.speed / 10 }}KM/h</span>
            </div>
            <div class="address">{{ dataset.location }}</div>
        </div>
        <div class="body">
            <div class="dl-warp">
                <dl>
                    <dt>上报时间</dt>
                    <dd>{{ dataset.uploadTime | moment('YYYY-MM-DD HH:mm:ss') }}</dd>
                </dl>
                <dl>
                    <dt>执行运单</dt>
                    <dd>
                        <span v-if="dataset.orderSn">
                            {{ dataset.orderSn }}({{ dataset.orderStatusName }}) </span
                        ><span v-else>无</span>
                    </dd>
                </dl>
                <dl>
                    <dt>车辆</dt>
                    <dd>
                        {{
                            dataset.orderCarLicenseNumber
                        }}<span
                            v-if="dataset.towingCarLicenseNumber"
                        >+{{ dataset.towingCarLicenseNumber }}</span
                        >
                    </dd>
                </dl>
                <dl>
                    <dt>货物</dt>
                    <dd>
                        {{ dataset.goodsName }}
                        <span
                            v-if="dataset.planTotalWeight"
                        >{{ dataset.planTotalWeight }}吨</span
                        >
                        <a href="#">msds卡</a>
                        <a href="#">道路运输危险货物安全卡</a>
                    </dd>
                </dl>
                <dl>
                    <dt>起运地</dt>
                    <dd>{{ dataset.startRegionName }}</dd>
                </dl>
                <dl>
                    <dt>送达地</dt>
                    <dd>{{ dataset.arriveRegionName }}</dd>
                </dl>
                <dl>
                    <dt>托运人</dt>
                    <dd>{{ dataset.consignerName }}</dd>
                </dl>
                <dl>
                    <dt>承运人</dt>
                    <dd>{{ dataset.companyName }}</dd>
                </dl>
                <dl>
                    <dt>司机</dt>
                    <dd>{{ dataset.driverName }} {{ dataset.driverTel }}</dd>
                </dl>
                <dl>
                    <dt>押运员</dt>
                    <dd>{{ dataset.escortName }} {{ dataset.escortTel }}</dd>
                </dl>
            </div>
        </div>
        <div class="footer">
            <div class="item">
                <a
                    :href="
                        '#/co/vehicle/vehicleLocationPlayback?companyId=' +
                            dataset.companyId +
                            '&vehicleId=' +
                            dataset.companyDeviceId
                    "
                    target="_blank"
                >实时轨迹</a
                >
            </div>
            <div
                class="item"
                v-if="!!dataset.transportOrderId">
                <a
                    :href="
                        '#/od/transportOrder/transportOrder/view/' +
                            dataset.transportOrderId
                    "
                    target="_blank"
                >运单详情</a
                >
            </div>
            <div class="item">
                <a
                    :href="'#/od/transportOrder/transportOrder?carId=' + dataset.id"
                    target="_blank"
                >往期运单</a
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoWindow',
    data() {
        return {};
    },
    props: {
        dataset: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss">
.marker-info-window {
    > .header {
        > .info {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: $padding-small 0;

            > span {
                padding: 0 $padding-small;
                line-height: 1;

                + span {
                    border-left: 1px solid $color-text;
                }
            }
        }
    }

    > .body {
        > .dl-warp {
            > dl {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;

                > dt {
                    flex: 0 0 auto;
                    margin-right: $margin-small;

                    &:after {
                        content: ':';
                    }
                }

                > dd {
                    flex: 1;
                }

                + dl {
                    margin-top: $margin-small;
                }
            }
        }
    }

    > .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: $padding-small 0;
        color: $color-primary;

        > .item {
            padding: 0 $padding-small;
            line-height: 1;

            + .item {
                border-left: 1px solid $color-text;
            }
        }
    }
}
</style>
