/**
 * Created by henian.xu on 2021/8/13.
 *
 */

export const getBMapParent = $component =>
    $component.abstract || $component.$el === $component.$children[0].$el
        ? getBMapParent($component.$parent)
        : $component;

export const BMapWidgetMixin = {
    data() {
        return {
            BMap: null,
            map: null,
        };
    },
    methods: {
        async ready() {
            const $parent = getBMapParent(this.$parent);
            this.BMap = $parent.BMap;
            this.map = $parent.map;
        },
    },
    mounted() {
        const $parent = getBMapParent(this.$parent);
        const { map } = $parent;
        const { ready } = this;
        if (map) {
            ready();
        } else {
            $parent.$on('ready', ready);
        }
    },
};
