// import '@babel/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Utils, Vue } from 'vmf';
import './router';
import './store';
import '@/assets/styles/app.scss';
import Components from '@vmf/components';
import LocalComponents from '@/components';
import api from './api/install';
import WebSocket from './plugins/WebSocket';
import voiceQueue from './plugins/voiceQueue';
import i18n, { localeLanguage } from './plugins/i18n';

const { GlobalVar } = Utils;
Vue.prototype.$globalVar = GlobalVar;

Vue.use(Components);
Vue.use(LocalComponents);
Vue.use(WebSocket);
Vue.use(voiceQueue);
Vue.use(api);

export async function beforeCreate() {
    return i18n.changeLanguage(localeLanguage || GlobalVar.currentLanguage || 'en');
}
