/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class OrderRefund extends Ex {
    constructor() {
        super();
        this.baseUrl += '/orderRefund';
    }

    data({
        refundSn,
        orderSn,
        orderRefundType,
        refundStatus,
        refundType,
        goodsName,
        insertTime,
        currentPage,
        pageSize,
    } = {}) {
        let insertTimeBegin = null;
        let insertTimeEnd = null;
        if (insertTime != null && insertTime.length === 2) {
            [insertTimeBegin, insertTimeEnd] = insertTime;
        }
        return super.get('/data', {
            refundSn,
            orderSn,
            orderRefundType,
            refundStatus,
            refundType,
            goodsName,
            insertTimeBegin,
            insertTimeEnd,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/detail', { id });
    }

    orderRefundAudit({ id, refundAmount, auditStatus, auditAttachment, auditDesc } = {}) {
        const auditAttachmentStr = auditAttachment
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/orderRefundAudit', {
            id,
            refundAmount,
            auditStatus,
            auditAttachment: auditAttachmentStr,
            auditDesc,
        });
    }

    orderRefundPay({ id, paymentAttachment, paymentDesc } = {}) {
        const paymentAttachmentStr = paymentAttachment
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/orderRefundPay', {
            id,
            paymentAttachment: paymentAttachmentStr,
            paymentDesc,
        });
    }

    orderRefundRec({ id } = {}) {
        return super.post('/orderRefundRec', {
            id,
        });
    }
}

export default new OrderRefund();
export { OrderRefund };
