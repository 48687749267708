/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class OrderImp extends Ex {
    constructor() {
        super();
        this.baseUrl += '/orderImp';
    }

    orderList(queryData) {
        let orderBeginTime = null;
        let orderEndTime = null;
        if (queryData.orderTime != null && queryData.orderTime.length === 2) {
            [orderBeginTime, orderEndTime] = queryData.orderTime;
        }
        return super.get('/orderList', {
            ...queryData,
            orderBeginTime,
            orderEndTime,
        });
    }

    getDetail({ id }) {
        return super.get('/getDetail', { id });
    }

    editOrderDetailInfo({ id, orderDetailListJson }) {
        return super.post('/editOrderDetailInfo', { id, orderDetailListJson });
    }

    syncOrder({ ids, syncTime }) {
        let syncTimeBegin = null;
        let syncTimeEnd = null;
        if (syncTime != null && syncTime.length === 2) {
            [syncTimeBegin, syncTimeEnd] = syncTime;
        }
        return super.post(
            '/syncOrder',
            {
                ids,
                syncTimeBegin,
                syncTimeEnd,
            },
            { timeout: 0, isHandleError: true },
        );
    }

    syncWeimobcloudSkuInfo() {
        return super.post('/syncWeimobcloudSkuInfo', {}, { timeout: 0 });
    }

    syncWeimobcloudSkuStock() {
        return super.post('/syncWeimobcloudSkuStock', {}, { timeout: 0 });
    }

    syncWeimobcloudGoods() {
        return super.post('/syncWeimobcloudGoods', {}, { timeout: 0 });
    }
}

export default new OrderImp();
export { OrderImp };
