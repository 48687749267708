/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class Goods extends Gd {
    constructor() {
        super();
        this.baseUrl += '/goods';
    }

    data({
        goodsName,
        goodsSn,
        goodsCatId,
        supplierId,
        goodsStatusType,
        shopGoodsCatId,
        storeInitGoodsCatId,
        isPrivateCustom,
        goodsSendModeList,
        ids,
        orderByKey,
        orderByType,
        goodsStatus,
        isActivityGoods,
        activityId,
        goodsForbidSellBuyerIdNE,
        shopGoodsCatIdNE,
        storeInitGoodsCatIdNE,
        supplierQuotationsNE,
        goodsAgentNE,
        isAgent,
        agentShopId,
        shopIdNull,
        quotationsStockMin,
        quotationsStockMax,
        isSetSupplierQuotations,
        userFilter,
        isAlarmStock,
        isCombinationGoods,
        isMultipleGoods,
        isStoreGoods,
        storeId,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            goodsName,
            goodsSn,
            goodsCatId,
            supplierId,
            goodsStatusType,
            shopGoodsCatId,
            storeInitGoodsCatId,
            isPrivateCustom,
            goodsSendModeList,
            ids,
            orderByKey,
            orderByType,
            goodsStatus,
            isActivityGoods,
            activityId,
            goodsForbidSellBuyerIdNE,
            shopGoodsCatIdNE,
            storeInitGoodsCatIdNE,
            supplierQuotationsNE,
            goodsAgentNE,
            isAgent,
            agentShopId,
            shopIdNull,
            quotationsStockMin,
            quotationsStockMax,
            isSetSupplierQuotations,
            userFilter,
            isAlarmStock,
            isCombinationGoods,
            isMultipleGoods,
            isStoreGoods,
            storeId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/getDetail', { id });
    }

    getGoodsDetailVo({ id }) {
        return super.get('/getGoodsDetailVo', { id });
    }

    save(data) {
        return super.postJson('/save', data);
    }

    saveAndPublish(data) {
        return super.postJson('/saveAndPublish', data);
    }

    onSaleGoods({ goodsId, orderDetailListJson }) {
        return super.post('/onSaleGoods', { goodsId, orderDetailListJson });
    }

    offSaleGoods({ goodsId }) {
        return super.post('/offSaleGoods', { goodsId });
    }

    /**
     * 获取商品(当选择/装修时用)
     * @param ids 有值不分页
     * @param orderByKey
     * @param orderByType
     *
     * @param goodsName
     * @param goodsSn
     * @param goodsStatus
     * @param isActivityGoods
     * @param activityId
     * @param currentPage
     * @param pageSize
     * @returns {AxiosPromise<any>}
     */
    selectByPage(
        {
            ids,
            orderByKey,
            orderByType,
            goodsName,
            goodsSn,
            goodsCatId,
            goodsStatus,
            isActivityGoods,
            activityId,
            activityIdNE,
            goodsForbidSellBuyerIdNE,
            shopGoodsCatIdNE,
            storeInitGoodsCatIdNE,
            supplierQuotationsNE,
            goodsAgentNE,
            shopIdNull,
            goodsStatusTypeNull,
            couponGiveId,
            couponTempletId,
            shopTagId,
            shopTagIds,
            shopTagIdList,
            shopTagIdNE,
            paymentMethod,
            goodsLabelIdNE,
            goodsLabelId,
            shopStoreLevelIdRENE,
            shopStoreLevelIdRE,
            goodsSendMode,
            isCombinationGoods,
            isStoreGoods,
            goodsType,
            currentPage,
            pageSize,
        } = {},
        cfg,
    ) {
        if (shopTagIdList && shopTagIdList.length !== 0) {
            shopTagIds = shopTagIdList
                ? shopTagIdList
                      .reduce((prev, curr) => {
                          prev.push(curr);
                          return prev;
                      }, [])
                      .join(',')
                : '';
        }
        return super.get(
            '/selectByPage',
            {
                ids,
                orderByKey,
                orderByType,
                goodsName,
                goodsSn,
                goodsCatId,
                goodsStatus,
                isActivityGoods,
                activityId,
                activityIdNE,
                goodsForbidSellBuyerIdNE,
                shopGoodsCatIdNE,
                storeInitGoodsCatIdNE,
                supplierQuotationsNE,
                goodsAgentNE,
                shopIdNull,
                goodsStatusTypeNull,
                couponGiveId,
                couponTempletId,
                shopTagId,
                shopTagIds,
                shopTagIdNE,
                paymentMethod,
                goodsLabelIdNE,
                goodsLabelId,
                shopStoreLevelIdRENE,
                shopStoreLevelIdRE,
                goodsSendMode,
                isCombinationGoods,
                isStoreGoods,
                goodsType,
                currentPage,
                pageSize,
            },
            {
                isRepeat: true,
                ...cfg,
            },
        );
    }

    changeOrderNum({ id, orderNum }) {
        return super.post('/changeOrderNum', { id, orderNum });
    }

    changeBaseSales({ id, baseSales }) {
        return super.post('/changeBaseSales', { id, baseSales });
    }

    batchSetBaseSales(data) {
        return super.post('/batchSetBaseSales', data);
    }

    changePackingAmount({ id, packingAmount }) {
        return super.post('/changePackingAmount', { id, packingAmount });
    }

    batchSetPackingAmount(data) {
        return super.post('/batchSetPackingAmount', data);
    }

    editGoods({ id, goodsShareCopy, goodsMultiStyleJson }) {
        return super.post('/editGoods', { id, goodsShareCopy, goodsMultiStyleJson });
    }

    saveThirdPlatformSkuSn({ id, thirdPlatformSkuSn }) {
        return super.post('/saveThirdPlatformInfo', { id, thirdPlatformSkuSn });
    }

    saveThirdPlatformSkuQuantity({ id, thirdPlatformSkuQuantity }) {
        return super.post('/saveThirdPlatformInfo', { id, thirdPlatformSkuQuantity });
    }

    saveGoodsShopGoodsCat({ goodsIds, shopGoodsCatId }) {
        return super.post('/saveGoodsShopGoodsCat', { goodsIds, shopGoodsCatId });
    }

    delGoodsShopGoodsCat({ goodsIds, shopGoodsCatId }) {
        return super.post('/delGoodsShopGoodsCat', { goodsIds, shopGoodsCatId });
    }

    saveGoodsStoreInitGoodsCat({ goodsIds, storeInitGoodsCatId }) {
        return super.post('/saveGoodsStoreInitGoodsCat', { goodsIds, storeInitGoodsCatId });
    }

    delGoodsStoreInitGoodsCat({ goodsIds, storeInitGoodsCatId }) {
        return super.post('/delGoodsStoreInitGoodsCat', { goodsIds, storeInitGoodsCatId });
    }

    findSkuMapList({
        goodsName,
        goodsSn,
        specDesc,
        goodsStatusType,
        goodsCatId,
        supplierId,
        skuSn,
        userFilter,
        goodsSendModeList,
        isMultipleGoods,
        storeId,
        showAllGoods,
        currentPage,
        pageSize,
    } = {}) {
        return super.get(
            '/findSkuMapList',
            {
                goodsName,
                goodsSn,
                specDesc,
                goodsStatusType,
                goodsCatId,
                supplierId,
                skuSn,
                userFilter,
                goodsSendModeList,
                isMultipleGoods,
                storeId,
                showAllGoods,
                currentPage,
                pageSize,
            },
            {
                isRepeat: true,
            },
        );
    }

    changeAlarmStock({ skuIds, alarmStock }) {
        return super.post('/changeAlarmStock', {
            skuIds,
            alarmStock,
        });
    }

    createGoodsQrCode({ goodsId, deviceId }) {
        return super.post('/createGoodsQrCode', { goodsId, deviceId });
    }

    copyGoods({ ids, goodsSendMode }) {
        return super.post('/copyGoods', { ids, goodsSendMode });
    }
}

export default new Goods();
export { Goods };
