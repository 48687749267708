/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class Shop extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shop';
    }

    data({ shopName, shopSn, shopStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            shopName,
            shopSn,
            shopStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { fileBasePath } = json.data.data;
            const { shopLogo } = json.data.data;
            if (!shopLogo) {
                json.data.data.shopLogo = [];
            } else {
                json.data.data.shopLogo = shopLogo.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (json.data.data.otoldWaybillTemplate) {
                const { miniAppsImg } = json.data.data.otoldWaybillTemplate;
                if (!miniAppsImg) {
                    json.data.data.miniAppsImg = [];
                } else {
                    json.data.data.miniAppsImg = miniAppsImg.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                const { customerServiceImg } = json.data.data.otoldWaybillTemplate;
                if (!customerServiceImg) {
                    json.data.data.customerServiceImg = [];
                } else {
                    json.data.data.customerServiceImg = customerServiceImg.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                json.data.data.printerType = json.data.data.otoldWaybillTemplate.printerType;
                json.data.data.waybillTemplate = json.data.data.otoldWaybillTemplate.waybillTemplate;
                json.data.data.storeTemplate = json.data.data.otoldWaybillTemplate.storeTemplate;
                json.data.data.orderCancelTemplate = json.data.data.otoldWaybillTemplate.orderCancelTemplate;
            }
            const { shopNoticeImage } = json.data.data;
            if (!shopNoticeImage) {
                json.data.data.shopNoticeImage = [];
            } else {
                json.data.data.shopNoticeImage = shopNoticeImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (json.data.data.otherConfig) {
                const { loadingImg } = json.data.data.otherConfig;
                if (!loadingImg) {
                    json.data.data.loadingImg = [];
                } else {
                    json.data.data.loadingImg = loadingImg.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                const { shopShareLogo } = json.data.data.otherConfig;
                if (!shopShareLogo) {
                    json.data.data.shopShareLogo = [];
                } else {
                    json.data.data.shopShareLogo = shopShareLogo.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                const { wxQrCode } = json.data.data.otherConfig;
                if (!wxQrCode) {
                    json.data.data.wxQrCode = [];
                } else {
                    json.data.data.wxQrCode = wxQrCode.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                const { enterpriseWeChatImage } = json.data.data.otherConfig;
                if (!enterpriseWeChatImage) {
                    json.data.data.enterpriseWeChatImage = [];
                } else {
                    json.data.data.enterpriseWeChatImage = enterpriseWeChatImage.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                json.data.data.currencySymbol = json.data.data.otherConfig.currencySymbol;
                json.data.data.currencyCode = json.data.data.otherConfig.currencyCode;
                json.data.data.themeColorMain = json.data.data.otherConfig.themeColorMain;
                json.data.data.themeColorSecond = json.data.data.otherConfig.themeColorSecond;
                json.data.data.isQuickBuyerAddress = json.data.data.otherConfig.isQuickBuyerAddress;
                json.data.data.moduleConfigCart = json.data.data.otherConfig.moduleConfigCart;
                json.data.data.meiQiaEntId = json.data.data.otherConfig.meiQiaEntId;
                json.data.data.meiQiaCustomerService = json.data.data.otherConfig.meiQiaCustomerService;
                json.data.data.distributorPosterConfig = json.data.data.otherConfig.distributorPosterConfig;
                json.data.data.storeOfflinePosterConfig = json.data.data.otherConfig.storeOfflinePosterConfig;
                json.data.data.isPushOrderErp = json.data.data.otherConfig.isPushOrderErp;
                json.data.data.erpAppKey = json.data.data.otherConfig.erpAppKey;
                json.data.data.erpAppSecret = json.data.data.otherConfig.erpAppSecret;
                json.data.data.isPushOrderToGateway = json.data.data.otherConfig.isPushOrderToGateway;
                json.data.data.gatewayAppKey = json.data.data.otherConfig.gatewayAppKey;
                json.data.data.gatewayAppSecret = json.data.data.otherConfig.gatewayAppSecret;
                json.data.data.gatewayPlatformName = json.data.data.otherConfig.gatewayPlatformName;
                json.data.data.gatewayPlatformCode = json.data.data.otherConfig.gatewayPlatformCode;
                json.data.data.gatewayDistributorName = json.data.data.otherConfig.gatewayDistributorName;
                json.data.data.gatewayEbpCode = json.data.data.otherConfig.gatewayEbpCode;
                json.data.data.gatewayRecpAccount = json.data.data.otherConfig.gatewayRecpAccount;
                json.data.data.gatewayRecpCode = json.data.data.otherConfig.gatewayRecpCode;
                json.data.data.gatewayRecpName = json.data.data.otherConfig.gatewayRecpName;
                json.data.data.isShowRMBAmount = json.data.data.otherConfig.isShowRMBAmount;
                json.data.data.RMBAmountRate = json.data.data.otherConfig.RMBAmountRate;
                json.data.data.sendConsignor = json.data.data.otherConfig.sendConsignor;
                json.data.data.sendTel = json.data.data.otherConfig.sendTel;
                json.data.data.isStoreEditSendInfo = json.data.data.otherConfig.isStoreEditSendInfo;
                json.data.data.qskdAppKey = json.data.data.otherConfig.qskdAppKey;
                json.data.data.qskdAppSecret = json.data.data.otherConfig.qskdAppSecret;
                json.data.data.amountFormat = json.data.data.otherConfig.amountFormat;
                json.data.data.isOrderEditSendInfo = json.data.data.otherConfig.isOrderEditSendInfo;
                json.data.data.minWithdrawAmount = json.data.data.otherConfig.minWithdrawAmount;
                json.data.data.withdrawPoundageRate = json.data.data.otherConfig.withdrawPoundageRate;
                json.data.data.withdrawAutoPassTime = json.data.data.otherConfig.withdrawAutoPassTime;
                json.data.data.withdrawDesc = json.data.data.otherConfig.withdrawDesc;
                json.data.data.goodsSendMode = json.data.data.otherConfig.goodsSendMode
                    ? json.data.data.otherConfig.goodsSendMode.split(',')
                    : [];
                json.data.data.threeFactorCheck = json.data.data.otherConfig.threeFactorCheck
                    ? json.data.data.otherConfig.threeFactorCheck.split(',')
                    : [];
                json.data.data.pickupGoodsSendMode = json.data.data.otherConfig.pickupGoodsSendMode
                    ? json.data.data.otherConfig.pickupGoodsSendMode.split(',')
                    : [];
                json.data.data.goodsConfig = json.data.data.otherConfig.goodsConfig;
                json.data.data.buyerConfig = json.data.data.otherConfig.buyerConfig;
                json.data.data.marketCodeContacts = json.data.data.otherConfig.marketCodeContacts;
                json.data.data.marketCodeCouponId = json.data.data.otherConfig.marketCodeCouponId;
                json.data.data.marketCodeWarnNum = json.data.data.otherConfig.marketCodeWarnNum;
                json.data.data.isGiftCardReturnPurse = json.data.data.otherConfig.isGiftCardReturnPurse;
                json.data.data.isRegisterIHealth = json.data.data.otherConfig.isRegisterIHealth;
                json.data.data.defaultShareIncomeRate = json.data.data.otherConfig.defaultShareIncomeRate;
                json.data.data.storeIncomeRate = json.data.data.otherConfig.storeIncomeRate;
                json.data.data.iHealthApiKey = json.data.data.otherConfig.iHealthApiKey;
                json.data.data.iHealthApiLock = json.data.data.otherConfig.iHealthApiLock;
                json.data.data.isUseCommWechatAuth = json.data.data.otherConfig.isUseCommWechatAuth;
                json.data.data.isUseCommPaySite = json.data.data.otherConfig.isUseCommPaySite;
                json.data.data.orderFreezeTime = json.data.data.otherConfig.orderFreezeTime;
                json.data.data.orderAllowRefundTime = json.data.data.otherConfig.orderAllowRefundTime;
                json.data.data.directMailOrderAllowRefundTime =
                    json.data.data.otherConfig.directMailOrderAllowRefundTime;
                json.data.data.enableBehavior = json.data.data.otherConfig.enableBehavior;
                json.data.data.isSyncWeimobcloud = json.data.data.otherConfig.isSyncWeimobcloud;
                json.data.data.isSyncWeimobcloudStock = json.data.data.otherConfig.isSyncWeimobcloudStock;
                json.data.data.weimobcloudShopId = json.data.data.otherConfig.weimobcloudShopId;
                json.data.data.weimobcloudClientId = json.data.data.otherConfig.weimobcloudClientId;
                json.data.data.weimobcloudClientSecret = json.data.data.otherConfig.weimobcloudClientSecret;
                json.data.data.isSyncSifeiErp = json.data.data.otherConfig.isSyncSifeiErp;
                json.data.data.sifeiAppId = json.data.data.otherConfig.sifeiAppId;
                json.data.data.sifeiAppKey = json.data.data.otherConfig.sifeiAppKey;
                json.data.data.isSyncPospal = json.data.data.otherConfig.isSyncPospal;
                json.data.data.isSyncPospalStock = json.data.data.otherConfig.isSyncPospalStock;
                json.data.data.pospalAreaId = json.data.data.otherConfig.pospalAreaId;
                json.data.data.pospalAppId = json.data.data.otherConfig.pospalAppId;
                json.data.data.pospalAppKey = json.data.data.otherConfig.pospalAppKey;
                json.data.data.eachLevelIncomeDayLimit = json.data.data.otherConfig.eachLevelIncomeDayLimit;
                json.data.data.goodsCatStyle = json.data.data.otherConfig.goodsCatStyle;
                json.data.data.isShowEnterpriseWeChat = json.data.data.otherConfig.isShowEnterpriseWeChat;
                json.data.data.isPushInventory = json.data.data.otherConfig.isPushInventory;
                json.data.data.isSyncInventoryGoods = json.data.data.otherConfig.isSyncInventoryGoods;
                json.data.data.inventoryAppKey = json.data.data.otherConfig.inventoryAppKey;
                json.data.data.inventoryAppSecret = json.data.data.otherConfig.inventoryAppSecret;
                json.data.data.isStoreShowShopGoods = json.data.data.otherConfig.isStoreShowShopGoods;
                json.data.data.weixinWithdrawConfig = json.data.data.otherConfig.weixinWithdrawConfig;
                json.data.data.balanceAlias = json.data.data.otherConfig.balanceAlias;
                json.data.data.isAutoStockHandleOrder = json.data.data.otherConfig.isAutoStockHandleOrder;
                json.data.data.isSyncKuaidi100TrackingInfo = json.data.data.otherConfig.isSyncKuaidi100TrackingInfo;
                json.data.data.kuaidi100Customer = json.data.data.otherConfig.kuaidi100Customer;
                json.data.data.kuaidi100ConstAppKey = json.data.data.otherConfig.kuaidi100ConstAppKey;
                json.data.data.orderAmountLimit = json.data.data.otherConfig.orderAmountLimit;
                json.data.data.autoSettlementDay = json.data.data.otherConfig.autoSettlementDay;
            }
            return json;
        });
    }

    save({
        id,
        orgId,
        shopName,
        shopSn,
        shopLogo,
        miniAppsImg,
        customerServiceImg,
        printerType,
        waybillTemplate,
        storeTemplate,
        orderCancelTemplate,
        principalName,
        principalPosition,
        principalMobile,
        principalEmail,
        principalQq,
        shopRegionId,
        shopAddress,
        shopDesc,
        freightMode,
        isAllowMoreLevel,
        shopDomain,
        shopLevelId,
        shopStatus,
        joinTime,
        memberRegisterMode,
        memberAccountSign,
        wxaccountType,
        appid,
        secret,
        token,
        aeskey,
        maAppid,
        maSecret,
        maToken,
        maAeskey,
        mapPlaceKey,
        publicApikey,
        publicSecret,
        publicToken,
        remarks,
        distributionIncomeMode,
        distributorRegisterMode,
        isMustInvitationCode,
        distributionLevel,
        sellGoodsType,
        shipMethod,
        paymentMode,
        onlinePaymentType,
        prepaidPaymentType,
        storePaymentType,
        orderNoticeMode,
        orderNoticeMobile,
        orderNoticeWebchat,
        isAllowOrder,
        orderFreezeTime,
        orderAllowRefundTime,
        directMailOrderAllowRefundTime,
        isShowNotice,
        shopNoticeImage,
        shopNoticeLink,
        loadingImg,
        shopShareLogo,
        wxQrCode,
        currencySymbol,
        currencyCode,
        themeColorMain,
        themeColorSecond,
        isQuickBuyerAddress,
        moduleConfigCart,
        isGoodsMultiStyle,
        shopStoreType,
        meiQiaEntId,
        meiQiaCustomerService,
        distributorPosterConfig,
        storeOfflinePosterConfig,
        isPushOrderErp,
        erpAppKey,
        erpAppSecret,
        isPushOrderToGateway,
        gatewayAppKey,
        gatewayAppSecret,
        gatewayPlatformName,
        gatewayPlatformCode,
        gatewayDistributorName,
        gatewayEbpCode,
        gatewayRecpAccount,
        gatewayRecpCode,
        gatewayRecpName,
        isShowRMBAmount,
        RMBAmountRate,
        sendConsignor,
        sendTel,
        isStoreEditSendInfo,
        storeOpenMode,
        qskdAppKey,
        qskdAppSecret,
        amountFormat,
        prepaidPaymentMode,
        distributorQrcodeExpire,
        isDistributorQrcodeUnique,
        isOrderEditSendInfo,
        minWithdrawAmount,
        withdrawPoundageRate,
        withdrawAutoPassTime,
        withdrawDesc,
        goodsSendMode,
        threeFactorCheck,
        pickupGoodsSendMode,
        goodsConfig,
        buyerConfig,
        marketCodeContacts,
        marketCodeCouponId,
        marketCodeWarnNum,
        isGiftCardReturnPurse,
        isRegisterIHealth,
        defaultShareIncomeRate,
        storeIncomeRate,
        iHealthApiKey,
        iHealthApiLock,
        isUseCommWechatAuth,
        isUseCommPaySite,
        enableBehavior,
        isSyncWeimobcloud,
        isSyncWeimobcloudStock,
        weimobcloudShopId,
        weimobcloudClientId,
        weimobcloudClientSecret,
        isSyncSifeiErp,
        sifeiAppId,
        sifeiAppKey,
        isSyncPospal,
        isSyncPospalStock,
        pospalAreaId,
        pospalAppId,
        pospalAppKey,
        eachLevelIncomeDayLimit,
        goodsCatStyle,
        isShowEnterpriseWeChat,
        enterpriseWeChatImage,
        isPushInventory,
        isSyncInventoryGoods,
        inventoryAppKey,
        inventoryAppSecret,
        isStoreShowShopGoods,
        weixinWithdrawConfig,
        balanceAlias,
        isAutoStockHandleOrder,
        isSyncKuaidi100TrackingInfo,
        kuaidi100Customer,
        kuaidi100ConstAppKey,
        orderAmountLimit,
        autoSettlementDay,
    } = {}) {
        const shopLogoStr = shopLogo
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const otoldWaybillTemplate = {
            miniAppsImg: miniAppsImg
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            customerServiceImg: customerServiceImg
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            printerType,
            waybillTemplate,
            storeTemplate,
            orderCancelTemplate,
        };
        const shopNotice = {
            shopNoticeImage: shopNoticeImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            shopNoticeLink,
        };
        const otherConfig = {
            loadingImg: loadingImg
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            shopShareLogo: shopShareLogo
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            wxQrCode: wxQrCode
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            currencySymbol,
            currencyCode,
            themeColorMain,
            themeColorSecond,
            isQuickBuyerAddress,
            moduleConfigCart,
            meiQiaEntId,
            meiQiaCustomerService,
            distributorPosterConfig,
            storeOfflinePosterConfig,
            isPushOrderErp,
            erpAppKey,
            erpAppSecret,
            isPushOrderToGateway,
            gatewayAppKey,
            gatewayAppSecret,
            gatewayPlatformName,
            gatewayPlatformCode,
            gatewayDistributorName,
            gatewayEbpCode,
            gatewayRecpAccount,
            gatewayRecpCode,
            gatewayRecpName,
            isShowRMBAmount,
            RMBAmountRate,
            sendConsignor,
            sendTel,
            isStoreEditSendInfo,
            qskdAppKey,
            qskdAppSecret,
            amountFormat,
            isOrderEditSendInfo,
            minWithdrawAmount,
            withdrawPoundageRate,
            withdrawAutoPassTime,
            withdrawDesc,
            goodsSendMode: goodsSendMode.join(','),
            threeFactorCheck: threeFactorCheck.join(','),
            pickupGoodsSendMode: pickupGoodsSendMode.join(','),
            goodsConfig,
            buyerConfig,
            marketCodeContacts,
            marketCodeCouponId,
            marketCodeWarnNum,
            isGiftCardReturnPurse,
            isRegisterIHealth,
            defaultShareIncomeRate,
            storeIncomeRate,
            iHealthApiKey,
            iHealthApiLock,
            isUseCommWechatAuth,
            isUseCommPaySite,
            orderFreezeTime,
            orderAllowRefundTime,
            directMailOrderAllowRefundTime,
            enableBehavior,
            isSyncWeimobcloud,
            isSyncWeimobcloudStock,
            weimobcloudShopId,
            weimobcloudClientId,
            weimobcloudClientSecret,
            isSyncSifeiErp,
            sifeiAppId,
            sifeiAppKey,
            isSyncPospal,
            isSyncPospalStock,
            pospalAreaId,
            pospalAppId,
            pospalAppKey,
            eachLevelIncomeDayLimit,
            goodsCatStyle,
            isShowEnterpriseWeChat,
            enterpriseWeChatImage: enterpriseWeChatImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            isPushInventory,
            isSyncInventoryGoods,
            inventoryAppKey,
            inventoryAppSecret,
            isStoreShowShopGoods,
            weixinWithdrawConfig,
            balanceAlias,
            isAutoStockHandleOrder,
            isSyncKuaidi100TrackingInfo,
            kuaidi100Customer,
            kuaidi100ConstAppKey,
            orderAmountLimit,
            autoSettlementDay,
        };
        return super.post('/save', {
            id,
            orgId,
            shopName,
            shopSn,
            shopLogo: shopLogoStr,
            principalName,
            principalPosition,
            principalMobile,
            principalEmail,
            principalQq,
            shopRegionId,
            shopAddress,
            shopDesc,
            freightMode,
            isAllowMoreLevel,
            shopDomain,
            shopLevelId,
            shopStatus,
            joinTime,
            memberRegisterMode,
            memberAccountSign,
            wxaccountType,
            appid,
            secret,
            token,
            aeskey,
            maAppid,
            maSecret,
            maToken,
            maAeskey,
            mapPlaceKey,
            publicApikey,
            publicSecret,
            publicToken,
            remarks,
            distributionIncomeMode,
            distributorRegisterMode,
            isMustInvitationCode,
            distributionLevel,
            sellGoodsType,
            shipMethod,
            paymentMode,
            onlinePaymentType,
            prepaidPaymentType,
            storePaymentType,
            orderNoticeMode,
            orderNoticeMobile,
            orderNoticeWebchat,
            isAllowOrder,
            isShowNotice,
            shopNotice: JSON.stringify(shopNotice),
            otherConfig: JSON.stringify(otherConfig),
            otoldWaybillTemplate: JSON.stringify(otoldWaybillTemplate),
            isGoodsMultiStyle,
            shopStoreType,
            storeOpenMode,
            prepaidPaymentMode,
            distributorQrcodeExpire,
            isDistributorQrcodeUnique,
        });
    }

    getShopInfo() {
        return super.get('/getShopInfo', {}).then(json => {
            const { fileBasePath } = json.data.data;
            const { shopLogo } = json.data.data;
            if (!shopLogo) {
                json.data.data.shopLogo = [];
            } else {
                json.data.data.shopLogo = shopLogo.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const { shopNoticeImage } = json.data.data;
            if (!shopNoticeImage) {
                json.data.data.shopNoticeImage = [];
            } else {
                json.data.data.shopNoticeImage = shopNoticeImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (json.data.data.otherConfig) {
                const { shopShareLogo } = json.data.data.otherConfig;
                if (!shopShareLogo) {
                    json.data.data.shopShareLogo = [];
                } else {
                    json.data.data.shopShareLogo = shopShareLogo.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                const { enterpriseWeChatImage } = json.data.data.otherConfig;
                if (!enterpriseWeChatImage) {
                    json.data.data.enterpriseWeChatImage = [];
                } else {
                    json.data.data.enterpriseWeChatImage = enterpriseWeChatImage.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                json.data.data.isShowRMBAmount = json.data.data.otherConfig.isShowRMBAmount;
                json.data.data.RMBAmountRate = json.data.data.otherConfig.RMBAmountRate;
                json.data.data.sendConsignor = json.data.data.otherConfig.sendConsignor;
                json.data.data.sendTel = json.data.data.otherConfig.sendTel;
                json.data.data.isStoreEditSendInfo = json.data.data.otherConfig.isStoreEditSendInfo;
                json.data.data.minWithdrawAmount = json.data.data.otherConfig.minWithdrawAmount;
                json.data.data.withdrawPoundageRate = json.data.data.otherConfig.withdrawPoundageRate;
                json.data.data.withdrawAutoPassTime = json.data.data.otherConfig.withdrawAutoPassTime;
                json.data.data.withdrawDesc = json.data.data.otherConfig.withdrawDesc;
                json.data.data.goodsSendMode = json.data.data.otherConfig.goodsSendMode
                    ? json.data.data.otherConfig.goodsSendMode.split(',')
                    : [];
                json.data.data.threeFactorCheck = json.data.data.otherConfig.threeFactorCheck
                    ? json.data.data.otherConfig.threeFactorCheck.split(',')
                    : [];
                json.data.data.pickupGoodsSendMode = json.data.data.otherConfig.pickupGoodsSendMode
                    ? json.data.data.otherConfig.pickupGoodsSendMode.split(',')
                    : [];
                json.data.data.marketCodeContacts = json.data.data.otherConfig.marketCodeContacts;
                json.data.data.marketCodeCouponId = json.data.data.otherConfig.marketCodeCouponId;
                json.data.data.marketCodeWarnNum = json.data.data.otherConfig.marketCodeWarnNum;
                json.data.data.orderFreezeTime = json.data.data.otherConfig.orderFreezeTime;
                json.data.data.orderAllowRefundTime = json.data.data.otherConfig.orderAllowRefundTime;
                json.data.data.directMailOrderAllowRefundTime =
                    json.data.data.otherConfig.directMailOrderAllowRefundTime;
                json.data.data.goodsCatStyle = json.data.data.otherConfig.goodsCatStyle;
                json.data.data.isShowEnterpriseWeChat = json.data.data.otherConfig.isShowEnterpriseWeChat;
                json.data.data.orderAmountLimit = json.data.data.otherConfig.orderAmountLimit;
                json.data.data.autoSettlementDay = json.data.data.otherConfig.autoSettlementDay;
            }
            return json;
        });
    }

    saveShopInfo({
        shopName,
        shopSn,
        shopLogo,
        principalName,
        principalPosition,
        principalMobile,
        principalEmail,
        principalQq,
        shopRegionId,
        shopAddress,
        shopDesc,
        freightMode,
        isAllowMoreLevel,
        isAllowOrder,
        orderFreezeTime,
        orderAllowRefundTime,
        directMailOrderAllowRefundTime,
        isShowNotice,
        shopNoticeImage,
        shopNoticeLink,
        isShowRMBAmount,
        RMBAmountRate,
        sendConsignor,
        sendTel,
        isStoreEditSendInfo,
        minWithdrawAmount,
        withdrawPoundageRate,
        withdrawAutoPassTime,
        withdrawDesc,
        marketCodeContacts,
        marketCodeCouponId,
        marketCodeWarnNum,
        shopShareLogo,
        goodsCatStyle,
        isShowEnterpriseWeChat,
        enterpriseWeChatImage,
        orderAmountLimit,
        autoSettlementDay,
    } = {}) {
        const shopLogoStr = shopLogo
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const shopNotice = {
            shopNoticeImage: shopNoticeImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            shopNoticeLink,
        };
        const otherConfig = {
            shopShareLogo: shopShareLogo
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            isShowRMBAmount,
            RMBAmountRate,
            sendConsignor,
            sendTel,
            isStoreEditSendInfo,
            minWithdrawAmount,
            withdrawPoundageRate,
            withdrawAutoPassTime,
            withdrawDesc,
            marketCodeContacts,
            marketCodeCouponId,
            marketCodeWarnNum,
            orderFreezeTime,
            orderAllowRefundTime,
            directMailOrderAllowRefundTime,
            goodsCatStyle,
            isShowEnterpriseWeChat,
            enterpriseWeChatImage: enterpriseWeChatImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            orderAmountLimit,
            autoSettlementDay,
        };
        return super.post('/saveShopInfo', {
            shopName,
            shopSn,
            shopLogo: shopLogoStr,
            principalName,
            principalPosition,
            principalMobile,
            principalEmail,
            principalQq,
            shopRegionId,
            shopAddress,
            shopDesc,
            freightMode,
            isAllowMoreLevel,
            isAllowOrder,
            isShowNotice,
            shopNotice: JSON.stringify(shopNotice),
            otherConfig: JSON.stringify(otherConfig),
        });
    }

    getShopAvailableGoodsType({ shopId }) {
        return super.get('/getShopAvailableGoodsType', { shopId });
    }

    getShopAvailableDistributionIncomeMode() {
        return super.get('/getShopAvailableDistributionIncomeMode', {});
    }

    getShopAvailablePaymentMode() {
        return super.get('/getShopAvailablePaymentMode', {});
    }

    getShopPaymentTypeList({ shopId }) {
        return super.get('/getShopPaymentTypeList', { shopId });
    }

    getShopOnlinePaymentTypeList() {
        return super.get('/getShopOnlinePaymentTypeList', {});
    }

    getShopPrepaidPaymentTypeList() {
        return super.get('/getShopPrepaidPaymentTypeList', {});
    }

    getShopAvailableStoreType() {
        return super.get('/getShopAvailableStoreType', {});
    }

    getShopShipMethodList({ shopId }) {
        return super.get('/getShopShipMethodList', { shopId });
    }

    getShopGoodsSendModeList({ shopId }) {
        return super.get('/getShopGoodsSendModeList', { shopId });
    }
}

export default new Shop();
export { Shop };
