/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class BuyerReference extends Mb {
    constructor() {
        super();
        this.baseUrl += '/buyerReference';
    }

    list({ userName, mobile, promotionLevelId, parentId, currentPage, pageSize } = {}) {
        return super.get('/list', {
            userName,
            mobile,
            promotionLevelId,
            parentId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    promotionDetailList({ userName, mobile, promotionLevelId, orderSn, settlementStatus, currentPage, pageSize } = {}) {
        return super.get('/promotionDetailList', {
            userName,
            mobile,
            promotionLevelId,
            orderSn,
            settlementStatus,
            currentPage,
            pageSize,
        });
    }
}

export default new BuyerReference();
export { BuyerReference };
