/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class SupplierQuotations extends Gd {
    constructor() {
        super();
        this.baseUrl += '/supplierQuotations';
    }

    quotationsGoodsList({
        goodsName,
        goodsSn,
        goodsCatId,
        supplierQuotationsStatus,
        warehouseId,
        supplierName,
        isAvailable,
        isAlarmStock,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/quotationsGoodsList', {
            goodsName,
            goodsSn,
            goodsCatId,
            supplierQuotationsStatus,
            warehouseId,
            supplierName,
            isAvailable,
            isAlarmStock,
            currentPage,
            pageSize,
        });
    }

    getSupplierQuotations({ goodsId }) {
        return super.get('/getSupplierQuotations', { goodsId });
    }

    saveSupplierQuotations({ goodsId, warehouseId, skuListJson }) {
        return super.post('/saveSupplierQuotations', { goodsId, warehouseId, skuListJson });
    }

    onEditQuotationsStock({ goodsId, skuListJson }) {
        return super.post('/editQuotationsStock', { goodsId, skuListJson });
    }

    getDetail({ id }) {
        return super.get('/getDetail', { id });
    }

    auditSupplierQuotations({ id, supplierQuotationsStatus, auditRemarks, supplierQuotationsListJson }) {
        return super.post('/auditSupplierQuotations', {
            id,
            supplierQuotationsStatus,
            auditRemarks,
            supplierQuotationsListJson,
        });
    }

    getGoodsSupplierQuotations({ goodsId }) {
        return super.get('/getGoodsSupplierQuotations', { goodsId });
    }

    onEditSupplierQuotations({ id, isAvailable, orderNum }) {
        return super.post('/editSupplierQuotations', { id, isAvailable, orderNum });
    }

    batchAudit({ ids, supplierQuotationsStatus }) {
        return super.post('/batchAudit', { ids, supplierQuotationsStatus });
    }
}

export default new SupplierQuotations();
export { SupplierQuotations };
