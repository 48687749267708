/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import DistributorStat from './DistributorStat';
import BuyerVisitLog from './BuyerVisitLog';
import GoodsStat from './GoodsStat';
import PayStat from './PayStat';
import PrepaidCardStat from './PrepaidCardStat';
import AgentFeeOrderStat from './AgentFeeOrderStat';
import BuyerStat from './BuyerStat';
import OpenStoreOrderStat from './OpenStoreOrderStat';
import OrderStat from './OrderStat';
import DriverStat from './DriverStat';
import MarketingStat from './MarketingStat';
import BuyerBehaviorLogStat from './BuyerBehaviorLogStat';
import ShopStoreStat from './ShopStoreStat';

export default {
    DistributorStat,
    BuyerVisitLog,
    GoodsStat,
    PayStat,
    PrepaidCardStat,
    AgentFeeOrderStat,
    BuyerStat,
    OpenStoreOrderStat,
    OrderStat,
    DriverStat,
    MarketingStat,
    BuyerBehaviorLogStat,
    ShopStoreStat,
};
