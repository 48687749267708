/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopStoreServiceTime extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopStoreServiceTime';
    }

    getDetail({ storeId }) {
        if (storeId) {
            return super.get('/get', {
                storeId,
            });
        }
        return super.get('/getCurr', {});
    }

    save({
        storeId,
        prepareGoodsDesc,
        prepareGoodsMode,
        distributionType,
        distributionDistance,
        serviceTimeJson,
        otherConfigJson,
    } = {}) {
        if (storeId) {
            return super.post('/save', {
                storeId,
                prepareGoodsDesc,
                prepareGoodsMode,
                distributionType,
                distributionDistance,
                serviceTimeJson,
                otherConfigJson,
            });
        }
        return super.post('/saveCurr', {
            prepareGoodsDesc,
            prepareGoodsMode,
            serviceTimeJson,
            otherConfigJson,
        });
    }
}

export default new ShopStoreServiceTime();
export { ShopStoreServiceTime };
