/**
 * Created by henian.xu on 2021/8/15.
 *
 */

import { DeleteMarker } from './DeleteMarker';

export class OverlayCircle {
    constructor(center, radius, opt) {
        if (!opt || !opt.BMap || !opt.map) {
            console.error('BMap|map 参数未传入');
            return;
        }
        this.type = 'circle';
        this._center = center;
        this._radius = radius;
        this.BMap = opt.BMap;
        this.map = opt.map;
        this.deleteMarker = null;
        this.isEditing = false;
        const options = {
            enableEditing: false,
            enableMassClear: true,
            enableClicking: true,
            ...opt,
        };
        delete options.BMap;
        delete options.map;
        this.options = options;
        this.load();
    }

    load() {
        const { options, center, radius, BMap, map } = this;
        this.originInstance = new BMap.Circle(center, radius, {
            ...options,
            enableEditing: false,
        });
        map.addOverlay(this.originInstance);
        this.originInstance.addEventListener('lineupdate', event => {
            this.onLineupdate(event);
        });
        if (options.enableEditing) {
            setTimeout(() => {
                this.enableEditing();
            }, 50);
        }
    }

    onLineupdate(event) {
        if (!this.isEditing) return;
        const { currentTarget } = event;
        // const { originInstance, BMap } = this;
        const center = currentTarget.getCenter();
        const radius = currentTarget.getRadius();
        this._center = center;
        this._radius = radius;
        // const point = originInstance.getBounds().getNorthEast();
        // this.deleteMarker.setPosition(new BMap.Point(point.lng, center.lat));
        this.deleteMarker.setPosition(center);
    }

    get radius() {
        return this._radius;
    }

    set radius(val) {
        this._radius = val;
        this.originInstance.setRadius(val);
    }

    get center() {
        return this._center;
    }

    set center(val) {
        this._center = val;
    }

    enableEditing() {
        this.originInstance.enableEditing();
        this.isEditing = true;
        this.options.enableEditing = true;
        this.createDeleteMarker();
    }

    disableEditing() {
        this.originInstance.disableEditing();
        this.isEditing = false;
        this.options.enableEditing = false;
        this.destroyDeleteMarker();
    }

    getBounds() {
        const { originInstance } = this;
        if (!originInstance) return [];
        return originInstance.getBounds();
    }

    createDeleteMarker() {
        if (this.deleteMarker) return;
        const { center, BMap, map } = this;
        // const point = originInstance.getBounds().getNorthEast();
        this.deleteMarker = new DeleteMarker(center, { BMap, map });
        this.deleteMarker.addEventListener('click', () => {
            this.destroy();
        });
    }

    destroyDeleteMarker() {
        const { deleteMarker } = this;
        if (deleteMarker) {
            deleteMarker.destroy();
            this.deleteMarker = null;
        }
    }

    destroy() {
        const { map, originInstance } = this;
        map.removeOverlay(originInstance);
        this.destroyDeleteMarker();
        this.originInstance = null;
    }
}

export default OverlayCircle;
