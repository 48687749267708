/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class PaymentProfitSharing extends Ex {
    constructor() {
        super();
        this.baseUrl += '/paymentProfitSharing';
    }

    data({ paymentSn, externalPaymentSn, orderSn, profitSharingStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            paymentSn,
            externalPaymentSn,
            orderSn,
            profitSharingStatus,
            currentPage,
            pageSize,
        });
    }

    submit({ id } = {}) {
        return super.post('/submit', {
            id,
        });
    }

    unfreeze({ id } = {}) {
        return super.post('/unfreeze', {
            id,
        });
    }
}

export default new PaymentProfitSharing();
export { PaymentProfitSharing };
