/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import CmsCatalog from './CmsCatalog';
import CmsArticle from './CmsArticle';
import PortalMenu from './PortalMenu';
import PortalInfoConfig from './PortalInfoConfig';
import CmsArticleComment from './CmsArticleComment';

export default {
    CmsCatalog,
    CmsArticle,
    PortalMenu,
    PortalInfoConfig,
    CmsArticleComment,
};
