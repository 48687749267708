/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ms } from './Ms';

class EmailAccount extends Ms {
    constructor() {
        super();
        this.baseUrl += '/emailAccount';
    }

    data({ orgId, emailAddress, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            orgId,
            emailAddress,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then();
    }

    save({ id, orgId, emailAddress, smtpServer, smtpPort, userName, password, isAvailable } = {}) {
        return super.post('/save', {
            id,
            orgId,
            emailAddress,
            smtpServer,
            smtpPort,
            userName,
            password,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    select({ orgId }) {
        return super.get('/select', { orgId });
    }
}

export default new EmailAccount();
export { EmailAccount };
