import { Pm } from './Pm';

class Raffle extends Pm {
    constructor() {
        super();
        this.baseUrl += '/raffle';
    }

    data({ raffleName, raffleTime, raffleStatus, currentPage, pageSize } = {}) {
        let raffleBeginTime;
        let raffleEndTime;
        if (raffleTime && raffleTime.length === 2) {
            [raffleBeginTime, raffleEndTime] = raffleTime;
        }
        return super.get('/data', {
            raffleName,
            raffleBeginTime,
            raffleEndTime,
            raffleStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super
            .get('/get', {
                id,
            })
            .then(json => {
                const { data } = json.data;
                const {
                    raffleCover,
                    raffleAdImage,
                    raffleBeginTime,
                    raffleEndTime,
                    fileBasePath,
                    raffleRuleList,
                    rafflePrizeList,
                } = data;

                if (raffleCover) {
                    data.raffleCover = raffleCover.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                } else {
                    data.raffleCover = [];
                }

                if (raffleAdImage) {
                    data.raffleAdImage = raffleAdImage.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                } else {
                    data.raffleAdImage = [];
                }

                if (raffleBeginTime && raffleEndTime) {
                    data.raffleTime = [raffleBeginTime, raffleEndTime];
                } else {
                    data.raffleTime = [];
                }

                if (raffleRuleList && raffleRuleList.length) {
                    raffleRuleList.forEach(item => {
                        const { raffleRuleConfig } = item;
                        if (raffleRuleConfig) {
                            const ruleConfig = JSON.parse(raffleRuleConfig);
                            if (+item.raffleRuleType === 1) {
                                item.personNum = ruleConfig.personNum ? +ruleConfig.personNum : undefined;
                                item.raffleNum = ruleConfig.raffleNum ? +ruleConfig.raffleNum : undefined;
                            }
                        }
                    });
                }
                if (rafflePrizeList && rafflePrizeList.length) {
                    rafflePrizeList.forEach(item => {
                        const { rafflePrizeImage } = item;
                        if (rafflePrizeImage) {
                            item.rafflePrizeImage = rafflePrizeImage.split(',').reduce((prev, curr) => {
                                prev.push({
                                    name: curr,
                                    address: fileBasePath + curr,
                                });
                                return prev;
                            }, []);
                        } else {
                            item.rafflePrizeImage = [];
                        }

                        const { rafflePrizeConfig } = item;
                        if (rafflePrizeConfig) {
                            const prizeConfig = JSON.parse(rafflePrizeConfig);
                            if (+item.rafflePrizeType === 1) {
                                item.money = prizeConfig.money ? +prizeConfig.money : undefined;
                            }
                        }
                    });
                }

                return json;
            });
    }

    save({
        id,
        raffleName,
        raffleCover,
        raffleAdImage,
        raffleDesc,
        raffleTime,
        remarks,
        raffleRuleList,
        rafflePrizeList,
        isSubmit,
    } = {}) {
        let raffleCoverUrl;
        let raffleAdImageUrl;
        let raffleBeginTime;
        let raffleEndTime;
        if (raffleCover && raffleCover.length) {
            raffleCoverUrl = raffleCover
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        if (raffleAdImage && raffleAdImage.length) {
            raffleAdImageUrl = raffleAdImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        if (raffleTime && raffleTime.length === 2) {
            [raffleBeginTime, raffleEndTime] = raffleTime;
        }

        if (rafflePrizeList && rafflePrizeList.length) {
            rafflePrizeList = rafflePrizeList.map(item => {
                const res = { ...item };
                const { rafflePrizeImage } = res;
                if (rafflePrizeImage && rafflePrizeImage.length) {
                    res.rafflePrizeImage = rafflePrizeImage
                        .reduce((prev, curr) => {
                            prev.push(curr.name);
                            return prev;
                        }, [])
                        .join(',');
                } else {
                    res.rafflePrizeImage = '';
                }
                return res;
            });
        }

        return super.post('/save', {
            id,
            raffleName,
            raffleCover: raffleCoverUrl,
            raffleAdImage: raffleAdImageUrl,
            raffleDesc,
            raffleBeginTime,
            raffleEndTime,
            remarks,
            raffleRuleList: JSON.stringify(raffleRuleList),
            rafflePrizeList: JSON.stringify(rafflePrizeList),
            isSubmit,
        });
    }

    delete({ id } = {}) {
        return super.post('/delete', {
            id,
        });
    }

    selectByPage({ raffleName, raffleTime, currentPage, pageSize } = {}) {
        let raffleBeginTime;
        let raffleEndTime;
        if (raffleTime && raffleTime.length === 2) {
            [raffleBeginTime, raffleEndTime] = raffleTime;
        }
        return super.get('/selectByPage', {
            raffleName,
            raffleBeginTime,
            raffleEndTime,
            currentPage,
            pageSize,
        });
    }

    finishRaffle({ id } = {}) {
        return super.post('/finishRaffle', {
            id,
        });
    }
}
export default new Raffle();
export { Raffle };
