/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopDriver extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopDriver';
    }

    data({
        driverName,
        driverMobile,
        driverStatus,
        approvalStatus,
        driverType,
        driverTypeEq,
        driverLevelId,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            driverName,
            driverMobile,
            driverStatus,
            approvalStatus,
            driverType,
            driverTypeEq,
            driverLevelId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({
        id,
        driverName,
        driverMobile,
        password,
        regionId,
        detailAddress,
        driverStatus,
        remarks,
        driverLevelId,
    } = {}) {
        return super.post('/save', {
            id,
            driverName,
            driverMobile,
            password,
            regionId,
            detailAddress,
            driverStatus,
            remarks,
            driverLevelId,
        });
    }

    saveSettleIn({
        id,
        driverName,
        driverMobile,
        password,
        regionId,
        detailAddress,
        driverStatus,
        remarks,
        driverLevelId,
    } = {}) {
        return super.post('/saveSettleIn', {
            id,
            driverName,
            driverMobile,
            password,
            regionId,
            detailAddress,
            driverStatus,
            remarks,
            driverLevelId,
        });
    }

    audit({ id, approvalStatus, approvalRemarks } = {}) {
        return super.post('/audit', {
            id,
            approvalStatus,
            approvalRemarks,
        });
    }
}

export default new ShopDriver();
export { ShopDriver };
