/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class GoodsLabel extends Gd {
    constructor() {
        super();
        this.baseUrl += '/goodsLabel';
    }

    data({ goodsLabelTypeId, labelName, labelCode, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            goodsLabelTypeId,
            labelName,
            labelCode,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { labelImage } = json.data.data;
            const { fileBasePath } = json.data.data;
            if (!labelImage) {
                json.data.data.labelImage = [];
            } else {
                json.data.data.labelImage = labelImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        goodsLabelTypeId,
        labelName,
        labelCode,
        labelContent,
        labelImage,
        labelConfig,
        isAvailable,
        orderNum,
    } = {}) {
        const labelImageStr = labelImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            goodsLabelTypeId,
            labelName,
            labelCode,
            labelContent,
            labelImage: labelImageStr,
            labelConfig: JSON.stringify(labelConfig),
            isAvailable,
            orderNum,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    saveGoodsGoodsLabel({ goodsIds, goodsLabelId }) {
        return super.post('/saveGoodsGoodsLabel', { goodsIds, goodsLabelId });
    }

    delGoodsGoodsLabel({ goodsIds, goodsLabelId }) {
        return super.post('/delGoodsGoodsLabel', { goodsIds, goodsLabelId });
    }
}

export default new GoodsLabel();
export { GoodsLabel };
