/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ms } from './Ms';

class EmailTemplate extends Ms {
    constructor() {
        super();
        this.baseUrl += '/emailTemplate';
    }

    data({ orgId, emailUseCaseName, emailTemplateName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            orgId,
            emailUseCaseName,
            emailTemplateName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then();
    }

    save({
        id,
        orgId,
        emailAccountId,
        emailUseCase,
        emailTemplateName,
        emailTemplateDesc,
        emailTitle,
        emailContent,
        emailAttachment,
        isAvailable,
    } = {}) {
        return super.post('/save', {
            id,
            orgId,
            emailAccountId,
            emailUseCase,
            emailTemplateName,
            emailTemplateDesc,
            emailTitle,
            emailContent,
            emailAttachment,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}

export default new EmailTemplate();
export { EmailTemplate };
