/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { St } from './St';

class GoodsSaleStat extends St {
    constructor() {
        super();
        this.baseUrl += '/goodsSaleStat';
    }

    data({
        goodsName,
        goodsSn,
        goodsCatId,
        type,
        statDateTime,
        statDateDay,
        statDateWeek,
        statDateMonth,
        orderByKey,
        orderByType,
        currentPage,
        pageSize,
    } = {}) {
        let statDateTimeBegin = null;
        let statDateTimeEnd = null;
        if (statDateTime != null && statDateTime.length === 2) {
            [statDateTimeBegin, statDateTimeEnd] = statDateTime;
        }
        let statDateDayBegin = null;
        let statDateDayEnd = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statDateDayBegin, statDateDayEnd] = statDateDay;
        }
        let statDateMonthBegin = null;
        let statDateMonthEnd = null;
        if (statDateMonth != null && statDateMonth.length === 2) {
            [statDateMonthBegin, statDateMonthEnd] = statDateMonth;
        }
        return super.get(
            '/data',
            {
                goodsName,
                goodsSn,
                goodsCatId,
                type,
                statDateTimeBegin,
                statDateTimeEnd,
                statDateDayBegin,
                statDateDayEnd,
                statDateMonthBegin,
                statDateMonthEnd,
                statDateWeek,
                orderByKey,
                orderByType,
                currentPage,
                pageSize,
            },
            {
                isRepeat: true,
            },
        );
    }

    findGoodsVisitLogList({
        type,
        statDateTime,
        statDateDay,
        statDateWeek,
        statDateMonth,
        orderByKey,
        orderByType,
        currentPage,
        pageSize,
    } = {}) {
        let statDateTimeBegin = null;
        let statDateTimeEnd = null;
        if (statDateTime != null && statDateTime.length === 2) {
            [statDateTimeBegin, statDateTimeEnd] = statDateTime;
        }
        let statDateDayBegin = null;
        let statDateDayEnd = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statDateDayBegin, statDateDayEnd] = statDateDay;
        }
        let statDateMonthBegin = null;
        let statDateMonthEnd = null;
        if (statDateMonth != null && statDateMonth.length === 2) {
            [statDateMonthBegin, statDateMonthEnd] = statDateMonth;
        }
        return super.get(
            '/findGoodsVisitLogList',
            {
                type,
                statDateTimeBegin,
                statDateTimeEnd,
                statDateDayBegin,
                statDateDayEnd,
                statDateMonthBegin,
                statDateMonthEnd,
                statDateWeek,
                orderByKey,
                orderByType,
                currentPage,
                pageSize,
            },
            {
                isRepeat: true,
            },
        );
    }
}

export default new GoodsSaleStat();
export { GoodsSaleStat };
