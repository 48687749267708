/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopStore extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopStore';
    }

    data({
        id,
        storeName,
        storeSn,
        isAvailable,
        isDefault,
        distributorBuyerIdNull,
        distributorName,
        distributorTel,
        storeStatus,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            id,
            storeName,
            storeSn,
            isAvailable,
            isDefault,
            distributorBuyerIdNull,
            distributorName,
            distributorTel,
            storeStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id, type }) {
        const url = type === 1 ? '/get' : '/getCurr';
        return super.get(url, { id }).then(json => {
            const { shopStore } = json.data.data;
            if (!shopStore.storeLogo) {
                shopStore.storeLogo = [];
            } else {
                shopStore.storeLogo = shopStore.storeLogo.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: shopStore.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (!shopStore.storeAdImage) {
                shopStore.storeAdImage = [];
            } else {
                shopStore.storeAdImage = shopStore.storeAdImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: shopStore.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (shopStore.addressLatitude && shopStore.addressLongitude) {
                shopStore.latLng = `${shopStore.addressLongitude},${shopStore.addressLatitude}`;
            }
            shopStore.isPushInventory = shopStore.otherConfig.isPushInventory;
            shopStore.inventoryAppKey = shopStore.otherConfig.inventoryAppKey;
            shopStore.inventoryAppSecret = shopStore.otherConfig.inventoryAppSecret;
            shopStore.pospalAppId = shopStore.otherConfig.pospalAppId;
            shopStore.pospalAppKey = shopStore.otherConfig.pospalAppKey;
            shopStore.speakerImei = shopStore.otherConfig.speakerImei;
            shopStore.pickupType = shopStore.otherConfig.pickupType;
            return json;
        });
    }

    save({
        id,
        storeName,
        storeSn,
        storeLogo,
        storeAdImage,
        openTime,
        storeNotice,
        storeType,
        pickupType,
        storeDesc,
        storeRegionId,
        storeDetailAddress,
        latLng,
        contactFullname,
        contactTel,
        storeContactTel,
        speakerImei,
        printerSn,
        isAutoPrint,
        isAvailable,
        isDefault,
        isStoreIncome,
        userIds,
        shopStoreLevelId,
        isPushInventory,
        inventoryAppKey,
        inventoryAppSecret,
        publicToken,
        pospalAppId,
        pospalAppKey,
        storeStatus,
        openTimeType,
        openTimeJson,
    } = {}) {
        const storeLogoStr = storeLogo
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');

        const storeAdImageStr = storeAdImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');

        let addressLongitude = null;
        let addressLatitude = null;
        if (latLng) {
            [addressLongitude, addressLatitude] = latLng.split(',');
        }
        const otherConfig = {
            isPushInventory,
            inventoryAppKey,
            inventoryAppSecret,
            pospalAppId,
            pospalAppKey,
            speakerImei,
            pickupType,
        };
        return super.post('/save', {
            id,
            storeName,
            storeSn,
            storeLogo: storeLogoStr,
            storeAdImage: storeAdImageStr,
            openTime,
            storeNotice,
            storeType,
            storeDesc,
            storeRegionId,
            storeDetailAddress,
            addressLongitude,
            addressLatitude,
            contactFullname,
            contactTel,
            storeContactTel,
            printerSn,
            isAutoPrint,
            isAvailable,
            isDefault,
            isStoreIncome,
            userIds,
            shopStoreLevelId,
            publicToken,
            otherConfig: JSON.stringify(otherConfig),
            storeStatus,
            openTimeType,
            openTimeJson,
        });
    }

    initShopStoreGoods({ id }) {
        return super.post('/initShopStoreGoods', { id });
    }

    getShareInfo({ id }) {
        return super.get('/getShareInfo', { id });
    }

    selectByPage({
        id,
        storeName,
        storeSn,
        isAvailable,
        isDefault,
        distributorBuyerIdNull,
        distributorName,
        distributorTel,
        storeStatus,
        businessDistrictId,
        relBusinessDistrictId,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/selectByPage', {
            id,
            storeName,
            storeSn,
            isAvailable,
            isDefault,
            distributorBuyerIdNull,
            distributorName,
            distributorTel,
            storeStatus,
            businessDistrictId,
            relBusinessDistrictId,
            currentPage,
            pageSize,
        });
    }
}

export default new ShopStore();
export { ShopStore };
