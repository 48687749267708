/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import User from './User';
import Menu from './Menu';
import Role from './Role';
import Region from './Region';
import Variable from './Variable';
import Dic from './Dic';
import DicEntry from './DicEntry';
import App from './App';
import Org from './Org';
import Express from './Express';
import OrgCountryCode from './OrgCountryCode';
import CountryCode from './CountryCode';
import Console from './Console';
import Pospal from './Pospal';
import PlatformConfig from './PlatformConfig';
import Inventory from './Inventory';
import LanguageDataTrans from './LanguageDataTrans';
import LanguageDataConf from './LanguageDataConf';
import LanguageModule from './LanguageModule';
import LanguageEntry from './LanguageEntry';

export default {
    User,
    Menu,
    Role,
    Region,
    Variable,
    Dic,
    DicEntry,
    App,
    Org,
    Express,
    OrgCountryCode,
    CountryCode,
    Console,
    Pospal,
    PlatformConfig,
    Inventory,
    LanguageDataTrans,
    LanguageDataConf,
    LanguageModule,
    LanguageEntry,
};
