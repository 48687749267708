/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class BusinessDistrictServiceTime extends Sp {
    constructor() {
        super();
        this.baseUrl += '/businessDistrictServiceTime';
    }

    getDetail({ businessDistrictId }) {
        return super.get('/get', {
            businessDistrictId,
        });
    }

    save({
        businessDistrictId,
        prepareGoodsDesc,
        prepareGoodsMode,
        distributionType,
        distributionDistance,
        serviceTimeJson,
        otherConfigJson,
    } = {}) {
        return super.post('/save', {
            businessDistrictId,
            prepareGoodsDesc,
            prepareGoodsMode,
            distributionType,
            distributionDistance,
            serviceTimeJson,
            otherConfigJson,
        });
    }

    s;
}

export default new BusinessDistrictServiceTime();
export { BusinessDistrictServiceTime };
