/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { St } from './St';

class ShopSearchKeywords extends St {
    constructor() {
        super();
        this.baseUrl += '/shopSearchKeywords';
    }

    data({ title, keywords, isAvailable, isSystem, currentPage, pageSize } = {}) {
        return super.get('/data', {
            title,
            keywords,
            isAvailable,
            isSystem,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, title, keywords, searchNum, isAvailable } = {}) {
        return super.post('/save', {
            id,
            title,
            keywords,
            searchNum,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    delCache() {
        return super.post('/delCache', {});
    }
}

export default new ShopSearchKeywords();
export { ShopSearchKeywords };
