/**
 * Created by henian.xu on 2021/8/15.
 *
 */

// eslint-disable-next-line max-classes-per-file
export class BMapEvent {
    constructor(type, target) {
        this.type = type;
        this.returnValue = true;
        this.target = target || null;
        this.currentTarget = null;
    }
}

export class BaiduEvent {
    constructor() {
        this._listeners = [];
        this._eventFilter = {};
    }

    on(element, type, listener) {
        type = type.replace(/^on/i, '');
        // element = baidu._g(element);
        let realListener = ev => {
            // 1. 这里不支持EventArgument,  原因是跨frame的事件挂载
            // 2. element是为了修正this
            listener.call(element, ev);
        };
        const lis = this._listeners;
        const filter = this._eventFilter;
        let afterFilter;
        let realType = type;
        type = type.toLowerCase();
        // filter过滤
        if (filter && filter[type]) {
            afterFilter = filter[type](element, type, realListener);
            realType = afterFilter.type;
            realListener = afterFilter.listener;
        }

        // 事件监听器挂载
        if (element.addEventListener) {
            element.addEventListener(realType, realListener, false);
        } else if (element.attachEvent) {
            element.attachEvent(`on${realType}`, realListener);
        }

        // 将监听器存储到数组中
        lis[lis.length] = [element, type, listener, realListener, realType];
        return element;
    }

    un(element, type, listener) {
        // element = baidu._g(element);
        type = type.replace(/^on/i, '').toLowerCase();

        const lis = this._listeners;
        let len = lis.length - 1;
        const isRemoveAll = !listener;
        let item;
        let realType;
        let realListener;

        // 如果将listener的结构改成json
        // 可以节省掉这个循环，优化性能
        // 但是由于un的使用频率并不高，同时在listener不多的时候
        // 遍历数组的性能消耗不会对代码产生影响
        // 暂不考虑此优化
        while (len >= 0) {
            item = lis[len];

            // listener存在时，移除element的所有以listener监听的type类型事件
            // listener不存在时，移除element的所有type类型事件
            if (item[1] === type && item[0] === element && (isRemoveAll || item[2] === listener)) {
                // eslint-disable-next-line prefer-destructuring
                realType = item[4];
                // eslint-disable-next-line prefer-destructuring
                realListener = item[3];
                if (element.removeEventListener) {
                    element.removeEventListener(realType, realListener, false);
                } else if (element.detachEvent) {
                    element.detachEvent(`on${realType}`, realListener);
                }
                lis.splice(len, 1);
                len -= 1;
            }
        }
        return element;
    }

    // eslint-disable-next-line class-methods-use-this
    getEvent(event) {
        return window.event || event;
    }

    getTarget(event) {
        event = this.getEvent(event);
        return event.target || event.srcElement;
    }

    preventDefault(event) {
        event = this.getEvent(event);
        if (event.preventDefault) {
            event.preventDefault();
        } else {
            event.returnValue = false;
        }
    }

    stopBubble(event) {
        event = this.getEvent(event);
        if (event.stopPropagation) {
            event.stopPropagation();
        } else {
            event.cancelBubble = true;
        }
    }
}

export const baiduEvent = new BaiduEvent();
