/**
 * Created by henian.xu on 2021/8/15.
 *
 */

import { DeleteMarker } from './DeleteMarker';

export function getNorthEast(...args) {
    const bound = args[0];
    let maxlng = 0;
    let index = 0;
    for (let j = 0; j < bound.length; j += 1) {
        if (maxlng < bound[j].lng) {
            maxlng = bound[j].lng;
            index = j;
        }
    }
    return bound[index];
}

export class OverlayPolygon {
    constructor(pointPath, opt) {
        if (!opt || !opt.BMap || !opt.map) {
            console.error('BMap|map 参数未传入');
            return;
        }
        this.type = 'polygon';
        this._pointPath = pointPath;
        this.BMap = opt.BMap;
        this.map = opt.map;
        this.deleteMarker = null;
        this.isEditing = false;
        const options = {
            enableEditing: false,
            enableMassClear: true,
            enableClicking: true,
            ...opt,
        };
        delete options.BMap;
        delete options.map;
        this.options = options;
        this.load();
    }

    load() {
        const { options, BMap, map, pointPath } = this;
        this.originInstance = new BMap.Polygon(pointPath, {
            ...options,
            enableEditing: false,
        });
        map.addOverlay(this.originInstance);
        this.originInstance.addEventListener('lineupdate', event => {
            this.onLineupdate(event);
        });
        if (options.enableEditing) {
            setTimeout(() => {
                this.enableEditing();
            }, 50);
        }
    }

    onLineupdate(event) {
        if (!this.isEditing) return;
        this._pointPath = event.currentTarget.getPath();
        const point = getNorthEast(this._pointPath);
        this.deleteMarker.setPosition(point);
    }

    unload() {
        const { map, originInstance } = this;
        map.removeOverlay(originInstance);
    }

    reload() {
        this.unload();
        this.load();
    }

    get pointPath() {
        return this._pointPath;
    }

    set pointPath(val) {
        this._pointPath = val;
        this.reload();
    }

    setPositionAt(index, point) {
        const { originInstance } = this;
        originInstance.setPositionAt(index, point);
    }

    lastPosition(point) {
        const { originInstance, _pointPath } = this;
        originInstance.setPositionAt(_pointPath.length - 1, point);
        _pointPath.splice(_pointPath.length - 1, 1, point);
    }

    addPosition(point) {
        const { originInstance, _pointPath } = this;
        _pointPath.push(point);
        originInstance.setPath(_pointPath);
    }

    setPath(pointPath) {
        this._pointPath = pointPath;
        this.originInstance.setPath(this._pointPath);
    }

    enableEditing() {
        console.log('enableEditing');
        this.originInstance.enableEditing();
        this.isEditing = true;
        this.options.enableEditing = true;
        this.createDeleteMarker();
    }

    disableEditing() {
        console.log('disableEditing');
        this.originInstance.disableEditing();
        this.isEditing = false;
        this.options.enableEditing = false;
        this.destroyDeleteMarker();
    }

    createDeleteMarker() {
        if (this.deleteMarker) return;
        const { pointPath, BMap, map } = this;
        const point = getNorthEast(pointPath);
        this.deleteMarker = new DeleteMarker(point, { BMap, map });
        this.deleteMarker.addEventListener('click', () => {
            this.destroy();
        });
    }

    destroyDeleteMarker() {
        const { deleteMarker } = this;
        if (deleteMarker) {
            deleteMarker.destroy();
            this.deleteMarker = null;
        }
    }

    destroy() {
        const { map, originInstance } = this;
        map.removeOverlay(originInstance);
        this.destroyDeleteMarker();
        this.originInstance = null;
    }
}

export default OverlayPolygon;
