/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Spider } from './Spider';

class PupuGoods extends Spider {
    constructor() {
        super();
        this.baseUrl += '/pupuGoods';
    }

    data({ goodsName, goodsComment, currentPage, pageSize } = {}) {
        return super.get('/data', {
            goodsName,
            goodsComment,
            currentPage,
            pageSize,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new PupuGoods();
export { PupuGoods };
