/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Stat } from './Stat';

class BuyerBehaviorLogStat extends Stat {
    constructor() {
        super();
        this.baseUrl += '/buyerBehaviorLogStat';
    }

    findBuyerBehaviorLogStatHistoryList({ behaviorType, statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/findBuyerBehaviorLogStatHistoryList', {
            behaviorType,
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }

    findBuyerBehaviorLogStatHistoryDetailList({ behaviorType, statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/findBuyerBehaviorLogStatHistoryDetailList', {
            behaviorType,
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }

    findBuyerBehaviorLogStatList({ behaviorType, statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/findBuyerBehaviorLogStatList', {
            behaviorType,
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }

    findBuyerBehaviorLogStatDetailList({ behaviorType, statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/findBuyerBehaviorLogStatDetailList', {
            behaviorType,
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }
}

export default new BuyerBehaviorLogStat();
export { BuyerBehaviorLogStat };
