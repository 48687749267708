/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class Payment extends Ex {
    constructor() {
        super();
        this.baseUrl += '/payment';
    }

    data({
        orderSn,
        paymentSn,
        externalPaymentSn,
        payer,
        payerAccount,
        paymentStatus,
        paymentTime,
        currentPage,
        pageSize,
    } = {}) {
        let beginTime = null;
        let endTime = null;
        if (paymentTime != null && paymentTime.length === 2) {
            [beginTime, endTime] = paymentTime;
        }
        return super.get('/data', {
            orderSn,
            paymentSn,
            externalPaymentSn,
            payer,
            payerAccount,
            paymentStatus,
            beginTime,
            endTime,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id, source } = {}) {
        return super.get('/getDetail', { id, source });
    }

    offlinePaymentAudit({ id, source, auditStatus, payAuditComments } = {}) {
        return super.post('/offlinePaymentAudit', {
            id,
            source,
            auditStatus,
            payAuditComments,
        });
    }
}

export default new Payment();
export { Payment };
