/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Pm } from './Pm';

class CouponGive extends Pm {
    constructor() {
        super();
        this.baseUrl += '/couponGive';
    }

    data({
        couponType,
        drawLimitType,
        couponCode,
        couponDesc,
        giveScope,
        remarks,
        couponGiveMode,
        insertTime,
        currentPage,
        pageSize,
    } = {}) {
        let insertBeginTime = null;
        let insertEndTime = null;
        if (insertTime != null && insertTime.length === 2) {
            [insertBeginTime, insertEndTime] = insertTime;
        }
        return super.get('/data', {
            couponType,
            drawLimitType,
            couponCode,
            couponDesc,
            giveScope,
            remarks,
            couponGiveMode,
            insertBeginTime,
            insertEndTime,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { couponImage } = json.data.data.couponGive;
            const { fileBasePath } = json.data.data;
            if (!couponImage) {
                json.data.data.couponGive.couponImage = [];
            } else {
                json.data.data.couponGive.couponImage = couponImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        couponCode,
        couponDesc,
        couponShortDesc,
        couponTempletId,
        couponAmount,
        couponUseAmount,
        couponExpireMode,
        couponPeriod,
        couponExpireTime,
        giveScope,
        buyerLevelId,
        remarks,
        buyerIds,
        couponGiveMode,
        couponTempletType,
        drawBeginTime,
        drawEndTime,
        couponAmountType,
        discountMaxAmount,
        couponUseScopeType,
        couponUseScope,
        couponGiveQuantity,
        drawQuantityLimit,
        couponActivityId,
        couponType,
        couponImage,
        couponUseRemarks,
        drawLimitType,
        couponShipMethod,
    } = {}) {
        const couponImageStr = couponImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            couponCode,
            couponDesc,
            couponShortDesc,
            couponTempletId,
            couponAmount,
            couponUseAmount,
            couponExpireMode,
            couponPeriod,
            couponExpireTime,
            giveScope,
            buyerLevelId,
            remarks,
            buyerIds,
            couponGiveMode,
            couponTempletType,
            drawBeginTime,
            drawEndTime,
            couponAmountType,
            discountMaxAmount,
            couponUseScopeType,
            couponUseScope,
            couponGiveQuantity,
            drawQuantityLimit,
            couponActivityId,
            couponType,
            couponImage: couponImageStr,
            couponUseRemarks,
            drawLimitType,
            couponShipMethod,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}

export default new CouponGive();
export { CouponGive };
