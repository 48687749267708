/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class Inventory extends Rs {
    constructor() {
        super();
        this.baseUrl += '/inventory';
    }

    syncInventoryStock() {
        return super.post(
            '/syncInventoryStock',
            {},
            {
                timeout: 0,
                isLoading: true,
            },
        );
    }

    syncInventoryGoodsCat() {
        return super.post(
            '/syncInventoryGoodsCat',
            {},
            {
                timeout: 0,
                isLoading: true,
            },
        );
    }

    syncInventoryGoods() {
        return super.post(
            '/syncInventoryGoods',
            {},
            {
                timeout: 0,
                isLoading: true,
            },
        );
    }
}

export default new Inventory();
export { Inventory };
