/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Stat } from './Stat';

class OrderStat extends Stat {
    constructor() {
        super();
        this.baseUrl += '/orderStat';
    }

    orderStatList({ orderSn, statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/orderStatList', {
            orderSn,
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }

    groupOrderStatList({ distributorName, referenceId, statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/groupOrderStatList', {
            distributorName,
            referenceId,
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }

    orderGoodsStatList({ orderSn, goodsName, orderTime, paymentTime, currentPage, pageSize } = {}) {
        let orderTimeBegin = null;
        let orderTimeEnd = null;
        if (orderTime != null && orderTime.length === 2) {
            [orderTimeBegin, orderTimeEnd] = orderTime;
        }
        let paymentTimeBegin = null;
        let paymentTimeEnd = null;
        if (paymentTime != null && paymentTime.length === 2) {
            [paymentTimeBegin, paymentTimeEnd] = paymentTime;
        }
        return super.get('/orderGoodsStatList', {
            orderSn,
            goodsName,
            orderTimeBegin,
            orderTimeEnd,
            paymentTimeBegin,
            paymentTimeEnd,
            currentPage,
            pageSize,
        });
    }
}

export default new OrderStat();
export { OrderStat };
