/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class GoodsAgent extends Gd {
    constructor() {
        super();
        this.baseUrl += '/goodsAgent';
    }

    goodsAgentList({
        goodsName,
        goodsSn,
        goodsCatId,
        pointGoodsCatId,
        goodsStatus,
        isAvailable,
        taxRate,
        shopTagIdList,
        currentPage,
        pageSize,
    } = {}) {
        let shopTagIds = null;
        if (shopTagIdList && shopTagIdList.length !== 0) {
            shopTagIds = shopTagIdList
                ? shopTagIdList
                      .reduce((prev, curr) => {
                          prev.push(curr);
                          return prev;
                      }, [])
                      .join(',')
                : '';
        }
        return super.get('/goodsAgentList', {
            goodsName,
            goodsSn,
            goodsCatId,
            pointGoodsCatId,
            goodsStatus,
            isAvailable,
            taxRate,
            shopTagIds,
            currentPage,
            pageSize,
        });
    }

    getGoodsAgent({ goodsId }) {
        return super.get('/getGoodsAgent', { goodsId }).then(json => {
            const { goodsImage, goodsDetailImage } = json.data.data.goodsAgent;
            const { fileBasePath } = json.data.data;
            if (!goodsImage) {
                json.data.data.goodsAgent.goodsImage = [];
            } else {
                json.data.data.goodsAgent.goodsImage = goodsImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (!goodsDetailImage) {
                json.data.data.goodsAgent.goodsDetailImage = [];
            } else {
                json.data.data.goodsAgent.goodsDetailImage = goodsDetailImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            json.data.data.goodsAgent.shopGoodsCatIds = json.data.data.goodsAgent.shopGoodsCatIds
                ? json.data.data.goodsAgent.shopGoodsCatIds.split(',').map(item => +item)
                : [];
            json.data.data.goodsAgent.pointGoodsCatIds = json.data.data.goodsAgent.pointGoodsCatIds
                ? json.data.data.goodsAgent.pointGoodsCatIds.split(',').map(item => +item)
                : [];
            json.data.data.goodsAgent.storeInitGoodsCatId = json.data.data.goodsAgent.storeInitGoodsCatIds
                ? json.data.data.goodsAgent.storeInitGoodsCatIds.split(',').map(item => +item)
                : [];
            json.data.data.goodsAgent.shopTagId = json.data.data.goodsAgent.shopTagIds
                ? json.data.data.goodsAgent.shopTagIds.split(',').map(item => +item)
                : [];
            return json;
        });
    }

    saveGoodsAgent({
        goodsId,
        goodsName,
        goodsImage,
        goodsDetailImage,
        orderNum,
        taxRate,
        shopIncomeRate,
        shareIncomeRate,
        isAvailable,
        storeInitGoodsCatId,
        shopTagId,
        shopGoodsCatIds,
        pointGoodsCatIds,
        skuListJson,
        paymentMethod,
        pointRate,
    }) {
        const goodsImageStr =
            goodsImage && goodsImage.length !== 0
                ? goodsImage
                      .reduce((prev, curr) => {
                          prev.push(curr.name);
                          return prev;
                      }, [])
                      .join(',')
                : '';
        const goodsDetailImageStr =
            goodsDetailImage && goodsDetailImage.length !== 0
                ? goodsDetailImage
                      .reduce((prev, curr) => {
                          prev.push(curr.name);
                          return prev;
                      }, [])
                      .join(',')
                : '';
        const storeInitGoodsCatIds = storeInitGoodsCatId ? storeInitGoodsCatId.join(',') : '';
        const shopTagIds = shopTagId ? shopTagId.join(',') : '';
        const shopGoodsCatIdsStr = shopGoodsCatIds
            ? shopGoodsCatIds
                  .reduce((prev, curr) => {
                      prev.push(curr);
                      return prev;
                  }, [])
                  .join(',')
            : '';
        const pointGoodsCatIdsStr = pointGoodsCatIds
            ? pointGoodsCatIds
                  .reduce((prev, curr) => {
                      prev.push(curr);
                      return prev;
                  }, [])
                  .join(',')
            : '';
        return super.post('/saveGoodsAgent', {
            goodsId,
            goodsName,
            goodsImage: goodsImageStr,
            goodsDetailImage: goodsDetailImageStr,
            orderNum,
            taxRate,
            shopIncomeRate,
            shareIncomeRate,
            isAvailable,
            storeInitGoodsCatIds,
            shopTagIds,
            shopGoodsCatIds: shopGoodsCatIdsStr,
            pointGoodsCatIds: pointGoodsCatIdsStr,
            skuListJson,
            paymentMethod,
            pointRate,
        });
    }

    editGoodsAgent({ id, isAvailable, orderNum }) {
        return super.post('/editGoodsAgent', { id, isAvailable, orderNum });
    }

    getShopGoodsAgentList({ goodsId }) {
        return super.get('/getShopGoodsAgentList', { goodsId }).then(json => {
            const list = json.data.data;
            list.forEach(dataItem => {
                dataItem.shopGoodsCatId = dataItem.shopGoodsCatIds
                    ? dataItem.shopGoodsCatIds.split(',').map(item => +item)
                    : [];
                dataItem.pointGoodsCatId = dataItem.pointGoodsCatIds
                    ? dataItem.pointGoodsCatIds.split(',').map(item => +item)
                    : [];
            });
            return json;
        });
    }

    saveShopGoodsAgent({ goodsId, agentJson }) {
        return super.post('/saveShopGoodsAgent', { goodsId, agentJson });
    }

    saveShopGoodsBatchAgent({ goodsId, agentJson }) {
        return super.post('/saveShopGoodsBatchAgent', { goodsId, agentJson });
    }
}

export default new GoodsAgent();
export { GoodsAgent };
