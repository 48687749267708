/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class PromotionLevel extends Mb {
    constructor() {
        super();
        this.baseUrl += '/promotionLevel';
    }

    data({ levelName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            levelName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { levelIcon } = json.data.data;
            const { fileBasePath } = json.data.data;
            if (!levelIcon) {
                json.data.data.levelIcon = [];
            } else {
                json.data.data.levelIcon = levelIcon.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        levelName,
        levelCode,
        levelDesc,
        levelIcon,
        orderNum,
        isAvailable,
        isDefault,
        upgradeAmount,
        firstReferenceAmount,
        secondReferenceAmount,
        referenceAmount,
        referenceSameLevelAmount,
        upgradeBuyerOrderNum,
        upgradeBuyerOrderAmount,
        upgradeOrderNum,
        upgradeGroupOrderAmount,
        upgradeSubordinateNum,
    } = {}) {
        const levelIconStr = levelIcon
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const incomeConfig = {
            upgradeAmount,
            firstReferenceAmount,
            secondReferenceAmount,
            referenceAmount,
            referenceSameLevelAmount,
        };
        const upgradeConfig = {
            upgradeBuyerOrderNum,
            upgradeBuyerOrderAmount,
            upgradeOrderNum,
            upgradeGroupOrderAmount,
            upgradeSubordinateNum,
        };
        return super.post('/save', {
            id,
            levelName,
            levelCode,
            levelDesc,
            levelIcon: levelIconStr,
            orderNum,
            isAvailable,
            isDefault,
            incomeConfig: JSON.stringify(incomeConfig),
            upgradeConfig: JSON.stringify(upgradeConfig),
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    isDefault({ id, isDefault }) {
        return super.post('/isDefault', {
            id,
            isDefault,
        });
    }

    select() {
        return super.get('/select', {});
    }
}

export default new PromotionLevel();
export { PromotionLevel };
