/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class PaymentRefundCheck extends Ex {
    constructor() {
        super();
        this.baseUrl += '/paymentRefundCheck';
    }

    data({ paymentType, statDateDay, paymentCheckStatus, orderByKey, orderByType, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        let paymentTypeStr = null;
        if (Array.isArray(paymentType)) {
            paymentTypeStr = paymentType.join(',');
        }
        return super.get('/data', {
            paymentType: paymentTypeStr,
            statBeginDate,
            statEndDate,
            paymentCheckStatus,
            currentPage,
            pageSize,
            orderByKey,
            orderByType,
        });
    }
}

export default new PaymentRefundCheck();
export { PaymentRefundCheck };
