/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { GlobalVar } from '@vmf/shared';
import { Pt } from './Pt';

class CmsArticle extends Pt {
    constructor() {
        super();
        this.baseUrl += '/cmsArticle';
    }

    data({ companyName, title, cmsCatalogId, cmsArticleType, isAvailable, isTop, currentPage, pageSize } = {}) {
        return super.get('/data', {
            companyName,
            title,
            cmsCatalogId,
            cmsArticleType,
            isAvailable,
            isTop,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { titleImage } = json.data.data;
            if (!titleImage) {
                json.data.data.titleImage = [];
            } else {
                json.data.data.titleImage = titleImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const { contentImage } = json.data.data;
            if (!contentImage) {
                json.data.data.contentImage = [];
            } else {
                json.data.data.contentImage = contentImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const { voiceFile } = json.data.data;
            if (!voiceFile) {
                json.data.data.voiceFile = [];
            } else {
                json.data.data.voiceFile = voiceFile.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        cmsArticleType,
        showType,
        id,
        title,
        subtitle,
        cmsCatalogId,
        titleImageType,
        titleImage,
        contentImageType,
        contentImage,
        voiceFile,
        digest,
        author,
        source,
        isShowReleaseTime,
        releaseTime,
        content,
        imageList,
        isTop,
        topNum,
    } = {}) {
        const titleImageStr = titleImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const contentImageStr = contentImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const voiceFileStr = voiceFile
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            cmsArticleType,
            showType,
            id,
            title,
            subtitle,
            cmsCatalogId,
            titleImageType,
            titleImage: titleImageStr,
            contentImageType,
            contentImage: contentImageStr,
            voiceFile: voiceFileStr,
            digest,
            author,
            source,
            isShowReleaseTime,
            releaseTime,
            content,
            imageList,
            isTop,
            topNum,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new CmsArticle();
export { CmsArticle };
