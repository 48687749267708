/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import Rs from './rs';
import Sp from './sp';
import Gd from './gd';
import Pm from './pm';
import Mb from './mb';
import Ex from './ex';
import Pt from './pt';
import Ms from './ms';
import Stat from './stat';
import Ts from './ts';
import St from './st';
import Spider from './spider';

export const Api = {
    Rs,
    Sp,
    Gd,
    Pm,
    Mb,
    Ex,
    Pt,
    Ms,
    Stat,
    Ts,
    St,
    Spider,
};
export default Api;
