/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopNotice extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopNotice';
    }

    data({ content, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            content,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, content, releaseTime, isAvailable, orderNum } = {}) {
        return super.post('/save', {
            id,
            content,
            releaseTime,
            isAvailable,
            orderNum,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new ShopNotice();
export { ShopNotice };
