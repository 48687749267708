/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class BusinessDistrict extends Sp {
    constructor() {
        super();
        this.baseUrl += '/businessDistrict';
    }

    data({ id, businessDistrictName, isDefault, businessDistrictStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            id,
            businessDistrictName,
            isDefault,
            businessDistrictStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { businessDistrict } = json.data.data;
            if (!businessDistrict.businessDistrictLogo) {
                businessDistrict.businessDistrictLogo = [];
            } else {
                businessDistrict.businessDistrictLogo = businessDistrict.businessDistrictLogo
                    .split(',')
                    .reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: businessDistrict.fileBasePath + curr,
                        });
                        return prev;
                    }, []);
            }
            if (businessDistrict.addressLatitude && businessDistrict.addressLongitude) {
                businessDistrict.latLng = `${businessDistrict.addressLongitude},${businessDistrict.addressLatitude}`;
            }
            return json;
        });
    }

    save({
        id,
        businessDistrictName,
        businessDistrictSn,
        businessDistrictLogo,
        openTime,
        businessDistrictDesc,
        regionId,
        detailAddress,
        latLng,
        contactFullname,
        contactTel,
        isDefault,
        businessDistrictStatus,
    } = {}) {
        const businessDistrictLogoStr = businessDistrictLogo
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        let addressLongitude = null;
        let addressLatitude = null;
        if (latLng) {
            [addressLongitude, addressLatitude] = latLng.split(',');
        }
        const otherConfig = {};
        return super.post('/save', {
            id,
            businessDistrictName,
            businessDistrictSn,
            businessDistrictLogo: businessDistrictLogoStr,
            openTime,
            businessDistrictDesc,
            regionId,
            detailAddress,
            addressLongitude,
            addressLatitude,
            contactFullname,
            contactTel,
            isDefault,
            otherConfig: JSON.stringify(otherConfig),
            businessDistrictStatus,
        });
    }

    relationStore({ businessDistrictId, storeIds } = {}) {
        return super.post('/relationStore', {
            businessDistrictId,
            storeIds,
        });
    }

    cancelRelationStore({ businessDistrictId, storeIds }) {
        return super.post('/cancelRelationStore', {
            businessDistrictId,
            storeIds,
        });
    }
}

export default new BusinessDistrict();
export { BusinessDistrict };
