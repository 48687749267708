/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class Pospal extends Rs {
    constructor() {
        super();
        this.baseUrl += '/pospal';
    }

    syncBuyerLevel() {
        return super.post(
            '/syncBuyerLevel',
            {},
            {
                timeout: 0,
                isLoading: true,
            },
        );
    }

    syncBuyer() {
        return super.post(
            '/syncBuyer',
            {},
            {
                timeout: 0,
                isLoading: true,
            },
        );
    }

    syncGoods() {
        return super.post(
            '/syncGoods',
            {},
            {
                timeout: 0,
                isLoading: true,
            },
        );
    }
}

export default new Pospal();
export { Pospal };
