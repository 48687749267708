/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Stat } from './Stat';

class AgentFeeOrderStat extends Stat {
    constructor() {
        super();
        this.baseUrl += '/agentFeeOrderStat';
    }

    agentFeeOrderStatList({ statDateDay, paymentType, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/agentFeeOrderStatList', {
            statBeginDate,
            statEndDate,
            paymentType,
            currentPage,
            pageSize,
        });
    }
}

export default new AgentFeeOrderStat();
export { AgentFeeOrderStat };
