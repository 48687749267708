/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopTag extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopTag';
    }

    data({ tagName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            tagName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, tagName, isAvailable, orderNum } = {}) {
        return super.post('/save', {
            id,
            tagName,
            isAvailable,
            orderNum,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    saveGoodsShopTag({ goodsIds, shopTagId }) {
        return super.post('/saveGoodsShopTag', { goodsIds, shopTagId });
    }

    delGoodsShopTag({ goodsIds, shopTagId }) {
        return super.post('/delGoodsShopTag', { goodsIds, shopTagId });
    }
}

export default new ShopTag();
export { ShopTag };
