/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class App extends Rs {
    constructor() {
        super();
        this.baseUrl += '/console';
    }

    todoList() {
        return super.get('/todoList', {});
    }

    orderStat({ goodsSendMode }) {
        return super.get('/orderStat', { goodsSendMode });
    }

    buyerStat() {
        return super.get('/buyerStat', {});
    }

    delete({ ids } = {}) {
        return super.post('/delete', { ids });
    }
}

export default new App();
export { App };
