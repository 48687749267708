/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class GoodsReview extends Ex {
    constructor() {
        super();
        this.baseUrl += '/goodsReview';
    }

    data({ orderSn, goodsName, approvalStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            orderSn,
            goodsName,
            approvalStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', { id }).then(json => {
            const { fileBasePath } = json.data.data;
            const { reviewAttachment } = json.data.data;
            if (!reviewAttachment) {
                json.data.data.reviewAttachment = [];
            } else {
                json.data.data.reviewAttachment = reviewAttachment.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({ id, rating, reviewContent, reviewAttachment, isAvailable, approvalStatus } = {}) {
        const reviewAttachmentStr = reviewAttachment
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            rating,
            reviewContent,
            reviewAttachment: reviewAttachmentStr,
            isAvailable,
            approvalStatus,
        });
    }

    batchApproval({ ids, approvalStatus }) {
        return super.post('/batchApproval', { ids, approvalStatus });
    }
}

export default new GoodsReview();
export { GoodsReview };
