/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class OrderInvoice extends Ex {
    constructor() {
        super();
        this.baseUrl += '/orderInvoice';
    }

    data({ mobile, invoiceTitle, invoiceStatus, invoiceTime, currentPage, pageSize } = {}) {
        let invoiceTimeBegin = null;
        let invoiceTimeEnd = null;
        if (invoiceTime != null && invoiceTime.length === 2) {
            [invoiceTimeBegin, invoiceTimeEnd] = invoiceTime;
        }
        return super.get('/data', {
            mobile,
            invoiceTitle,
            invoiceStatus,
            invoiceTimeBegin,
            invoiceTimeEnd,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', { id });
    }

    invoiceAudit({ id, invoiceStatus, failReason } = {}) {
        return super.post('/invoiceAudit', {
            id,
            invoiceStatus,
            failReason,
        });
    }
}

export default new OrderInvoice();
export { OrderInvoice };
