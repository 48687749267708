/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Stat } from './Stat';

class MarketingStat extends Stat {
    constructor() {
        super();
        this.baseUrl += '/marketingStat';
    }

    storeMarketingStatList({
        type,
        orderByKey,
        orderByType,
        storeName,
        statDateDay,
        statBeginDateMonth,
        statEndDateMonth,
    } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/storeMarketingStatList', {
            type,
            orderByKey,
            orderByType,
            storeName,
            statBeginDate,
            statEndDate,
            statBeginDateMonth,
            statEndDateMonth,
        });
    }

    storeMarketingStatDetail({ type, storeId, statDateDay, statBeginDateMonth, statEndDateMonth } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/storeMarketingStatDetail', {
            type,
            storeId,
            statBeginDate,
            statEndDate,
            statBeginDateMonth,
            statEndDateMonth,
        });
    }

    storeBuyerMarketingStatList({
        type,
        orderByKey,
        orderByType,
        fullName,
        mobile,
        statDateDay,
        statBeginDateMonth,
        statEndDateMonth,
    } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/storeBuyerMarketingStatList', {
            type,
            orderByKey,
            orderByType,
            fullName,
            mobile,
            statBeginDate,
            statEndDate,
            statBeginDateMonth,
            statEndDateMonth,
        });
    }

    storeBuyerMarketingStatDetail({ type, storeBuyerId, statDateDay, statBeginDateMonth, statEndDateMonth } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/storeBuyerMarketingStatDetail', {
            type,
            storeBuyerId,
            statBeginDate,
            statEndDate,
            statBeginDateMonth,
            statEndDateMonth,
        });
    }

    deviceMarketingStatList({
        type,
        orderByKey,
        orderByType,
        deviceName,
        deviceSn,
        statDateDay,
        statBeginDateMonth,
        statEndDateMonth,
    } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/deviceMarketingStatList', {
            type,
            orderByKey,
            orderByType,
            deviceName,
            deviceSn,
            statBeginDate,
            statEndDate,
            statBeginDateMonth,
            statEndDateMonth,
        });
    }

    deviceMarketingStatDetail({ type, deviceId, statDateDay, statBeginDateMonth, statEndDateMonth } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/deviceMarketingStatDetail', {
            type,
            deviceId,
            statBeginDate,
            statEndDate,
            statBeginDateMonth,
            statEndDateMonth,
        });
    }
}

export default new MarketingStat();
export { MarketingStat };
