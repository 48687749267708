/**
 * Created by henian.xu on 2021/8/15.
 *
 */

import { Utils } from 'vmf';
import { baiduEvent, BMapEvent } from './BMapEvent';

export const createMaskOverlayClass = BMap => {
    return class MaskOverlay extends BMap.Overlay {
        initialize(map) {
            this._map = map;
            const div = document.createElement('div');
            const size = this._map.getSize();
            div.style.cssText = `position:absolute;background:rgba(255,0,0,0);cursor:crosshair;width:${
                size.width
            }px;height:${size.height}px;`;
            this.container = div;
            this._enableEdgeMove = true;
            this._map.addEventListener('resize', e => {
                this.adjustSize(e.size);
            });
            this._map.getPanes().floatPane.appendChild(div);
            this._bind();
            return div;
        }

        draw() {
            const map = this._map;
            const point = map.pixelToPoint(new BMap.Pixel(0, 0));
            const pixel = map.pointToOverlayPixel(point);
            this.container.style.left = `${pixel.x}px`;
            this.container.style.top = `${pixel.y}px`;
        }

        adjustSize(size) {
            this.container.style.width = `${size.width}px`;
            this.container.style.height = `${size.height}px`;
        }

        getDrawPoint(e) {
            const map = this._map;
            let trigger = baiduEvent.getTarget(e);
            let x = e.offsetX || e.layerX || 0;
            let y = e.offsetY || e.layerY || 0;
            if (trigger.nodeType !== 1) trigger = trigger.parentNode;

            while (trigger && trigger !== map.getContainer()) {
                if (
                    !(
                        trigger.clientWidth === 0 &&
                        trigger.clientHeight === 0 &&
                        trigger.offsetParent &&
                        trigger.offsetParent.nodeName === 'TD'
                    )
                ) {
                    x += trigger.offsetLeft || 0;
                    y += trigger.offsetTop || 0;
                }

                trigger = trigger.offsetParent;
            }
            const pixel = new BMap.Pixel(x, y);
            const point = map.pixelToPoint(pixel);
            return point;
        }

        /* addEventListener(type, listener, options) {
      const { container } = this;
      container.addEventListener(
        type,
        (e) => {
          e.point = this.getDrawPoint(e);
          return listener(e);
        },
        options,
      );
    }

    removeEventListener(type, listener, options) {
      const { container } = this;
      container.addEventListener(type, listener, options);
    } */

        addEventListener(type, handler, key) {
            if (!Utils.isFunction(handler)) return;

            if (!this.__listeners) this.__listeners = {};
            const listeners = this.__listeners;
            let id;
            if (typeof key === 'string' && key) {
                if (/[^\w-]/.test(key)) {
                    throw new Error(`nonstandard key:${key}`);
                } else {
                    handler.hashCode = key;
                    id = key;
                }
            }

            if (type.indexOf('on') !== 0) type = `on${type}`;
            if (typeof listeners[type] !== 'object') listeners[type] = {};
            id = id || Utils.getUniqueId();
            handler.hashCode = id;
            listeners[type][id] = handler;
        }

        removeEventListener(type, handler) {
            if (Utils.isFunction(handler)) {
                handler = handler.hashCode;
            } else if (!Utils.isString(handler)) {
                return;
            }
            if (!this.__listeners) this.__listeners = {};
            if (type.indexOf('on') !== 0) type = `on${type}`;
            const t = this.__listeners;
            if (!t[type]) return;
            if (t[type][handler]) delete t[type][handler];
        }

        dispatchEvent(event, options) {
            if (Utils.isString(event)) event = new BMapEvent(event);

            if (!this.__listeners) this.__listeners = {};
            options = options || {};
            Object.keys(options).forEach(key => {
                event[key] = options[key];
            });
            const listeners = this.__listeners;
            let { type } = event;
            if (type === 'contextmenu') type = 'rightclick';
            // event.target = event.target || this;
            // event.currentTarget = this;
            if (type.indexOf('on') !== 0) type = `on${type}`;
            if (Utils.isFunction(this[type])) {
                this[type].apply(this, [event, options]);
            }
            const listener = listeners[type];
            if (typeof listener === 'object') {
                Object.keys(listener).forEach(key => {
                    listener[key].apply(this, [event, options]);
                });
            }
            return event.returnValue;
        }

        _bind() {
            const me = this;
            // const map = this._map;
            const { container } = this;
            let lastMousedownXY = null;
            let lastClickXY = null;

            /**
             * 根据event对象获取鼠标的xy坐标对象
             * @param {Event}
             * @return {Object} {x:e.x, y:e.y}
             */
            const getXYbyEvent = e => {
                return {
                    x: e.clientX,
                    y: e.clientY,
                };
            };

            const domEvent = e => {
                const { type } = e;
                e = baiduEvent.getEvent(e);
                const point = me.getDrawPoint(e); // 当前鼠标所在点的地理坐标

                const dispatchEvent = (/* type */) => {
                    e.point = point;
                    me.dispatchEvent(e);
                };

                if (type === 'mousedown') {
                    lastMousedownXY = getXYbyEvent(e);
                }

                const nowXY = getXYbyEvent(e);
                // click经过一些特殊处理派发，其他同事件按正常的dom事件派发
                if (type === 'click') {
                    // 鼠标点击过程不进行移动才派发click和dblclick
                    if (Math.abs(nowXY.x - lastMousedownXY.x) < 5 && Math.abs(nowXY.y - lastMousedownXY.y) < 5) {
                        if (
                            !lastClickXY ||
                            !(Math.abs(nowXY.x - lastClickXY.x) < 5 && Math.abs(nowXY.y - lastClickXY.y) < 5)
                        ) {
                            dispatchEvent('click');
                            lastClickXY = getXYbyEvent(e);
                        } else {
                            lastClickXY = null;
                        }
                    }
                } else {
                    dispatchEvent(type);
                }
            };

            /**
             * 将事件都遮罩层的事件都绑定到domEvent来处理
             */
            const events = ['click', 'mousedown', 'mousemove', 'mouseup', 'dblclick', 'rightclick'];
            let index = events.length - 1;
            while (index >= 0) {
                const type = events[index];
                if (type === 'rightclick') {
                    baiduEvent.on(container, 'contextmenu', e => {
                        e.preventDefault();
                        domEvent(e);
                    });
                } else {
                    baiduEvent.on(container, type, domEvent);
                }
                index -= 1;
            }

            // 鼠标移动过程中，到地图边缘后自动平移地图
            baiduEvent.on(container, 'mousemove', e => {
                if (me._enableEdgeMove) {
                    me.mousemoveAction(e);
                }
            });
        }

        mousemoveAction(event) {
            function getClientPosition(e) {
                let { clientX } = e;
                let { clientY } = e;
                if (e.changedTouches) {
                    clientX = e.changedTouches[0].clientX;
                    clientY = e.changedTouches[0].clientY;
                }

                return new BMap.Pixel(clientX, clientY);
            }

            const map = this._map;
            const me = this;
            let pixel = map.pointToPixel(this.getDrawPoint(event));
            const clientPos = getClientPosition(event);
            const offsetX = clientPos.x - pixel.x;
            const offsetY = clientPos.y - pixel.y;
            pixel = new BMap.Pixel(clientPos.x - offsetX, clientPos.y - offsetY);
            // this._draggingMovePixel = pixel;
            // const point = map.pixelToPoint(pixel);
            /* const eventObj = {
        pixel,
        point,
      }; */
            // 拖拽到地图边缘移动地图
            this._panByX = 0;
            this._panByY = 0;
            if (pixel.x <= 10 || pixel.x >= map.width - 10 || pixel.y <= 10 || pixel.y >= map.height - 10) {
                if (pixel.x <= 20) {
                    this._panByX = 8;
                } else if (pixel.x >= map.width - 20) {
                    this._panByX = -8;
                }

                if (pixel.y <= 50) {
                    this._panByY = 8;
                } else if (pixel.y >= map.height - 10) {
                    this._panByY = -8;
                }

                if (!this._edgeMoveTimer) {
                    this._edgeMoveTimer = setInterval(() => {
                        map.panBy(me._panByX, me._panByY, {
                            noAnimation: true,
                        });
                    }, 30);
                }
            } else if (this._edgeMoveTimer) {
                clearInterval(this._edgeMoveTimer);
                this._edgeMoveTimer = null;
            }
        }
    };
};

export default createMaskOverlayClass;
