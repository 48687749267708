/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class PlatformConfig extends Rs {
    constructor() {
        super();
        this.baseUrl += '/platformConfig';
    }

    getDetail() {
        return super.get('/get', {}).then(json => {
            if (json.data.data.otherConfig) {
                json.data.data.isPushInventory = json.data.data.otherConfig.isPushInventory;
                json.data.data.isSyncInventoryGoods = json.data.data.otherConfig.isSyncInventoryGoods;
                json.data.data.inventoryAppKey = json.data.data.otherConfig.inventoryAppKey;
                json.data.data.inventoryAppSecret = json.data.data.otherConfig.inventoryAppSecret;
            }
            return json;
        });
    }

    save({ isSupplierQuotations, isPushInventory, isSyncInventoryGoods, inventoryAppKey, inventoryAppSecret } = {}) {
        const otherConfig = {
            isPushInventory,
            isSyncInventoryGoods,
            inventoryAppKey,
            inventoryAppSecret,
        };
        return super.post('/save', {
            isSupplierQuotations,
            otherConfig: JSON.stringify(otherConfig),
        });
    }
}

export default new PlatformConfig();
export { PlatformConfig };
