<!-- Created by henian.xu on 2021/8/13. -->

<template>
    <div
        v-if="distanceTool"
        class="b-map-distance-tool">
        <el-button
            size="mini"
            :type="isDrawing ? 'primary' : ''"
            @click="onStart"
        >测距
        </el-button
        >
    </div>
</template>

<script>
import { Utils } from 'vmf';
import { BMapWidgetMixin, getBMapParent } from './BMapWidgetMixin';

export default {
    name: 'BMapDistanceTool',
    mixins: [BMapWidgetMixin],
    data() {
        return {
            distanceTool: null,
            isDrawing: false,
        };
    },
    methods: {
        async ready() {
            const $parent = getBMapParent(this.$parent);
            this.BMap = $parent.BMap;
            this.map = $parent.map;

            const DistanceTool = await Utils.loadExternalScript(
                '//relcdn.oss-cn-hangzhou.aliyuncs.com/BMapLib/DistanceTool/DistanceTool.min.js',
                () => {
                    const { BMapLib } = window;
                    if (BMapLib && BMapLib.DistanceTool) {
                        return BMapLib.DistanceTool;
                    }
                    return null;
                },
            );

            this.distanceTool = new DistanceTool(this.map);

            this.distanceTool.addEventListener('drawend', () => {
                this.isDrawing = false;
            });
        },
        onStart() {
            this.distanceTool.open();
            this.isDrawing = true;
            /* const { isDrawing } = this;
      if (isDrawing) {
        this.distanceTool.close();
      } else {
        this.distanceTool.open();
      }
      this.isDrawing = !this.isDrawing; */
        },
    },
};
</script>

<style lang="scss">
.b-map-distance-tool {
}
</style>
