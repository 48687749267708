/**
 * Created by henian.xu on 2023/5/31.
 *
 */

import { GlobalVar, localStorage, Vue } from 'vmf';
import VueI18n from 'vue-i18n';
import { Api } from '@/api';

export const STORE_KEY_I18N_MESSAGES = '__STORE_KEY_I18N_MESSAGES__';
export const STORE_KEY_I18N_LOCALE = '__STORE_KEY_I18N_LOCALE__';
export const localeLanguage = localStorage.get(STORE_KEY_I18N_LOCALE);

VueI18n.prototype.getLanguage = async function getLanguage(locale = GlobalVar.currentLanguage) {
    const { data } = await Api.Rs.LanguageEntry.getLanguageEntryForPlatform({ language: locale });
    Object.entries(data).forEach(([key, value]) => {
        // i18n.setLocaleMessage(key, value);
        this.mergeLocaleMessage(key, value);
    });
    localStorage.set(STORE_KEY_I18N_MESSAGES, this.messages);
};

VueI18n.prototype.changeLanguage = async function changeLanguage(locale = GlobalVar.currentLanguage) {
    if (!locale) return;
    await this.getLanguage(locale);
    if (locale === localeLanguage) return;
    this.locale = locale;
    localStorage.set(STORE_KEY_I18N_LOCALE, this.locale);
    window.location.reload();
};

Vue.use(VueI18n);

Vue.use(Vue_ => {
    const old$t = Vue_.prototype.$t;
    Vue_.prototype.$t = function func(key, defaultValue, ...values) {
        return old$t.call(this, key, ...values) || defaultValue || '';
    };
});

export const i18n = new VueI18n({
    locale: GlobalVar.currentLanguage, // 设置地区
    messages: localStorage.get(STORE_KEY_I18N_MESSAGES) || {}, // 设置地区信息
    missing() {
        // noop
        return '';
    },
});

Vue.prototype._i18n = i18n;

export default i18n;

// i18n.changeLanguage(localeLanguage || GlobalVar.currentLanguage);
