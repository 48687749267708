/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopPaymentTemplate extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopPaymentTemplate';
    }

    data({ shopId, storeName, templateName, isAvailable, isDefault, currentPage, pageSize } = {}) {
        return super.get('/data', {
            shopId,
            storeName,
            templateName,
            isAvailable,
            isDefault,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({
        id,
        shopId,
        templateName,
        storeIds,
        goodsType,
        goodsSendModes,
        paymentType,
        orderTypes,
        shipMethod,
        isAvailable,
        isDefault,
        detailJson,
        otherConfig,
    } = {}) {
        return super.post('/save', {
            id,
            shopId,
            templateName,
            storeIds,
            goodsType,
            goodsSendModes,
            paymentType,
            orderTypes,
            shipMethod,
            isAvailable,
            isDefault,
            detailJson,
            otherConfig,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new ShopPaymentTemplate();
export { ShopPaymentTemplate };
