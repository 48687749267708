/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import ShopSaleStat from './ShopSaleStat';
import GoodsSaleStat from './GoodsSaleStat';
import GoodsFlowSaleStat from './GoodsFlowSaleStat';
import GoodsCatSaleStat from './GoodsCatSaleStat';
import GoodsRegionSaleStat from './GoodsRegionSaleStat';
import BrowserTypeSaleStat from './BrowserTypeSaleStat';
import SearchKeywordsSaleStat from './SearchKeywordsSaleStat';
import GoodsMatchingSaleStat from './GoodsMatchingSaleStat';
import BuyerSaleStat from './BuyerSaleStat';
import ShopSearchKeywords from './ShopSearchKeywords';

export default {
    ShopSaleStat,
    GoodsSaleStat,
    GoodsFlowSaleStat,
    GoodsCatSaleStat,
    GoodsRegionSaleStat,
    BrowserTypeSaleStat,
    SearchKeywordsSaleStat,
    GoodsMatchingSaleStat,
    BuyerSaleStat,
    ShopSearchKeywords,
};
