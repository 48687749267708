/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class BuyerBenefitCard extends Mb {
    constructor() {
        super();
        this.baseUrl += '/buyerBenefitCard';
    }

    data({ userName, mobile, buyerLevelId, distributorLevelId, cardName, currentPage, pageSize } = {}) {
        return super.get('/data', {
            userName,
            mobile,
            buyerLevelId,
            distributorLevelId,
            cardName,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }
}

export default new BuyerBenefitCard();
export { BuyerBenefitCard };
