/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class Order extends Ex {
    constructor() {
        super();
        this.baseUrl += '/order';
    }

    orderList(queryData) {
        let orderBeginTime = null;
        let orderEndTime = null;
        if (queryData.orderTime != null && queryData.orderTime.length === 2) {
            [orderBeginTime, orderEndTime] = queryData.orderTime;
        }
        let presellEndTimeBegin = null;
        let presellEndTimeEnd = null;
        if (queryData.presellEndTime != null && queryData.presellEndTime.length === 2) {
            [presellEndTimeBegin, presellEndTimeEnd] = queryData.presellEndTime;
        }
        let estimateShippingTimeBegin = null;
        let estimateShippingTimeEnd = null;
        if (queryData.estimateShippingTime != null && queryData.estimateShippingTime.length === 2) {
            [estimateShippingTimeBegin, estimateShippingTimeEnd] = queryData.estimateShippingTime;
        }
        let confirmShipDateBegin = null;
        let confirmShipDateEnd = null;
        if (queryData.confirmShipDate != null && queryData.confirmShipDate.length === 2) {
            [confirmShipDateBegin, confirmShipDateEnd] = queryData.confirmShipDate;
        }
        let receiveTimeBegin = null;
        let receiveTimeEnd = null;
        if (queryData.receiveTime != null && queryData.receiveTime.length === 2) {
            [receiveTimeBegin, receiveTimeEnd] = queryData.receiveTime;
        }
        let cancelOperateTimeBegin = null;
        let cancelOperateTimeEnd = null;
        if (queryData.cancelOperateTime != null && queryData.cancelOperateTime.length === 2) {
            [cancelOperateTimeBegin, cancelOperateTimeEnd] = queryData.cancelOperateTime;
        }
        let shippingTimeBegin = null;
        let shippingTimeEnd = null;
        if (queryData.shippingTime != null && queryData.shippingTime.length === 2) {
            [shippingTimeBegin, shippingTimeEnd] = queryData.shippingTime;
        }
        return super.get('/orderList', {
            ...queryData,
            orderBeginTime,
            orderEndTime,
            presellEndTimeBegin,
            presellEndTimeEnd,
            receiveTimeBegin,
            receiveTimeEnd,
            cancelOperateTimeBegin,
            cancelOperateTimeEnd,
            shippingTimeBegin,
            shippingTimeEnd,
            estimateShippingTimeBegin,
            estimateShippingTimeEnd,
            confirmShipDateBegin,
            confirmShipDateEnd,
        });
    }

    getEstimateShippingTimeList(queryData) {
        let orderBeginTime = null;
        let orderEndTime = null;
        if (queryData.orderTime != null && queryData.orderTime.length === 2) {
            [orderBeginTime, orderEndTime] = queryData.orderTime;
        }
        let presellEndTimeBegin = null;
        let presellEndTimeEnd = null;
        if (queryData.presellEndTime != null && queryData.presellEndTime.length === 2) {
            [presellEndTimeBegin, presellEndTimeEnd] = queryData.presellEndTime;
        }
        let estimateShippingTimeBegin = null;
        let estimateShippingTimeEnd = null;
        if (queryData.estimateShippingTime != null && queryData.estimateShippingTime.length === 2) {
            [estimateShippingTimeBegin, estimateShippingTimeEnd] = queryData.estimateShippingTime;
        }
        let confirmShipDateBegin = null;
        let confirmShipDateEnd = null;
        if (queryData.confirmShipDate != null && queryData.confirmShipDate.length === 2) {
            [confirmShipDateBegin, confirmShipDateEnd] = queryData.confirmShipDate;
        }
        let receiveTimeBegin = null;
        let receiveTimeEnd = null;
        if (queryData.receiveTime != null && queryData.receiveTime.length === 2) {
            [receiveTimeBegin, receiveTimeEnd] = queryData.receiveTime;
        }
        let cancelOperateTimeBegin = null;
        let cancelOperateTimeEnd = null;
        if (queryData.cancelOperateTime != null && queryData.cancelOperateTime.length === 2) {
            [cancelOperateTimeBegin, cancelOperateTimeEnd] = queryData.cancelOperateTime;
        }
        let shippingTimeBegin = null;
        let shippingTimeEnd = null;
        if (queryData.shippingTime != null && queryData.shippingTime.length === 2) {
            [shippingTimeBegin, shippingTimeEnd] = queryData.shippingTime;
        }
        return super.get('/getEstimateShippingTimeList', {
            ...queryData,
            orderBeginTime,
            orderEndTime,
            presellEndTimeBegin,
            presellEndTimeEnd,
            estimateShippingTimeBegin,
            estimateShippingTimeEnd,
            confirmShipDateBegin,
            confirmShipDateEnd,
            receiveTimeBegin,
            receiveTimeEnd,
            cancelOperateTimeBegin,
            cancelOperateTimeEnd,
            shippingTimeBegin,
            shippingTimeEnd,
        });
    }

    getOrderLodopPrintInfo(queryData) {
        let orderBeginTime = null;
        let orderEndTime = null;
        if (queryData.orderTime != null && queryData.orderTime.length === 2) {
            [orderBeginTime, orderEndTime] = queryData.orderTime;
        }
        let presellEndTimeBegin = null;
        let presellEndTimeEnd = null;
        if (queryData.presellEndTime != null && queryData.presellEndTime.length === 2) {
            [presellEndTimeBegin, presellEndTimeEnd] = queryData.presellEndTime;
        }
        let estimateShippingTimeBegin = null;
        let estimateShippingTimeEnd = null;
        if (queryData.estimateShippingTime != null && queryData.estimateShippingTime.length === 2) {
            [estimateShippingTimeBegin, estimateShippingTimeEnd] = queryData.estimateShippingTime;
        }
        let confirmShipDateBegin = null;
        let confirmShipDateEnd = null;
        if (queryData.confirmShipDate != null && queryData.confirmShipDate.length === 2) {
            [confirmShipDateBegin, confirmShipDateEnd] = queryData.confirmShipDate;
        }
        let receiveTimeBegin = null;
        let receiveTimeEnd = null;
        if (queryData.receiveTime != null && queryData.receiveTime.length === 2) {
            [receiveTimeBegin, receiveTimeEnd] = queryData.receiveTime;
        }
        let cancelOperateTimeBegin = null;
        let cancelOperateTimeEnd = null;
        if (queryData.cancelOperateTime != null && queryData.cancelOperateTime.length === 2) {
            [cancelOperateTimeBegin, cancelOperateTimeEnd] = queryData.cancelOperateTime;
        }
        let shippingTimeBegin = null;
        let shippingTimeEnd = null;
        if (queryData.shippingTime != null && queryData.shippingTime.length === 2) {
            [shippingTimeBegin, shippingTimeEnd] = queryData.shippingTime;
        }
        return super.get('/getOrderLodopPrintInfo', {
            ...queryData,
            orderBeginTime,
            orderEndTime,
            presellEndTimeBegin,
            presellEndTimeEnd,
            estimateShippingTimeBegin,
            estimateShippingTimeEnd,
            confirmShipDateBegin,
            confirmShipDateEnd,
            receiveTimeBegin,
            receiveTimeEnd,
            cancelOperateTimeBegin,
            cancelOperateTimeEnd,
            shippingTimeBegin,
            shippingTimeEnd,
        });
    }

    getOrderExpressId(queryData) {
        let orderBeginTime = null;
        let orderEndTime = null;
        if (queryData.orderTime != null && queryData.orderTime.length === 2) {
            [orderBeginTime, orderEndTime] = queryData.orderTime;
        }
        let presellEndTimeBegin = null;
        let presellEndTimeEnd = null;
        if (queryData.presellEndTime != null && queryData.presellEndTime.length === 2) {
            [presellEndTimeBegin, presellEndTimeEnd] = queryData.presellEndTime;
        }
        let estimateShippingTimeBegin = null;
        let estimateShippingTimeEnd = null;
        if (queryData.estimateShippingTime != null && queryData.estimateShippingTime.length === 2) {
            [estimateShippingTimeBegin, estimateShippingTimeEnd] = queryData.estimateShippingTime;
        }
        let confirmShipDateBegin = null;
        let confirmShipDateEnd = null;
        if (queryData.confirmShipDate != null && queryData.confirmShipDate.length === 2) {
            [confirmShipDateBegin, confirmShipDateEnd] = queryData.confirmShipDate;
        }
        let receiveTimeBegin = null;
        let receiveTimeEnd = null;
        if (queryData.receiveTime != null && queryData.receiveTime.length === 2) {
            [receiveTimeBegin, receiveTimeEnd] = queryData.receiveTime;
        }
        let cancelOperateTimeBegin = null;
        let cancelOperateTimeEnd = null;
        if (queryData.cancelOperateTime != null && queryData.cancelOperateTime.length === 2) {
            [cancelOperateTimeBegin, cancelOperateTimeEnd] = queryData.cancelOperateTime;
        }
        let shippingTimeBegin = null;
        let shippingTimeEnd = null;
        if (queryData.shippingTime != null && queryData.shippingTime.length === 2) {
            [shippingTimeBegin, shippingTimeEnd] = queryData.shippingTime;
        }
        return super.get('/getOrderExpressId', {
            ...queryData,
            orderBeginTime,
            orderEndTime,
            presellEndTimeBegin,
            presellEndTimeEnd,
            estimateShippingTimeBegin,
            estimateShippingTimeEnd,
            confirmShipDateBegin,
            confirmShipDateEnd,
            receiveTimeBegin,
            receiveTimeEnd,
            cancelOperateTimeBegin,
            cancelOperateTimeEnd,
            shippingTimeBegin,
            shippingTimeEnd,
        });
    }

    getDetail({ id }) {
        return super.get('/getDetail', { id });
    }

    cancelOrder({ id, cancelDesc, isSendMsg }) {
        return super.post('/cancelOrder', { id, cancelDesc, isSendMsg });
    }

    auditOrder({ ids, orderAuditStatus, auditOpinion }) {
        return super.post('/auditOrder', { ids, orderAuditStatus, auditOpinion });
    }

    completeOrder({ orderId, receiveRemarks, receiveAttachment }) {
        const receiveAttachmentStr = receiveAttachment
            ? receiveAttachment
                  .reduce((prev, curr) => {
                      prev.push(curr.name);
                      return prev;
                  }, [])
                  .join(',')
            : '';
        return super.post('/completeOrder', { orderId, receiveRemarks, receiveAttachment: receiveAttachmentStr });
    }

    shippingOrder({ id, expressId, expressName, expressCode, trackingNumber }) {
        return super.post(
            '/shippingOrder',
            {
                id,
                expressId,
                expressName,
                expressCode,
                trackingNumber,
            },
            { timeout: 0 },
        );
    }

    batchShippingOrder({ ids, expressId }) {
        return super.post(
            '/batchShippingOrder',
            {
                ids,
                expressId,
            },
            {
                isHandleError: true,
                timeout: 0,
            },
        );
    }

    stockHandleOrder(params) {
        return super.post('/stockHandleOrder', params);
    }

    editOrderDeliveryInfo({
        id,
        deliveryConsignee,
        deliveryPhone,
        deliveryRegionId,
        deliveryAddress,
        deliveryIdCardNo,
        deliveryHouseNum,
    }) {
        return super.post('/editOrderDeliveryInfo', {
            id,
            deliveryConsignee,
            deliveryPhone,
            deliveryRegionId,
            deliveryAddress,
            deliveryIdCardNo,
            deliveryHouseNum,
        });
    }

    editOrderComments({ id, orderComments }) {
        return super.post('/editOrderComments', { id, orderComments });
    }

    batchSyncErpOrder({ ids }) {
        return super.post('/batchSyncErpOrder', { ids }, { timeout: 0, isHandleError: true });
    }

    pushOrderToGateway({ ids }) {
        return super.post('/pushOrderToGateway', { ids }, { timeout: 0, isHandleError: true });
    }

    rePushOrderToWarehousesystem({ id }) {
        return super.post('/rePushOrderToWarehousesystem', { id }, { timeout: 0, isHandleError: true });
    }

    batchSyncErpOrderTrackingInfo({ ids }) {
        return super.post('/batchSyncErpOrderTrackingInfo', { ids }, { timeout: 0, isHandleError: true });
    }

    batchSyncGatewayOrderTrackingInfo({ ids }) {
        return super.post('/batchSyncGatewayOrderTrackingInfo', { ids }, { timeout: 0, isHandleError: true });
    }

    csOrderList({
        orderSnEq,
        deliveryPhoneEq,
        orderStatus,
        shipMethod,
        goodsType,
        trackingNumberEq,
        buyerMobileEq,
        currentPage,
        pageSize,
    }) {
        return super.get('/csOrderList', {
            orderSnEq,
            deliveryPhoneEq,
            orderStatus,
            shipMethod,
            goodsType,
            trackingNumberEq,
            buyerMobileEq,
            currentPage,
            pageSize,
        });
    }

    csStoreOrderList({ orderSnEq, buyerMobileEq, currentPage, pageSize }) {
        return super.get('/csStoreOrderList', {
            orderSnEq,
            buyerMobileEq,
            currentPage,
            pageSize,
        });
    }

    checkIdCard({ id, deliveryConsignee, deliveryIdCardNo, deliveryIdCardFront, deliveryIdCardBack }) {
        let deliveryIdCardFrontStr;
        if (deliveryIdCardFront) {
            deliveryIdCardFrontStr = deliveryIdCardFront
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let deliveryIdCardBackStr;
        if (deliveryIdCardBack) {
            deliveryIdCardBackStr = deliveryIdCardBack
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        return super.post('/checkIdCard', {
            id,
            deliveryConsignee,
            deliveryIdCardNo,
            deliveryIdCardFront: deliveryIdCardFrontStr,
            deliveryIdCardBack: deliveryIdCardBackStr,
        });
    }

    addOrderLog({ id, orderLog }) {
        return super.post('/addOrderLog', { id, orderLog });
    }

    updateOrderPrintStatus({ ids, status }) {
        return super.post('/updateOrderPrintStatus', { ids, status });
    }

    createOrder({
        buyerId,
        deliveryConsignee,
        deliveryPhone,
        deliveryRegionId,
        deliveryAddress,
        deliveryIdCardNo,
        subscriberName,
        subscriberIdCardNo,
        sendConsignor,
        sendTel,
        buyerMsg,
        goodsListJson,
    }) {
        return super.post('/createOrder', {
            buyerId,
            deliveryConsignee,
            deliveryPhone,
            deliveryRegionId,
            deliveryAddress,
            deliveryIdCardNo,
            subscriberName,
            subscriberIdCardNo,
            sendConsignor,
            sendTel,
            buyerMsg,
            goodsListJson,
        });
    }

    editSubscriber({ id, subscriberName, subscriberIdCardNo }) {
        return super.post('/editSubscriber', { id, subscriberName, subscriberIdCardNo });
    }

    changeWarehouse({ id, supplierId, warehouseId }) {
        return super.post('/changeWarehouse', { id, supplierId, warehouseId });
    }

    setDistributor({ ids, buyerId }) {
        return super.post('/setDistributor', { ids, buyerId });
    }

    syncWeimobcloudOrder({ ids, syncTime }) {
        let syncTimeBegin = null;
        let syncTimeEnd = null;
        if (syncTime != null && syncTime.length === 2) {
            [syncTimeBegin, syncTimeEnd] = syncTime;
        }
        return super.post(
            '/syncWeimobcloudOrder',
            {
                ids,
                syncTimeBegin,
                syncTimeEnd,
            },
            { timeout: 0, isHandleError: true },
        );
    }

    printOrderByClodop({ ids, templateType }) {
        return super.post('/printOrderByClodop', { ids, templateType });
    }

    printOrderByXpyun({ ids }) {
        return super.post('/printOrderByXpyun', { ids });
    }

    findWaitPrintOrderList({ templateType }) {
        return super.post('/findWaitPrintOrderList', { templateType });
    }

    findWeixinShipOrderList(queryData) {
        let shippingTimeBegin = null;
        let shippingTimeEnd = null;
        if (queryData.shippingTime != null && queryData.shippingTime.length === 2) {
            [shippingTimeBegin, shippingTimeEnd] = queryData.shippingTime;
        }
        return super.get('/findWeixinShipOrderList', {
            ...queryData,
            shippingTimeBegin,
            shippingTimeEnd,
        });
    }
}

export default new Order();
export { Order };
