/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopStoreTable extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopStoreTable';
    }

    data({ storeId, storeTableArea, storeTableSn, storeTableType, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            storeId,
            storeTableArea,
            storeTableSn,
            storeTableType,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, storeId, storeTableArea, storeTableSn, storeTableType, isAvailable, remarks } = {}) {
        return super.post('/save', {
            id,
            storeId,
            storeTableArea,
            storeTableSn,
            storeTableType,
            isAvailable,
            remarks,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    getShareInfo({ id }) {
        return super.get('/getShareInfo', { id });
    }
}

export default new ShopStoreTable();
export { ShopStoreTable };
