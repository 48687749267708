/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class BuyerWithdraw extends Mb {
    constructor() {
        super();
        this.baseUrl += '/buyerWithdraw';
    }

    data({ withdrawApplySn, mobile, withdrawType, withdrawStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            withdrawApplySn,
            mobile,
            withdrawType,
            withdrawStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', { id });
    }

    audit({ id, paymentAmount, paymentVoucher, auditStatus, auditRemarks } = {}) {
        const paymentVoucherStr = paymentVoucher
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/audit', {
            id,
            paymentAmount,
            paymentVoucher: paymentVoucherStr,
            auditStatus,
            auditRemarks,
        });
    }
}

export default new BuyerWithdraw();
export { BuyerWithdraw };
