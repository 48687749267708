/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class GoodsCat extends Gd {
    constructor() {
        super();
        this.baseUrl += '/goodsCat';
    }

    data({ pId, name, isAvailable } = {}) {
        return super.get('/data', {
            pId,
            name,
            isAvailable,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({
        id,
        parentId,
        name,
        shareIncomeRate,
        levelIncomeRate,
        topIncomeRate,
        liveShareIncomeRate,
        anchorIncomeRate,
        goodsCatPermission,
        orderNum,
        isAvailable,
        gstRate,
        buyerLevelPriceRate,
    } = {}) {
        return super.post('/save', {
            id,
            parentId,
            name,
            shareIncomeRate,
            levelIncomeRate,
            topIncomeRate,
            liveShareIncomeRate,
            anchorIncomeRate,
            goodsCatPermission,
            orderNum,
            isAvailable,
            gstRate,
            buyerLevelPriceRate,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    exists({ id, pId, name } = {}) {
        return super.get('/exists', {
            id,
            pId,
            name,
        });
    }

    select({ pId, name, shopIdNull } = {}, config) {
        return super.get(
            '/select',
            {
                pId,
                name,
                shopIdNull,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }

    loadSelectNode({ id } = {}, config) {
        return super.get(
            '/loadSelectNode',
            {
                id,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }
}

export default new GoodsCat();
export { GoodsCat };
