import { Mb } from './Mb';

class VisitReserve extends Mb {
    constructor() {
        super();
        this.baseUrl += '/visitReserve';
    }

    findVisitReserve({ reserveTime, reserveName, reserveNum, currentPage, pageSize } = {}) {
        let reserveTimeBegin;
        let reserveTimeEnd;
        if (reserveTime && reserveTime.length === 2) {
            [reserveTimeBegin, reserveTimeEnd] = reserveTime;
        }
        return super.get('/data', {
            reserveTimeBegin,
            reserveTimeEnd,
            reserveName,
            reserveNum,
            currentPage,
            pageSize,
        });
    }

    findReserveStatistics({ reserveTime, currentPage, pageSize } = {}) {
        let reserveTimeBegin;
        let reserveTimeEnd;
        if (reserveTime && reserveTime.length === 2) {
            [reserveTimeBegin, reserveTimeEnd] = reserveTime;
        }
        return super.get('/findReserveStatistics', {
            reserveTimeBegin,
            reserveTimeEnd,
            currentPage,
            pageSize,
        });
    }
}

export default new VisitReserve();
export { VisitReserve };
