/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopStoreServiceStation extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopStoreServiceStation';
    }

    data({ storeId, stationName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            storeId,
            stationName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { data } = json.data;
            if (data.addressLatitude && data.addressLongitude) {
                data.latLng = `${data.addressLongitude},${data.addressLatitude}`;
            }
            return json;
        });
    }

    save({
        id,
        storeId,
        stationName,
        stationRegionId,
        stationDetailAddress,
        latLng,
        contactFullname,
        contactTel,
        openTime,
        isAvailable,
        orderNum,
    } = {}) {
        let addressLongitude = null;
        let addressLatitude = null;
        if (latLng) {
            [addressLongitude, addressLatitude] = latLng.split(',');
        }
        return super.post('/save', {
            id,
            storeId,
            stationName,
            stationRegionId,
            stationDetailAddress,
            addressLongitude,
            addressLatitude,
            contactFullname,
            contactTel,
            openTime,
            isAvailable,
            orderNum,
        });
    }
}

export default new ShopStoreServiceStation();
export { ShopStoreServiceStation };
