/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class OrderFundExit extends Ex {
    constructor() {
        super();
        this.baseUrl += '/orderFundExit';
    }

    data({
        fundExitSn,
        paymentSn,
        orderSn,
        externalPaymentSn,
        fundExitStatus,
        orderStatus,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            fundExitSn,
            paymentSn,
            orderSn,
            externalPaymentSn,
            fundExitStatus,
            orderStatus,
            currentPage,
            pageSize,
        });
    }

    submit({ ids } = {}) {
        return super.post(
            '/submit',
            {
                ids,
            },
            {
                timeout: 0,
                isLoading: true,
            },
        );
    }
}

export default new OrderFundExit();
export { OrderFundExit };
