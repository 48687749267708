/**
 * Created by henian.xu on 2022/6/13.
 *
 */

import { get } from 'vmf';
import { ElementUI } from '@vmf/components';
import Vue from 'vue';

const { MessageBox } = ElementUI;
const emptyAudio =
    'data:audio/mpeg;base64,SUQzAwAAAAAAFgAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/4xjAAAAAAlwAAAAAtASxAAAAAAAARQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/4xjAMQAAAlwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/4xjAbAAAAlwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=';
let isBindClickEvent = false;
let audioContext = null;

function checkAutoplay() {
    const audio = new Audio(emptyAudio);
    return new Promise((resolve, reject) => {
        audio
            .play()
            .then(() => {
                audioContext = new (window.AudioContext || window.webkitAudioContext)();
                resolve();
            })
            .catch(e => {
                reject(e);
                if (isBindClickEvent) return;
                function setAutoPlay() {
                    // eslint-disable-next-line no-use-before-define
                    handlerVoiceQueue();
                    document.removeEventListener('click', setAutoPlay);
                    document.removeEventListener('touchend', setAutoPlay);
                    isBindClickEvent = false;
                }
                document.addEventListener('click', setAutoPlay);
                document.addEventListener('touchend', setAutoPlay);
                MessageBox.alert('有语音尚未播放点击任意位置开始播放。', '温馨提示', { type: 'warning' });
                isBindClickEvent = true;
            });
    });
}

const state = Vue.observable({
    voiceQueue: [],
    push: () => {},
});

const playAudio = (() => {
    let timeId = null;
    return voiceData => {
        return new Promise(resolve => {
            audioContext.decodeAudioData(
                voiceData,
                decode => {
                    const duration = decode.duration + audioContext.currentTime;
                    // 调用resume恢复播放
                    audioContext.resume();
                    const source = audioContext.createBufferSource();
                    source.buffer = decode;
                    source.connect(audioContext.destination);
                    source.start(0);
                    timeId = setInterval(() => {
                        const surplusTime = duration - audioContext.currentTime;
                        if (surplusTime <= 0) {
                            clearInterval(timeId);
                            timeId = null;
                            audioContext.suspend().then(() => {
                                resolve();
                            });
                        }
                    }, 300);
                    // resolve();
                },
                errorCallback => {
                    console.error(errorCallback);
                    resolve();
                },
            );
        });
    };
})();

const handlerVoiceQueue = (() => {
    let playing = false;
    return async () => {
        try {
            if (!audioContext) await checkAutoplay();
        } catch (e) {
            return null;
        }
        if (playing || !state.voiceQueue || !state.voiceQueue.length) {
            return null;
        }
        const voiceData = state.voiceQueue.shift();
        if (!voiceData) {
            return handlerVoiceQueue();
        }
        playing = true;
        await playAudio(voiceData);
        playing = false;
        return handlerVoiceQueue();
    };
})();

state.push = function voiceQueuePush(src) {
    get(
        src,
        {},
        {
            isHandleError: true,
            responseType: 'arraybuffer',
            // baseURL: '',
        },
    ).then(({ data }) => {
        if (!data || !data.byteLength) return;
        state.voiceQueue.push(data);
        handlerVoiceQueue();
    });
};

export { state as voiceQueue };

export default vue => {
    vue.prototype.voiceQueue = state;
};
