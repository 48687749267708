/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Stat } from './Stat';

class DriverStat extends Stat {
    constructor() {
        super();
        this.baseUrl += '/driverStat';
    }

    driverStatList({ statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/driverStatList', {
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }
}

export default new DriverStat();
export { DriverStat };
