<!-- Created by henian.xu on 2019/12/25. -->

<template>
    <div class="layout-menu">
        <router-view/>
        <template v-if="isOpen">
            <div
                class="notice"
                @click="onNotice"
            >
                <!--el-icon-bell-->
                <!--el-icon-message-solid-->
                <i class="icon el-icon-message-solid"/>
                <div
                    v-if="unReadCount"
                    class="dot"
                >
                    <!--{{ unReadCount }}-->
                </div>
            </div>
            <el-drawer
                ref="noticeDrawer"
                class="notice-drawer"
                title="消息通知"
                size="400px"
                append-to-body
                :visible.sync="drawer"
                direction="rtl"
                @open="onOpen"
                @close="onClose"
            >
                <!--:before-close="handleClose"-->
                <div class="notice-drawer-inner">
                    <div class="header">
                        <el-button-group>
                            <el-button
                                v-for="(item,index) in filterList"
                                :key="index"
                                size="small"
                                :type="isRead === item.id?'primary':''"
                                @click="onReadStatus(item)"
                            >
                                {{ item.label }}
                            </el-button>
                        </el-button-group>
                        <el-button-group>
                            <!--                            <el-button
                                                            type="primary"
                                                            size="small"
                                                            @click="()=>{onSendUserMessage()}"
                                                        >
                                                            发送消息
                                                        </el-button>-->
                            <el-button
                                type="primary"
                                size="small"
                                @click="()=>{onALLMarkAsRead()}"
                            >
                                全部已读
                            </el-button>
                        </el-button-group>

                    </div>
                    <div
                        class="body"
                        ref="noticeDrawerInnerBody"
                        :infinite-scroll-delay="500"
                        v-infinite-scroll="onQuery"
                    >
                        <el-form
                            ref="queryForm"
                            class="second-header npa-b"
                            size="small"
                            :inline="true"
                            :model="queryFormModel"
                        >
                            <el-form-item
                                prop="keywords"
                            >
                                <el-input
                                    v-model="queryFormModel.keywords"
                                    placeholder="请输入关键词"
                                />
                            </el-form-item>
                            <el-form-item>
                                <el-button
                                    type="primary"
                                    @click="refreshData()"
                                >
                                    查询
                                </el-button>
                            </el-form-item>
                        </el-form>
                        <template v-if="listData && listData.length">
                            <Message
                                v-for="(item,index) in listData"
                                :key="item.id"
                                :data="item"
                                @change="onMessageChange($event,index)"
                                @updateReadCount="onUpdateReadCount"
                            />
                        </template>
                        <div
                            v-else
                            class="pa-a ta-c tc-g6"
                        >
                            暂无数据
                        </div>
                    </div>
                    <div class="footer">
                        共 {{ pagination.totalRows || 0 }} 条
                        <!--<el-button
                            type="primary"
                            size="small"
                            @click="onTestMessage"
                        >
                            测试消息
                        </el-button>-->
                    </div>
                </div>
            </el-drawer>
        </template>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="'发送消息'"
            center
            width="600px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="userId"
                    label="用户"
                    label-width="6em"
                >
                    <RemoteSelect
                        filterable
                        remote="/rs/user/selectByPage"
                        placeholder="请输入"
                        :props="{id:'id',name:'selectName'}"
                        v-model="addDialog.formModel.userId"
                    />
                </el-form-item>
                <el-form-item
                    prop="title"
                    label="标题"
                    label-width="6em"
                >
                    <el-input
                        v-model="addDialog.formModel.title"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="content"
                    label="内容"
                    label-width="6em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.content"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { Utils } from 'vmf';
import Message from './_Message';

const {
    GlobalVar: {
        webSocketConfig,
        // appConfig: { WEBSOCKET_CONFIG: webSocketConfig },
    },
} = Utils;
const { mapGetters } = createNamespacedHelpers('User');

export default {
    name: 'Menu',
    components: { Message },
    props: {},
    data() {
        return {
            drawer: false,
            isRead: '',
            filterList: [
                {
                    id: '',
                    label: '全部',
                },
                {
                    id: 1,
                    label: '已读',
                },
                {
                    id: 0,
                    label: '未读',
                },
            ],
            unReadCount: 0,
            pagination: {},
            listData: [],
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    userId: '',
                    title: '',
                    content: '',
                },
                // 表单校验规则
                formRules: {
                    userId: {
                        required: true,
                        message: '请选择用户',
                    },
                    title: {
                        required: true,
                        message: '请输入标题',
                        trigger: 'blur',
                    },
                    content: {
                        required: true,
                        message: '请输入正文',
                        trigger: 'blur',
                    },
                },
            },
            queryFormModel: {
                keywords: '',
            },
        };
    },
    computed: {
        isOpen() {
            return this.$sockets.readyState === WebSocket.OPEN;
        },
        ...mapGetters(['token']),
    },
    methods: {
        onNotice() {
            this.drawer = true;
        },
        onReadStatus(item) {
            this.isRead = item.id;
            this.refreshData();
        },
        refreshData() {
            this.listData = [];
            this.pagination = {};
            this.onQuery();
        },
        onMessageChange(data, index) {
            let currentItem = this.listData[index];
            if (!currentItem) return;
            currentItem = { ...currentItem };
            const Obj = Object.keys(data).reduce((pre, cur) => {
                // eslint-disable-next-line no-prototype-builtins
                if (pre.hasOwnProperty(cur)) pre[cur] = data[cur];
                return pre;
            }, currentItem);
            this.listData.splice(index, 1, Obj);
            // this.drawer = false;
            this.$refs.noticeDrawer.closeDrawer();
        },
        onUpdateReadCount(data) {
            if (!this.$utils.isDef(data)) return;
            this.unReadCount = data;
        },
        async getDataList() {
            const { pagination } = this;
            const res = await this.$api.Ms.UserMessage.data(
                {
                    keywords: this.queryFormModel.keywords,
                    isRead: this.isRead,
                    currentPage: (pagination.currentPage || 0) + 1,
                    pageSize: 20,
                },
                {
                    isAxiosResponseData: true,
                },
            );
            const { result, statMap } = res.data;
            this.listData.push(...result.data);
            this.pagination = result.pagination;
            this.unReadCount = statMap.unReadCount;
        },
        handleClose(done) {
            this.listData = [];
            this.pagination = {};
            done();
            console.log('handleClose');
        },
        onOpen() {
            // this.$refs['noticeDrawerInnerBody'] 只是为了判断 Drawer 是否已经打开过了
            if (this.$refs.noticeDrawerInnerBody) {
                this.onQuery();
            }
        },
        onClose() {
            this.listData = [];
            this.pagination = {};
        },
        onQuery() {
            if (!this.drawer) return;
            // console.log('onQuery', this.drawer);
            const { currentPage, totalPages, totalRows } = this.pagination;
            if (totalRows === 0 || (totalRows && currentPage >= totalPages)) return;
            this.getDataList();
        },
        async initUnReadCount() {
            const res = await this.$api.Ms.UserMessage.getUnReadCount();
            this.unReadCount = res.data.data;
        },
        async onALLMarkAsRead() {
            await this.$confirm('确定要全部标记为已读吗？');
            const { msg } = await this.$api.Ms.UserMessage.updateMessageRead(
                { ids: '' },
                { isAxiosResponseData: true },
            );
            this.refreshData();
            this.$message({
                message: msg,
                type: 'success',
            });
        },
        popUpMessage(msg) {
            if (msg.messageType === 'heartbeat') return;
            const h = this.$createElement;
            let notify = null;
            const callBack = () => {
                this.unReadCount -= 1;
                notify.close();
            };
            if (msg.voiceId) {
                this.voiceQueue.push(`/rs/voice/getVoice?voiceId=${msg.voiceId}`);
            }
            if (
                (this.$power(['storeStoreOrder:autoPrintOrder']) ||
                    this.$power(['storeCommunityOrder:autoPrintOrder'])) &&
                (msg.messageCase === 1003 || msg.messageCase === 1004)
            ) {
                let templateType = '';
                if (msg.messageCase === 1003) {
                    templateType = 2;
                }
                if (msg.messageCase === 1004) {
                    templateType = 1;
                }
                // 查询待打印订单列表
                this.$api.Ex.Order.findWaitPrintOrderList({ templateType }).then(json => {
                    const res = json.data;
                    if (res.success && res.data) {
                        // 有新的可打印订单
                        this.printOrder(res.data, templateType);
                    }
                });
            }
            notify = this.$notify({
                // title: '收到一条消息',
                customClass: 'notice-message-alert',
                // duration: 0,
                message: h(
                    'Message',
                    {
                        // style: 'color: teal'
                        props: {
                            data: msg,
                            callBack,
                        },
                    },
                    '',
                ),
            });
        },
        printOrder(ids, templateType) {
            const { printerType } = this.userData;
            if (printerType === 1) {
                this.$api.Ex.Order.printOrderByClodop({ ids, templateType }).then(json => {
                    const res = json.data;
                    if (res.success) {
                        this.orderLodopPrint(json.data.data, ids);
                    } else {
                        this.$alert(res.msg, '错误提示', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: '确定',
                        });
                    }
                });
            } else if (printerType === 2) {
                this.$api.Ex.Order.printOrderByXpyun({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            }
        },
        orderLodopPrint(res, ids) {
            const { waybillTemplate } = res;
            const { data } = res;
            const templateList = [];
            data.forEach(item => {
                let tpl = waybillTemplate;
                Object.keys(item).forEach(key => {
                    const rex = new RegExp(`\\$\\{${key}\\}`, 'g');
                    const val = JSON.stringify(item[key]);
                    tpl = tpl.replace(rex, val.slice(1, val.length - 1));
                });
                templateList.push(tpl);
            });
            const lodop = `${templateList.join('LODOP.NewPage();')}`;
            this.$lodop.init(lodop).then(({ LODOP, CLODOP }) => {
                CLODOP.On_Return = (/* taskId, value */) => {
                    this.$api.Ex.Order.updateOrderPrintStatus({ ids, status: 1 }).then(json => {
                        const res1 = json.data;
                        this.$message({
                            message: res1.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    });
                };
                LODOP.PRINT();
            });
        },
        onTestMessage() {
            console.log('onTestMessage');
            this.$socket.send('测试消息');
        },
        /* -- 事件 -- */
        onSendUserMessage() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Ms.UserMessage.sendUserMsg({
                    ...this.addDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
    },
    async websocketMessage(event) {
        const data = event.data ? JSON.parse(event.data) : {};
        if (data.messageType === 'message') {
            const message = data.message ? JSON.parse(data.message) : {};
            this.unReadCount += 1;
            this.popUpMessage(message);
        }
        if (data.messageType === 'unReadCount') {
            this.unReadCount = +data.message || 0;
        }
    },
    created() {
        const { token } = this;
        if (!webSocketConfig || !webSocketConfig.location || !token) return;
        this.$sockets.connect(`${webSocketConfig.location}/${this.token}`);
        this.initUnReadCount();
    },
};
</script>

<style lang="scss">
.notice-message-alert {
    width: auto;
    min-width: 400px;

    > .el-notification__group {
        flex: 1;
    }
}

.notice-drawer {
    // TODO element 13 版本后样式问题
    * {
        &:focus {
            outline: none;
        }
    }

    > .el-drawer__container {
        > .el-drawer {
            > .el-drawer__header {
                margin-bottom: $margin;
            }

            > .el-drawer__body {
                overflow: hidden;

                > .notice-drawer-inner {
                    padding: $padding;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: stretch;
                    height: 100%;

                    > .header {
                        flex: 0 0 auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: $margin;
                    }

                    > .body {
                        flex: 1 1 1%;
                        overflow-y: auto;
                        overflow-x: hidden;
                        @include beautify-scrollbar;
                    }

                    > .footer {
                        flex: 0 0 auto;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.layout-menu {
    height: 100%;
    width: 100%;
}

.notice {
    position: fixed;
    /*left: 0;*/
    right: 0;
    top: 160px;
    background-color: $color-primary;
    padding: $padding;
    border-top-left-radius: $padding-small;
    border-bottom-left-radius: $padding-small;

    > .icon {
        height: 1em;
        width: 1em;
        display: block;
        font-size: 20px;
        color: #fff;
    }

    > .dot {
        background-color: #f00;
        width: 10px;
        height: 10px;
        border-radius: 100vw;
        position: absolute;
        top: $padding-small;
        left: $padding-small;
    }
}
</style>
