/**
 * Created by henian.xu on 2018/7/18.
 *
 */
import { Sp } from './Sp';

class Device extends Sp {
    constructor() {
        super();
        this.baseUrl += '/device';
    }

    data({
        storeId,
        deviceCatId,
        deviceName,
        deviceSn,
        shopName,
        storeName,
        isAvailable,
        relStoreId,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            storeId,
            deviceCatId,
            deviceName,
            deviceSn,
            shopName,
            storeName,
            isAvailable,
            relStoreId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, deviceName, deviceSn, addressKeywords, remarks, isAvailable, deviceCatId } = {}) {
        return super.post('/save', {
            id,
            deviceName,
            deviceSn,
            addressKeywords,
            remarks,
            isAvailable,
            deviceCatId,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    selectByPage({ deviceCatId, deviceName, deviceSn, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/selectByPage', {
            deviceCatId,
            deviceName,
            deviceSn,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    findStoreDeviceList({ deviceCatId, deviceName, deviceSn, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/findStoreDeviceList', {
            deviceCatId,
            deviceName,
            deviceSn,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    relationDevice({ storeId, deviceIds } = {}) {
        return super.post('/relationDevice', {
            storeId,
            deviceIds,
        });
    }

    cancelRelationDevice({ id }) {
        return super.post('/cancelRelationDevice', {
            id,
        });
    }
}

export default new Device();
export { Device };
