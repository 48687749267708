/**
 * Created by henian.xu on 2021/8/15.
 *
 */

import deleteIcon from '../delete-icon.gif';

export class DeleteMarker {
    constructor(point, opt) {
        if (!opt || !opt.BMap || !opt.map) {
            console.error('BMap|map 参数未传入');
            return;
        }
        this.type = 'deleteMarker';
        this.BMap = opt.BMap;
        this.map = opt.map;
        this.point = point ? new this.BMap.Point(point.lng, point.lat) : null;
        this.offset = {
            width: 12,
            height: -12,
            ...opt.offset,
        };
        const options = {
            enableEditing: false,
            enableMassClear: true,
            enableClicking: true,
            ...opt,
        };
        delete options.BMap;
        delete options.map;
        this.options = options;
        this.load();
    }

    load() {
        const { point, offset, BMap, map } = this;
        this.originInstance = new BMap.Marker(point, {
            offset: new BMap.Size(offset.width, offset.height),
            // icon: 'https://api.map.baidu.com/images/blank.gif',
            icon: new BMap.Icon(deleteIcon, new BMap.Size(12, 12)),
        });
        map.addOverlay(this.originInstance);
    }

    addEventListener(type, listener, options) {
        this.originInstance.addEventListener(type, listener, options);
    }

    removeEventListener(type, listener, options) {
        this.originInstance.removeEventListener(type, listener, options);
    }

    setPosition(point) {
        this.point = point ? new this.BMap.Point(point.lng, point.lat) : null;
        this.originInstance.setPosition(point);
    }

    destroy() {
        const { map, originInstance } = this;
        map.removeOverlay(originInstance);
        this.originInstance = null;
    }
}

export default DeleteMarker;
