/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Pm } from './Pm';

class OpenScreenPromotion extends Pm {
    constructor() {
        super();
        this.baseUrl += '/openScreenPromotion';
    }

    data({ promotionName, promotionType, giveScope, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            promotionName,
            promotionType,
            giveScope,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({
        id,
        promotionName,
        promotionTime,
        promotionType,
        promotionContent,
        giveScope,
        buyerLevelId,
        remarks,
        isAvailable,
        buyerIds,
    } = {}) {
        let promotionBeginTime = null;
        let promotionEndTime = null;
        if (promotionTime != null && promotionTime.length === 2) {
            [promotionBeginTime, promotionEndTime] = promotionTime;
        }
        return super.post('/save', {
            id,
            promotionName,
            promotionBeginTime,
            promotionEndTime,
            promotionType,
            promotionContent,
            giveScope,
            buyerLevelId,
            remarks,
            isAvailable,
            buyerIds,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new OpenScreenPromotion();
export { OpenScreenPromotion };
