/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ts } from './Ts';

class MarketCodeProduct extends Ts {
    constructor() {
        super();
        this.baseUrl += '/marketCodeProduct';
    }

    data({ productTitle, productBrand, currentPage, pageSize } = {}) {
        return super.get('/data', {
            productTitle,
            productBrand,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const { fileBasePath } = json.data.data;
            const { productImage } = json.data.data;
            if (!productImage) {
                json.data.data.productImage = [];
            } else {
                json.data.data.productImage = productImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const { productAd } = json.data.data;
            if (!productAd) {
                json.data.data.productAd = [];
            } else {
                json.data.data.productAd = productAd.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        productTitle,
        productBrand,
        productCode,
        productImage,
        productDesc,
        productAd,
        isAvailable,
        isCopy,
    } = {}) {
        const productImageStr = productImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const productAdStr = productAd
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            productTitle,
            productBrand,
            productCode,
            productImage: productImageStr,
            productAd: productAdStr,
            productDesc,
            isAvailable,
            isCopy,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    saveDetail({ id, detailJson }) {
        return super.post('/saveDetail', { id, detailJson });
    }

    selectByPage({ productTitle, productBrand, currentPage, pageSize } = {}) {
        return super.get('/data', {
            productTitle,
            productBrand,
            currentPage,
            pageSize,
        });
    }
}

export default new MarketCodeProduct();
export { MarketCodeProduct };
