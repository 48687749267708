/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Stat } from './Stat';

class PayStat extends Stat {
    constructor() {
        super();
        this.baseUrl += '/payStat';
    }

    payStatList({ paymentMode, paymentType, statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        let paymentTypeStr = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        if (Array.isArray(paymentType)) {
            paymentTypeStr = paymentType.join(',');
        }
        return super.get('/payStatList', {
            paymentMode,
            paymentType: paymentTypeStr,
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }

    findAllPayStatList({ paymentType, statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        let paymentTypeStr = null;
        if (Array.isArray(paymentType)) {
            paymentTypeStr = paymentType.join(',');
        }
        return super.get('/findAllPayStatList', {
            paymentType: paymentTypeStr,
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }

    iHealthRefundStatList({ statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            [statBeginDate, statEndDate] = statDateDay;
        }
        return super.get('/iHealthRefundStatList', {
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }
}

export default new PayStat();
export { PayStat };
