/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Pm } from './Pm';

class LiveRoom extends Pm {
    constructor() {
        super();
        this.baseUrl += '/liveRoom';
    }

    data({ roomName, liveType, anchorName, anchorWechat, liveStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            roomName,
            liveType,
            anchorName,
            anchorWechat,
            liveStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({
        id,
        liveType,
        screenOrientation,
        roomName,
        liveTime,
        anchorName,
        anchorWechat,
        coverImageUrl,
        shareImageUrl,
        liveFunction,
        remarks,
        anchorBuyerId,
    } = {}) {
        const coverImageUrlStr = coverImageUrl
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const shareImageUrlStr = shareImageUrl
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        let startTime = null;
        let endTime = null;
        if (liveTime != null && liveTime.length === 2) {
            [startTime, endTime] = liveTime;
        }
        return super.post('/save', {
            id,
            liveType,
            screenOrientation,
            roomName,
            startTime,
            endTime,
            anchorName,
            anchorWechat,
            coverImageUrl: coverImageUrlStr,
            shareImageUrl: shareImageUrlStr,
            liveFunction,
            remarks,
            anchorBuyerId,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    addLiveGoods({ liveRoomId, liveGoodsIds }) {
        return super.post('/addLiveGoods', { liveRoomId, liveGoodsIds });
    }
}

export default new LiveRoom();
export { LiveRoom };
